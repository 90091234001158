@import '@fonts'
@import '@responsive'

.card
  position: relative

  &[data-size="L1x1"]
    width: 297px
    min-width: 297px
    .image-wrapper
      height: 297px

  &[data-size="L2x1"]
    width: 610px
    min-width: 610px
    .image-wrapper
      height: 297px

  &[data-size="M1x1"]
    width: 219px
    min-width: 219px
    .image-wrapper
      height: 219px

  &[data-size="M2x1"]
    width: 453px
    min-width: 453px
    .image-wrapper
      height: 219px

  &[data-size="S1x1"]
    width: 141px
    min-width: 141px
    .image-wrapper
      height: 140px
      margin-bottom: 8px

  &[data-size="1x1"]
    width: 158px
    min-width: 158px
    .image-wrapper
      height: 158px

  &[data-size="2x1"]
    width: 324px
    min-width: 324px
    .image-wrapper
      height: 158px

.image-wrapper
  width: 100%
  position: relative
  margin-bottom: 12px

.promo
  position: absolute
  z-index: 1
  top: 6px
  right: 6px
  pointer-events: none

.card-like-button
  position: absolute
  z-index: 1
// TODO: merge class with .selection-card-like-button & .rest-card-like-button
.card-like-button
  bottom: 12px
  right: 12px
  z-index: 1
  +responsive(only-desktop-large)
    bottom: 8px
    right: 8px
  +responsive(mobile)
    bottom: 8px
    right: 8px
  svg
    stroke: black
    fill: transparent

.card-extra
  display: flex
  gap: 10px
  align-items: center
  font-weight: 400
  font-size: 13px
  line-height: 18px
  color: var(--c-gray-59)
  margin-bottom: 4px
  overflow: hidden
.card-date
  white-space: nowrap
  text-overflow: ellipsis
  white-space: nowrap
.card-dot
// empty
.card-media-type
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  cursor: pointer
  transition: all .2s ease
  &:hover
    color: var(--c-afisha)

.card-description
  max-height: 110px
  max-width: 100%
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 5
  -webkit-box-orient: vertical

.card-image-link
  overflow: hidden
  border-radius: 16px
  width: 100%
  min-width: 100%
  height: 100%
  display: block
  position: relative
  -webkit-transform: translateZ(0)
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%)
  background-color: rgba(0, 0, 0, 0.05)
  &:hover .image
    transform: scale(1.03) translate3d(0,0,0)

.image
  object-fit: cover
  height: 100%
  min-width: 100%
  border-radius: 16px
  position: absolute
  top: 50%
  left: 50%
  transform: scale(1) translate3d(-0,0,0)
  transition: transform .5s ease

.afisha-selection-chevron--medium
  position: absolute
  left: 13px
  top: -6px
  z-index: 2
  svg
    width: 29px
    height: 50px
.afisha-selection-chevron--big
  position: absolute
  left: 16px
  top: -8px
  z-index: 2
  svg
    width: 36px
    height: 64px


.skeleton-card
  &[data-size="L1x1"]
    width: 297px
    height: 372px
    .skeleton-image
      height: 297px
    .skeleton-text--1
      width: 152px
      height: 18px
      translate: 0 309px
    .skeleton-text--2
      width: 245px
      height: 18px
      translate: 0 331px
    .skeleton-text--3
      width: 186px
      height: 18px
      translate: 0 353px

  &[data-size="L2x1"]
    width: 610px
    height: 372px
    .skeleton-image
      height: 297px
    .skeleton-text--1
      width: 152px
      height: 18px
      translate: 0 309px
    .skeleton-text--2
      width: 520px
      height: 18px
      translate: 0 331px
    .skeleton-text--3
      width: 430px
      height: 18px
      translate: 0 353px

  &[data-size="M1x1"]
    width: 219px
    height: 293px
    .skeleton-image
      height: 219px
    .skeleton-text--1
      width: 152px
      height: 18px
      translate: 0 231px
    .skeleton-text--2
      width: 210px
      height: 18px
      translate: 0 253px
    .skeleton-text--3
      width: 186px
      height: 18px
      translate: 0 275px

  &[data-size="M2x1"]
    width: 453px
    height: 293px
    .skeleton-image
      height: 219px
    .skeleton-text--1
      width: 152px
      height: 18px
      translate: 0 231px
    .skeleton-text--2
      width: 395px
      height: 18px
      translate: 0 253px
    .skeleton-text--3
      width: 334px
      height: 18px
      translate: 0 275px

  &[data-size="S1x1"]
    width: 141px
    height: 215px
    .skeleton-image
      height: 140px
    .skeleton-text--1
      width: 138px
      height: 18px
      translate: 0 152px
    .skeleton-text--2
      width: 112px
      height: 18px
      translate: 0 174px
    .skeleton-text--3
      width: 125px
      height: 18px
      translate: 0 196px

  &[data-size="1x1"]
    width: 158px
    height: 232px
    .skeleton-image
      height: 158px
    .skeleton-text--1
      width: 144px
      height: 18px
      translate: 0 170px
    .skeleton-text--2
      width: 112px
      height: 18px
      translate: 0 192px
    .skeleton-text--3
      width: 125px
      height: 18px
      translate: 0 214px

  &[data-size="2x1"]
    width: 324px
    height: 232px
    .skeleton-image
      height: 158px
    .skeleton-text--1
      width: 152px
      height: 18px
      translate: 0 170px
    .skeleton-text--2
      width: 280px
      height: 18px
      translate: 0 192px
    .skeleton-text--3
      width: 260px
      height: 18px
      translate: 0 214px

.skeleton-image
  width: 100%

.skeleton-text
  position: absolute


.favorite
  position: absolute
  bottom: 8px
  right: 8px
  z-index: 1
