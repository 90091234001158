@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  gap: 8px
  max-width: 790px
  :global(#field-select-input)
    height: 52px

  +responsive(mobile)
    max-width: 500px
    flex-wrap: wrap
    :global(#field-select-input)
      height: 56px


.selector
  width: 100%
  & > div
    min-width: initial
    max-width: initial
    width: 100%

.default-button[data-size='L']
  background-color: #000
  width: 52px
  height: 52px

.wrapper-selector
  position: relative
  width: 33%
  max-width: 240px
  +responsive(mobile)
    &:nth-child(1)
      flex: auto
      max-width: initial
      order: 3
    &:nth-child(2)
      width: calc(50% - 4px)
      order: 1
    &:nth-child(3)
      width: calc(50% - 4px)
      order: 2


  &[data-loading=true]
    pointer-events: none

  .selector
    position: relative


  .selector-loading
    background-color: #fff
    width: 35px
    height: 35px
    min-height: initial
    left: initial
    right: 10px
    top: 50%
    transform: translateY(-50%)
    z-index: 11
    border-radius: 50%
    pointer-events: none
    &[data-visible=true]
      pointer-events: initial


.button-wrapper
  order: 4

