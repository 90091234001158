@import '@responsive'
@import '@mixins'
@import '@fonts'

$asideWidth: 396px
$radius: 32px

@mixin backgroundStyles
  background-color: #595959
  background-size: cover
  background-repeat: no-repeat
  background-position: center center

.wrap
  width: 100%
  height: 477px
  display: flex
  &[data-is-mobile=true]
    height: auto
    aspect-ratio: 1/1

.aside
  width: $asideWidth
  border-radius: $radius
  padding: 24px
  color: white
  position: relative
  overflow: hidden
  &[data-is-mobile=true]
    width: calc((100vw - 12px - 12px))
    aspect-ratio: 1/1
    border-radius: 20px
    padding: 16px
  &.aside--skeleton
    padding: 0

.aside-background
  @include backgroundStyles
  position: absolute
  width: 100%
  height: 100%
  left: 0
  right: 0
  top: 0
  bottom: 0
  z-index: 1
  pointer-events: none
  filter: blur(25px)
  -webkit-filter: blur(25px) // TODO: fix autoprefixer
  transform: rotate(180deg) scale(2) translate3d(0, 0, 0) // must use translate3d for blur optimization
  &::before
    content: ''
    position: absolute
    z-index: 2
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0.04%, rgba(0, 0, 0, 0.15) 70.01%, rgba(0, 0, 0, 0.20) 99.96%)
    top: 0
    left: 0
    width: 100%
    height: 100%
  &[data-is-mobile=true]
    filter: none
    transform: none
.aside-content
  position: absolute
  z-index: 3
  left: 24px
  right: 24px
  bottom: 24px
  &[data-is-mobile=true]
    left: 16px
    right: 16px
    bottom: 16px

.aside-title
  max-height: 300px
  overflow: hidden
  display: -webkit-box
  -webkit-line-clamp: 8
  -webkit-box-orient: vertical

  margin-right: 48px
  margin-bottom: 12px
  +responsive(mobile)
    margin-bottom: 6px

.aside-description
  color: #FFFFFF
  margin-bottom: 16px
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden
  &[data-is-mobile=true]
    margin-bottom: 12px
    font-size: 13px
    line-height: 18px
.aside-subtitle
  opacity: 0.7

.aside-dot
  margin: 0 8px


.main
  @include backgroundStyles
  width: calc(100% - $asideWidth)
  border-radius: $radius
  position: relative

  &::after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    right: 0
    width: 100%
    height: 240px
    background: linear-gradient(0.13deg, rgba(0, 0, 0, 0.5) -10.71%, rgba(0, 0, 0, 0.35) 26.42%, rgba(0, 0, 0, 0.15) 64.2%, rgba(0, 0, 0, 0) 99.88%)
    z-index: 1
    border-bottom-right-radius: inherit
    border-bottom-left-radius: inherit

  &--skeleton
    background-color: transparent
    &:after
      display: none

.favorite
  position: absolute
  right: 24px
  bottom: 24px
  z-index: 2
  +responsive(mobile)
    right: 0
    bottom: 0
  svg
    stroke: white

.actions
  position: absolute
  z-index: 2
  bottom: 24px
  right: 24px
// color: white
.share
// empty
.like
// empty
