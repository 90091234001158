@import '@responsive'
@import '@mixins'
@import '@fonts'

$calendarArrowsWidth: 32px
$calendarDeselectGap: 8px

$calendarHeightDesktop: 70px
$calendarHeightMobile: 52px

$calendarListGapDesktop: 6px
$calendarListGapMobile: 3px

.calendar
  overflow: hidden
  position: relative
  padding: 0
  padding-top: $calendarDeselectGap
  margin-top: calc($calendarDeselectGap * -1)
  margin-bottom: 36px
  +responsive(mobile)
    overflow: visible
    margin-bottom: 12px
  &.calendar--has-prev
    padding-left: calc($calendarArrowsWidth)
  &.calendar--has-next
    padding-right: calc($calendarArrowsWidth)



.calendar-list
  display: flex
  padding: 0 0
  overflow: visible !important // keen prevention
  +responsive(mobile)
  &.calendar-list--has-prev
    padding-left: $calendarListGapDesktop
    +responsive(mobile)
      padding-left: $calendarListGapMobile
  &.calendar-list--has-next
    padding-right: $calendarListGapDesktop
    +responsive(mobile)
      padding-right: $calendarListGapMobile


// Стили, чтобы крестик не перекрывал background ячейки следующего дня
.calendar-list
  :global(.slider-item)
    z-index: 1
  :global(.slider-item):has(:global(.button-deselect))
    z-index: 2

