@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  margin: 0
  display: flex
  flex-direction: column

.input
  background: #F6F6F6
  text-decoration: none
  color: #000000
  transition: all 0.15s ease
  outline: none
  text-align: left
  position: absolute
  z-index: 1
  box-shadow: none
  font-size: 15px
  margin: 0
  appearance: none
  -moz-appearance: none
  -webkit-appearance: none
  caret-color: #FF3118
  padding: 0 12px 12px 12px
  width: inherit
  max-width: inherit
  border-radius: 13px
  border: none
  line-height: 22px
  left: 0
  top: 0
  resize: none
  overflow-y: hidden
  overflow-x: auto
  white-space: nowrap
  &::-webkit-scrollbar
    display: none
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0
  &::placeholder
    color: rgba(0, 0, 0, 0.4)
  &::-webkit-input-placeholder
    color: rgba(0, 0, 0, 0.4)
  &::-moz-placeholder
    color: rgba(0, 0, 0, 0.4)
  &:-ms-input-placeholder
    color: rgba(0, 0, 0, 0.4)

  &:active,
  &:hover,
  &:focus
    border: none

  &:empty,
  &:not(:empty)
    top: 19px
    height: calc(60px - 19px)
    min-height: calc(60px - 19px)

  &:focus,
  &.focused
    &:empty,
    &:not(:empty)
      top: 28px
      line-height: 22px
      height: calc(60px - 28px)
      min-height: calc(60px - 28px)

      &::placeholder
        color: rgba(0, 0, 0, 0)
      &::-webkit-input-placeholder
        color: rgba(0, 0, 0, 0)
      &:-moz-placeholder
        color: rgba(0, 0, 0, 0)
      &::-moz-placeholder
        color: rgba(0, 0, 0, 0)
      &:-ms-input-placeholder
        color: rgba(0, 0, 0, 0)

      & + .label
        top: 0
        opacity: 1

#input_phone
  appearance: textfield
  -webkit-appearance: textfield
  -webkit-rtl-ordering: logical
  -webkit-user-select: text
  cursor: auto
  &::-webkit-contacts-auto-fill-button
    visibility: hidden
    display: none !important
    pointer-events: none
    position: absolute
    right: 0

.block
  position: relative
  width: 100%
  max-width: 100%
  height: 60px
  min-height: 60px
  border-radius: 12px
  overflow: hidden
  background-color: #f6f6f6
  .error
    border: 1px solid #F41212

.label
  position: absolute
  z-index: 2
  left: 0
  padding: 9px 12px 0 12px
  top: 7px
  font-size: 13px
  line-height: 17px
  color: rgba(0, 0, 0, 0.4)
  transition: all 0.15s ease
  opacity: 0
  width: 100%
  cursor: text
  pointer-events: none

.error
  font-size: 13px
  line-height: 17px
  color: #F41212
  margin-top: 6px
  display: block
