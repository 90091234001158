@import '@fonts'
@import '@responsive'

.wrapper
  display: flex
  gap: 40px

// TODO: default picture?
.avatar
  width: 116px
  height: 116px
  +responsive(mobile)
    display: none

.title
  margin: 4px 0 12px 0
  +responsive(mobile)
    margin: 6px 0px 12px 0

.buttons
  display: flex
  align-items: center
  margin-bottom: 4px
  .button
    color: var(--c-gray-45)
    transition: all .2s ease

    &:not([data-active=true]):hover
      color: var(--c-afisha)
    &[data-active=true]
      color: var(--grey-20)

    // dot
    &:not(:last-child):after
      content: ''
      display: inline-block
      height: 3px
      width: 3px
      background-color: var(--c-gray-85)
      border-radius: 50%
      vertical-align: middle
      margin: 0 10px
      position: relative
      top: -1px

.text-wrap
  width: 100%
  max-height: 148px
  overflow: hidden
  margin-bottom: 12px
  position: relative
  transition: all .2s ease

  &::after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    right: 0
    width: 100%
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
    height: 64px
    opacity: 1
    transition: all .2s ease

  &[data-show=true]
    margin-bottom: 0
    &::after
      pointer-events: none
      opacity: 0

.text

.show-all-button
  width: 80px
  opacity: 1
  transition: all .2s ease, height .15s ease 0.15s
  &.open
    padding: 0
    opacity: 0
    pointer-events: none
    height: 0
    svg
      height: 0
      width: 0

.button-skeleton
  width: 257px
  height: 18px
  +responsive(mobile)
    width: 122px

.description-wrapper-skeleton
  margin-top: 26px
  +responsive(mobile)
    margin-top: 22px

.description-text-skeleton--1,
.description-text-skeleton--2,
.description-text-skeleton--3,
.description-text-skeleton--4
  height: 23px

.description-text-skeleton--1
  width: 95%
  transform: translateY(0)

.description-text-skeleton--2
  width: 100%
  transform: translateY(27px)

.description-text-skeleton--3
  width: 97%
  transform: translateY(54px)

.description-text-skeleton--4
  width: 90%
  transform: translateY(81px)
