@import '@responsive'
@import '@mixins'
@import '@fonts'

.modal
  display: flex
  flex-direction: column
  gap: 20px
  +responsive(mobile)
    gap: 8px

.modal-subtitle
  margin-top: 6px
  margin-bottom: 4px
  color: var(--c-gray-59)

.modal-photo
  width: 100%
  object-fit: cover
  border-radius: 16px
