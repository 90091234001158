
.wrapper
  position: relative
  min-width: 240px
  width: 100%

.field
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  box-sizing: border-box
  width: 100%
  font-size: 16px
  line-height: 22px
  z-index: 10
  cursor: pointer
  background-color: #fff

  min-height: 48px
  padding: 11px 19px 11px 16px
  border: 1px solid var(--c-gray-88)
  border-radius: 24px
  transition: all .2s ease
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0)
  &::placeholder
    color: var(--c-gray-59)
  &:focus:not([data-disabled=true]),
  &:hover:not([data-disabled=true])
    outline: none
    box-shadow: var(--e-inner-button)

  &__value
    line-height: inherit
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    font-size: 15px
    &--placeholder
      color: var(--c-gray-59)

  &__arrow
    pointer-events: none
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    top: 0
    right: 19px
    bottom: 0
    background-color: transparent
    transform: rotate(0) translateY(0px)
    transition: transform .2s ease
    svg
      width: 16px
      height: 16px
    &[data-close='true']
      transform: rotate(180deg) translateY(0px)

.dropdown
  box-sizing: border-box
  position: absolute
  top: 100%
  left: 0
  z-index: 11
  max-height: 350px
  width: 100%
  margin-top: 4px
  background-color: #fff
  pointer-events: none
  opacity: 0
  transition: transform .17s ease .18s, opacity .17s ease
  transform: translateY(-10px)
  overflow-x: hidden
  overflow-y: auto
  border-radius: 24px
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1)
  padding: 4px
  max-width: 350px
  &::-webkit-scrollbar
    width: 0
  &[data-visible='true']
    pointer-events: initial
    opacity: 1
    transform: translateY(0)
    transition: transform .17s ease, opacity .17s ease

  &__shadow
    position: sticky
    top: 0
    bottom: 0
    left: 0
    right: 0
    pointer-events: none
    &:before,
    &:after
      content: ""
      display: block
      position: absolute
      width: 100%
      left: 0
      top: -8px
      height: 25px
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 90%)
    &:after
      top: initial
      bottom: -350px
      height: 30px
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 90%)

  &__list
    margin: 0
    padding: 0
    list-style: none
    font-size: 16px
    line-height: 22px

  &__item
    cursor: pointer
    &[data-disabled=true]
      color: #B3B3B3
      pointer-events: none

  &__link
    display: block
    color: inherit
    text-decoration: none
    font-size: 15px
    line-height: 133%
    padding: 12px
    transition: all .2s ease
    border-radius: 22px
    box-shadow: inset 0 0 0 transparent
    &:focus,
    &:hover
      box-shadow: var(--e-inner-button)
