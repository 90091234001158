@import '@responsive'
@import '@mixins'
@import '@fonts'


.wrapper
  display: flex
  background-color: rgba(242, 242, 242, 1)
  flex-wrap: nowrap
  border-radius: 6px
  overflow: hidden

.main, .label
  display: flex
  align-items: center
  justify-content: center
  padding: 2px 4px

.main
  background-color: rgba(164, 255, 48, 1)
  border-radius: 6px

.main-text
  @include font-a-headline
  color: var(--c-black)
  font-size: 13px
  line-height: 16px

.label
  padding: 2.5px 6px 1.5px 4px

.label-text
  color: var(--c-black)
