@import '@responsive'
@import '@mixins'
@import '@fonts'

.adv-header
  max-width: initial
  transition: all .2s ease
  background-color: var(--c-gray-95)
  &[data-has-adv=false]
    height: 0
  &[data-has-adv=true]
    height: 90px

.adv-wrapper
  display: flex
  justify-content: center
  position: relative
  top: 0
  left: 0
  right: 0
  z-index: 1
  height: inherit
  padding: 0

.adv-container
  margin: 0 30px 0
  width: 100%
  display: flex
  justify-content: center
  &.adv-container--dev
    box-shadow: inset 0 0 0 4px salmon
  &.adv-container--mobile
    margin: 0 0 0
  &.adv-container--picnic
    margin: 0 0 0

.adv-place
  width: 100%
  height: 100%
  min-height: 90px
  margin-bottom: 25px
  &.adv-place--mobile
    margin-bottom: 15px
    min-height: 85px

.plug
  background-color: #fff
  position: absolute
  top: 0
  left: 0
  right: 0
  margin-bottom: 20px


// SAMOKAT

.adv-container
  margin: 0 0 0

.samokat-banner
  position: absolute
  left: 0
  right: 0
  opacity: 0
  pointer-events: none
  transition: all .2s ease
  &[data-is-show-for-city=true]
    pointer-events: initial
    opacity: 1
