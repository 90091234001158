=responsive($media)
  @if $media == desktop
    @media only screen and (min-width: 1280px)
      @content

  @else if $media == only-desktop-large
    @media only screen and (min-width: 1592px)
      @content

  @else if $media == only-desktop-medium
    @media only screen and (min-width: 1280px) and (max-width: 1591px)
      @content

  @else if $media == tablet
    @media only screen and (max-width: 1280px)
      @content

  @else if $media == only-tablet
    @media only screen and (min-width: 768px) and (max-width: 1279px)
      @content

  @else if $media == from-tablet
    @media only screen and (min-width: 768px)
      @content

  @else if $media == mobile
    @media only screen and (max-width: 767px)
      @content
