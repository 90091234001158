@import '@fonts'
@import '@responsive'

$circleBlockSize: 72px
$c-yellow: #FFFBD4
.guide-block
  position: relative
.guide-yellow-block
  margin-left: $circleBlockSize
.guide-yellow-block,
.guide-yellow-circle
  background-color: $c-yellow
  display: flex
  align-items: center
  justify-content: flex-start
  padding: 0 24px
  min-height: $circleBlockSize
  border-radius: calc($circleBlockSize / 2)
  gap: 16px
.guide-yellow-circle
  position: absolute
  height: 100%
  z-index: 1
  top: 0
  bottom: 0
  left: 0
  padding: 0
  width: $circleBlockSize
  min-width: $circleBlockSize
  max-width: $circleBlockSize
.guide-icon-wrap
  width: 32px
  height: 32px
  display: block
  position: absolute
  top: 50%
  left: 50%
  transform: translate3d(-50%, -50%, 0)
  svg
    width: 100%
    height: 100%
.guide-text
  margin: 16px 0
  width: 100%
.guide-button
  // empty
