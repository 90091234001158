@import '@responsive'
@import '@mixins'
@import '@fonts'


.tags-roll
// empty

.tags-list
  position: relative
  +responsive(only-desktop-large)
    &:after,
    &:before
      z-index: 1
      content: ''
      position: absolute
      display: block
      width: 50px
      top: 0
      bottom: 0
    &:after
      left: -48px
      background: linear-gradient(90deg, #fff 40%, transparent 100%)
    &:before
      right: -48px
      background: linear-gradient(270deg, #fff 40%, transparent 100%)
  :global(.keen-slider:not([data-keen-slider-disabled]))
    overflow: visible

.tags-item
  white-space: nowrap
  position: relative
  padding-right: calc(1px + 6px + 17px)
  &::before
    content: ''
    position: absolute
    width: 6px
    height: 6px
    border-radius: calc(6px / 2)
    display: block
    background-color: #ECECEC
    right: 1px
    top: 50%
    transform: translateY(-50%)
  +responsive(mobile)
    padding-right: calc(1px + 4px + 8px)
    &::before
      width: 4px
      height: 4px
  &.tags-item--last
    padding-right: 0
    &::before
      display: none

.tags-link
  text-decoration: none
  transition: all .2s ease
  &:hover
    color: var(--c-afisha)
