@import '@responsive'
@import '@mixins'
@import '@fonts'

.filter
  display: flex
  flex-direction: column
  gap: 16px
  justify-content: center
  +responsive(mobile)
    gap: 10px
  &:hover
    .image-wrapper
      filter: saturate(105%) contrast(105%)
      &:after
        box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.15)
        background-color: rgba(0, 0, 0, 0.05)
    .image
      transform: scale3d(1.07,1.07,1)

.image-wrapper
  position: relative
  border-radius: 50%
  overflow: hidden
  background-color: #F2F2F2
  z-index: 0
  filter: saturate(100%) contrast(100%)
  transition: filter .4s ease
  &:after
    content: ''
    position: absolute
    display: block
    z-index: 1
    top: 0
    left: 0
    right: 0
    bottom: 0
    border-radius: 50%
    transition: all .4s ease
    box-shadow: inset 0 0 15px 0 transparent
    background-color: rgba(0, 0, 0, 0)

.image
  display: block
  z-index: 0
  transform: scale3d(1,1,1)
  transition: transform .4s ease, opacity .3s ease

.title
  width: 100%
  text-align: center
  transition: opacity .3s ease
