@import '@responsive'
@import '@mixins'
@import '@fonts'

.slider
  position: relative
  min-height: 141px
  &:global(.swiper)
    overflow: visible
  :global(.swiper-wrapper)
    transition-timing-function: cubic-bezier(0.38, 0.28, 0.12, 1.30)
    align-items: center !important

.skeleton
  display: flex
  gap: 8px
  align-items: center
  transform: translateX(calc(-100% - 8px))


