@import '@responsive'

.wrapper
  text-align: left
  display: flex
  left: 0
  right: 0
  bottom: 30px
  justify-content: center
  z-index: 2
  width: 100%
  max-width: 480px
  +responsive(mobile)
    width: 100%

.text
  color: #fff

.button
  cursor: pointer
  border-radius: 30px
  background-color: #fff
  padding: 7.5px 10px 6.5px
  transition: all .2s ease
  color: #000
  font-size: 13px
  align-self: end
  &:hover
    background-color: #d9d9d9

.notice
  display: grid
  grid-template-columns: 1fr auto
  width: 480px
  box-sizing: border-box
  justify-content: space-between
  align-items: center
  gap: 20px

  animation: a-notice 0.4s ease
  transition: all .2s ease
  border-radius: 24px
  padding: 10px 10px 10px 20px
  background: rgba(0, 0, 0, 0.7)
  backdrop-filter: blur(8px)
  -webkit-backdrop-filter: blur(8px) // TODO: fix autoprefixer
  transform: translate3d(0, 0, 0) // must use translate3d for blur optimization

  +responsive(mobile)
    width: 100%

@keyframes a-notice
  0%
    transform: translateY(30px) translate3d(0, 0, 0) // must use translate3d for blur optimization
    opacity: 0

  100%
    transform: translateY(0) translate3d(0, 0, 0) // must use translate3d for blur optimization
    opacity: 1
