@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  z-index: 50
  &[data-status='open']
    transition: transform .18s ease

.tooltip
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1)
  background-color: rgba(26, 26, 26, 0.8)
  border-radius: 16px
  padding: 7px 12px
  opacity: 0
  scale: 1
  transform-origin: center

  &[data-status='initial']
    scale: 0.9
    transform-origin: center
  &[data-status='open']
    opacity: 1
    scale: 1
    transition: opacity .18s ease, scale .18s ease

  &[data-status='close']
    opacity: 0
    scale: 0.9
    transition: opacity .2s ease, transform 2s ease 1s, scale 0s ease .3s
