@import '@responsive'
@import '@mixins'
@import '@fonts'

.modal
  +responsive(mobile)
    height: 100vh

.content,
.inputs,
.row
  display: flex
  flex-direction: column
  gap: 20px

.row
  flex-direction: row
  gap: 12px
  +responsive(mobile)
    flex-direction: column
    gap: 20px
  & > *
    flex: 1

.description
  color: #969696

.button
  +responsive(mobile)
    position: fixed
    left: 12px
    right: 12px
    bottom: 12px
    z-index: 2
