@import '@responsive'
@import '@mixins'
@import '@fonts'

.container
  display: flex
  flex-direction: column
  max-width: 1724px
  min-height: 100vh
  margin-left: auto
  margin-right: auto
  width: 100%
  overflow-x: clip
  // border: 4px solid blue
  & > *:nth-child(2)
    flex: 1
