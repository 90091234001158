@import '@responsive'

.wrapper
  padding: 50px 12px 96px
  margin-top: max(calc(50vh - 400px), 0px)
  height: 100%
  max-height: 100%
  overflow-x: hidden
  overflow-y: auto
  +responsive(mobile)
    padding-top: 60px
    padding-bottom: 60px

  &::after
    // bottom margin
    content: ''
    height: 96px
    width: 100%
    position: absolute
    pointer-events: none

.header-button
  position: absolute
  right: 10px
  top: 10px
  z-index: 1
  .button-close
    color: #969699
    background-color: transparent

.title[data-size=h3]
  margin-bottom: 16px
  max-width: 100%
  text-align: center
  color: #000000
  font-size: 28px
  line-height: 32px
  text-transform: uppercase
  +responsive(mobile)
    font-size: 22px
    line-height: 24px
    margin-bottom: 12px

.description
  margin-left: auto
  margin-right: auto
  padding: 0 24px
  font-size: 15px
  text-align: center
  line-height: 20px
  max-width: 301px
  margin-bottom: 0
  +responsive(mobile)
    font-size: 13px
    line-height: 18px

.row
  text-align: center
  margin: auto
  display: flex
  justify-content: center
  align-items: center
  flex-direction: row

.guest-info
  font-size: 18px
  line-height: 22px
  max-width: 465px
  margin-bottom: 24px

.img-wrapper
  position: relative
  min-width: 334px
  min-height: 326px
  margin: 27px auto 21px
  +responsive(mobile)
    width: calc(50vw)
    height: calc(50vw)
    max-width: 252px
    max-height: 252px
    min-width: initial
    min-height: initial
    margin: 24px auto 24px

  & .img
    width: 100%
    height: 100%
    object-fit: contain

.button
  min-width: 310px
  +responsive(mobile)
    min-width: 279px

