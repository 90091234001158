@import '@responsive'
@import '@mixins'

.slider
  border-radius: 20px
  transition: all 0.3s ease
  opacity: 1
  @media (width <= 970px)
    border-radius: 0
    &:global(.keen-slider:not([data-keen-slider-disabled]))
      overflow: visible

  &[hidden]
    opacity: 0
    pointer-events: none

.slide
  display: block

.arrows
  display: flex
  gap: 4px

.arrow
  &:disabled
    pointer-events: none

.arrow-previous
  left: 0
  transform: rotate(180deg)
.arrow-next
    right: 0
