
#button
  position: relative
  overflow: hidden
  min-width: 116px

  background-repeat: no-repeat
  background-size: cover
  background-position: 27% 50%
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.15)
  z-index: 0
  &:after
    content: ''
    position: absolute
    display: block
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba(0, 0, 0, 0.02)
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0) inset
    transition: all .2s ease
  &:hover
    background-color: transparent
    &:after
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15) inset
    & > div
      position: relative
      gap: 8px
      z-index: 1

  &:active
    background-color: rgba(0, 0, 0, 0.1)
    box-shadow: var(--e-inner-button), inset 2px 2px 4px rgba(0, 0, 0, 0.15)

  //&_active
  //  background-color: var(--c-gray-95)
  //  box-shadow: var(--e-inner-button), inset 2px 2px 4px rgba(0, 0, 0, 0.15)
  //  &:hover
  //    background-color: var(--c-gray-92)

  svg
    position: relative
    display: inline-block
    width: 40px
    height: 40px
    z-index: 1
