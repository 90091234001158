@import '@fonts'
@import '@responsive'

.header
  display: flex
  flex-direction: column
  gap: 24px
  margin-bottom: 36px
  +responsive(mobile)
    gap: 20px
    margin-bottom: 32px

  &--margin-top
    margin-top: 30px
    +responsive(mobile)
      margin-top: 20px


.body
  display: flex
  flex-direction: column
  gap: 60px

  [data-loading]
    opacity: 1
    transition: all .2s ease
  [data-loading=true]
    opacity: 0.7
    pointer-events: none
  +responsive(mobile)
    gap: 32px

.sort-wrapper
  display: flex
  justify-content: start
  gap: 4px
  overflow: auto
  &::-webkit-scrollbar
    display: none
  +responsive(mobile)
    width: 100vw
    margin-left: -12px
    padding-left: 12px
    padding-right: 12px


.grid,
.cards
  margin-top: 60px
  &:first-child
    margin-top: 0px
  +responsive(mobile)
    margin-top: 32px

.grid + .grid
  margin-top: 16px

.list
  +responsive(mobile)
    display: flex
    flex-wrap: wrap
    gap: 32px 8px

  .card[data-size="1x1"]
    +responsive(only-tablet)
      width: calc(100%/4 - 12px)
    +responsive(mobile)
      width: calc(100%/4 - 6px)
      min-width: 130px
      & > div:first-child
        aspect-ratio: 1 / 1
        height: initial
    @media screen and (max-width: 650px)
      width: calc(100%/3 - 6px)
    @media screen and (max-width: 500px)
      width: calc(100%/2 - 4px)
    @media screen and (max-width: 320px)
      width: 100%

.cards
  display: flex
  flex-wrap: wrap
  gap: 60px 15px


.footer
  position: relative

.advertInGallery
  margin-top: 24px
  +responsive(mobile)
    margin-top: 16px
