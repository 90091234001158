@import "@responsive"

.link
  display: flex
  justify-content: center
  align-items: center
  height: var(--h-button)
  border-radius: calc(var(--h-button) / 2)
  border: 1px solid var(--c-black-a01)
  padding: 0 16px
  color: var(--c-black)
  background: none
  cursor: pointer
  text-decoration: none
  transition: all .2s ease
  white-space: nowrap
  +responsive(mobile)
    height: 40px
    padding: 0 11.9px
  &:hover
    box-shadow: inset 0 0 12px -2px rgba(0, 0, 0, 0.2)

  &.selected
    background-color: #F2F2F2
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15) inset

  &.daily
    width: auto
    padding: 0
    min-width: 98px !important
    max-width: 98px !important

    +responsive(mobile)
      min-width: 92px !important
      max-width: 92px !important

    svg
      width: auto
      max-width: 80px
      min-width: 80px
      height: auto
      max-height: 32px
      min-height: 32px
      pointer-events: none
      +responsive(mobile)
        max-width: 80px
        min-width: 80px
        max-height: 32px
        min-height: 32px

  &.dots,
  &.close
    flex-shrink: 0
    padding: 0
    width: 48px
    height: 48px
    svg
      height: 24px
      width: 24px
      pointer-events: none

.skeleton
  flex-shrink: 0
