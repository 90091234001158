@import "@responsive"

.container,
.container-without-bg
  position: relative
  margin-bottom: 28px
  +responsive(mobile)
    margin-bottom: 30px
    z-index: 2

.container-without-bg
  min-height: 322px
  display: flex
  padding: 24px
  background-color: rgba(0, 0, 0, 0.05)
  border-radius: 32px
  .content-wrapper
    margin-top: 32px
    color: black
  .rest-tag,
  .rest-title-address,
  .rating-right
    color: #969696
  .price-dot
    background-color: #969696
  .main
    min-width: 500px
  .chevron
    right: 24px

.aside-without-bg
  .rating-right
    color: black

  & > div:before,
  & > div:after
    display: none

.main
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 100%
  .chevron,
  .rating-wrapper
    +responsive(mobile)
      display: none

  .rating-count
    cursor: pointer
    transition: color .2s ease
    &:hover
      color: #dcdcdc

.main-wrapper
  display: flex
  flex-direction: column
  justify-content: space-between
  gap: 30px
  height: 100%

.rating-wrapper
  display: flex
  gap: 12px
  align-items: center
  color: var(--c-white)
  +responsive(mobile)
    gap: 8px

.rating-reviews
  opacity: 0.6

.rating-right
  display: flex
  flex-direction: column

.content-wrapper
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-end
  margin-top: 20px
  flex: 1
  gap: 12px
  color: #fff
  +responsive(mobile)
    color: #000
    gap: 8px
    margin-top: 0

.rest-title
  font: inherit
  font-size: inherit
  color: inherit
  display: inherit
  flex-direction: inherit
  gap: inherit
  margin-bottom: 0

.rest-title-name
  // empty

.rest-tag,
.rest-title-address
  color: rgba(255, 255, 255, 0.70)
  +responsive(mobile)
    color: #969696

.rest-actions-wrap
  display: flex
  gap: 8px
  z-index: 1
  position: relative
  svg
    color: #fff

.partners
  display: flex
  gap: 8px
  flex-wrap: wrap
  +responsive(mobile)
    display: none

.aside
  display: flex
  flex-direction: column
  justify-content: flex-end
  width: 100%
  height: 100%
  +responsive(mobile)
    justify-content: space-between
  &-top
    display: none
    +responsive(mobile)
      display: block
  &-bottom
    display: flex
    justify-content: space-between
    gap: 15px
    align-items: flex-end

    +responsive(mobile)
      justify-content: flex-end
  .chevron
    svg
      width: 36px
      height: 36px
    display: none
    +responsive(mobile)
      right: 16px
      display: block

.chevron
  top: -8px
  right: 24px
  svg
    height: 48px
    width: 48px
    color: var(--c-afisha)
  position: absolute
  +responsive(only-desktop-large)
    right: 13px

.rest-booking-button
  +responsive(only-tablet)
    &[data-size='XL']
      max-width: 224px
      width: 100%
      padding: 0 14px
      flex-shrink: 1
  +responsive(mobile)
    flex: 1

.rest-similar-button
  margin-top: 8px
  +responsive(mobile)
    width: 100%


.rest-additional-info-wrap
  display: inline-flex
  gap: 3px
  align-items: center
  vertical-align: center


.rest-status
  white-space: initial
  color: var(--c-afisha)
  &[data-opening-soon=true]
    &[data-is-img=true]
      color: #8DF16B
      +responsive(mobile)
        color: #31BE00
    &[data-is-img=false]
      color: #31BE00

.price-dot
  position: relative
  top: -1px
  width: 4px
  height: 4px
  background-color: #fff
  opacity: 0.5
  border-radius: 50%
  margin: 0 4px
  +responsive(mobile)
    width: 3px
    height: 3px
    margin: 0 3px
    opacity: 1
    background-color: #969696

.main-buttons-wrapper
  margin-top: 8px
  display: flex
  gap: 6px
  width: 100%
  align-items: center
  +responsive(mobile)
    margin-top: 9px

  & > *
    flex-shrink: 0
