@import '@fonts'
@import '@responsive'

.page
  // empty

.rest-page
  // empty
.rest-page-section
  margin-bottom: 60px
  width: 100%
  max-width: 100%
  +responsive(mobile)
    margin-bottom: 40px
.rest-page-king-data
  display: flex
.rest-page-columns-wrap
  display: flex
  gap: 92px
  justify-content: flex-end
  margin-bottom: 34px
  +responsive(only-desktop-large)
    margin-bottom: 100px
  +responsive(tablet)
    flex-direction: column
    align-items: center
    margin-bottom: 20px

.rest-page-column-basic
  display: flex
  flex-direction: column
  gap: 60px
  +responsive(mobile)
    gap: 40px
.rest-page-column-additional
  overflow: hidden
.rest-page-main-block
  position: relative
  width: 391px
  height: 391px
.rest-page-aside-block
  position: relative
  width: 845px
  height: 391px
  overflow: hidden

// TODO: add stocks styles
// .rest-stocks-list
// .rest-stock-item

.rest-photo-list
  display: flex
  gap: 8px
.rest-photo-item
  width: 225px
  height: 149px
  border-radius: 16px
  overflow: hidden
  position: relative
  cursor: pointer
.rest-photo-image
  height: 100%
.rest-photo-show-all-button
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  border: none
  font-weight: 400
  font-size: 36px
  line-height: 32px
  color: var(--c-white)
  background-color: var(--c-black-a05)
  cursor: pointer


.rest-page-section-title
  margin-bottom: 24px
  &--with-arrows
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 28px
  &--with-likes
    display: flex
    justify-content: flex-start
    gap: 16px
    align-items: center
    margin-bottom: 20px
.rest-page-section-title-text
  @include font-a-headline
  margin-bottom: 0
  display: block
  font-weight: 500
  font-size: 32px
  line-height: 36px
.rest-menu-list
  display: flex
  gap: 16px

.arrows-buttons-group
  display: flex
  gap: 16px
.arrows-button
  height: 40px
  width: 40px
  border-radius: calc(40px / 2)
  border: 1px solid var(--c-gray-88)
  background: var(--c-white)
  font-size: 18px
  color: transparent
  background-repeat: no-repeat
  background-size: 14px 14px
  cursor: pointer
  &--left
    background-image: url('/images/icons/icon-arrow-left.svg')
    background-position: calc(50% - 1px) 50%
  &--right
    background-image: url('/images/icons/icon-arrow-right.svg')
    background-position: calc(50% + 1px) 50%

.rest-page-rest-card-list
  display: flex
  gap: 16px
.rest-page-selection-card-list
  display: flex
  gap: 16px
  width: 100%
  height: 297px
.rest-page-news-card-list
  display: flex
  gap: 16px
  height: 337px

.likes-buttons-group
  display: flex
  gap: 8px
.likes-button
  height: 80px
  width: 80px
  border-radius: calc(80px / 2)
  border: 1px solid var(--c-black-a01)
  font-size: 2em
  color: transparent
  background-color: var(--c-white)
  background-repeat: no-repeat
  background-size: 32px 32px
  padding: 0
  cursor: pointer
  &--like
      background-image: url('/images/icons/icon-thumb-green.svg')
      background-position: 50% calc(50% - 1px)
  &--dislike
      background-image: url('/images/icons/icon-thumb-gray.svg')
      background-position: 50% calc(50% - 1px)
      transform: rotate(180deg)

.review-input-wrap
  position: relative
  margin-bottom: 60px
.review-input-submit
  height: 48px
  width: 48px
  border-radius: calc(48px / 2)
  border: 1px solid var(--c-black-a01)
  background-image: url('/images/icons/icon-submit-arrow.svg')
  background-color: #c8c8c8
  font-size: 2em
  color: transparent
  background-color: var(--c-white)
  background-repeat: no-repeat
  background-size: 20px 20px
  background-position: center center
  cursor: pointer
  position: absolute
  top: 50%
  right: 20px
  transform: translateY(-50%)
.review-input-field
  height: 80px
  width: 100%
  border: 1px solid var(--c-gray-88)
  padding: 0 20px
  font-weight: 400
  font-size: 15px
  line-height: 20px
  border-radius: 16px
  &:focus
    outline: none

.rest-page-adv-banners
  width: 100%
.rest-page-adv-banner-example
  max-width: 100%

.promo-roll
  margin-top: 0
  margin-bottom: 60px
  +responsive(mobile)
    margin-top: 24px
    margin-bottom: 40px
