@import '@responsive'

$cover-radius: 32px
$cover-padding: 24px

.card
  display: block
  width: calc((100vw - 24px - 24px) / 2.7)
  min-width: 344px
  min-height: 344px
  max-width: 532px
  max-height: 532px
  aspect-ratio: 1/1
  border-radius: $cover-radius
  position: relative
  margin-bottom: 6px
  text-decoration: none

  &[data-cover-size="XL"]
    width: calc((100vw - 24px - 24px) / 2.7)

  &[data-cover-size="S"]
    width: calc(100vw - 65px)
    min-width: 306px
    min-height: 306px
    max-width: 450px
    max-height: 450px
    border-radius: 24px

  &[data-chevron=true]
    margin-top: 8px

  &:hover
    .img
      transform: scale(1.03)

.content
  position: relative
  width: 100%
  height: 100%
  z-index: 5
  &::before
    content: ''
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0.04%, rgba(0, 0, 0, 0.15) 70.01%, rgba(0, 0, 0, 0.20) 99.96%)
    border-radius: 0 0 32px 32px
    z-index: -1

  [data-cover-size="S"] &
    border-radius: 20px
    &::before
      border-radius: 20px

  [data-cover-size="XL"] &
    border-radius: 32px
    &::before
      border-radius: 32px

.img-wrapper
  position: absolute
  width: 100%
  height: 100%
  overflow: hidden
  [data-cover-size="S"] &
    border-radius: 20px
  [data-cover-size="XL"] &
    border-radius: 32px

.img
  z-index: 4
  background-color: #eeeeee
  transform: scale(1)
  transition: transform .6s ease
  object-fit: cover

.backdrop-wrap
  z-index: 1
  position: absolute
  overflow: hidden
  bottom: -6px
  left: $cover-padding
  right: $cover-padding
  height: 30px
  width: calc(100% - $cover-padding * 2)
  background-color: white
  border-radius: $cover-radius
  pointer-events: none
  &[data-cover-size="S"]
    border-radius: 20px
    left: 12px
    right: 12px
    width: calc(100% - 12px * 2)

.backdrop
  z-index: 3
  position: absolute
  top: 0
  right: 0
  left: 0
  bottom: 0
  background-repeat: no-repeat
  background-position: center center
  background-origin: center center
  opacity: 0.4
  filter: blur(15px)
  -webkit-filter: blur(15px) // TODO: fix autoprefixer
  transform: rotate(180deg) scaleX(1.5) scaleY(5)  translate3d(0, 0, 0) // must use translate3d for blur optimization

.chevron
  position: absolute
  top: -8px
  left: 20px
  z-index: 6
  svg
    width: 36px
    height: 64px
  [data-cover-size="S"] & svg
    width: 25px
    height: 45px

