@import '@responsive'
@import '@mixins'
@import '@fonts'

.metro
  display: flex
  align-items: baseline
  gap: 5px
  &.metro-link
    cursor: pointer
    transition: all .2s ease
    &:hover
      color: var(--c-afisha)

.dots
  display: flex
  max-height: 8px
  flex-shrink: 0

.dot
  position: relative
  display: block
  height: 8px
  width: 8px
  border-radius: 50%
  margin-left: -3px
  top: -1px
  &:first-child
    margin-left: 0
