@import '@responsive'
@import '@mixins'
@import '@fonts'

.list
  display: flex
  gap: 6px
  margin-bottom: 70px
  & > *
    flex: 1

.button
  @include font-regular()
