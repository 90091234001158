@import '@responsive'
@import '@mixins'
@import '@fonts'

.head
  display: flex
  justify-content: space-between
  gap: 15px
  margin-bottom: 20px
  padding: 2px 0 2px 4px
