@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  margin-top: 16px
  padding: 0 32px

.wrapper-crop
  position: relative
  display: flex
  justify-content: center
  overflow: hidden
  border-radius: 24px

.background-blur
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  filter: blur(15px)
  background-size: cover

.cropper
  border-radius: 24px
  overflow: hidden

.image-crop
  border-radius: 24px
  overflow: hidden

.description
  margin-top: 14px
  color: var(--c-gray-59)

.footer
  margin-top: 36px
  display: flex
  justify-content: space-between
  align-items: center
  gap: 20px

.scale
  display: flex
  justify-content: space-between
  align-items: center
  gap: 16px

.save
  min-width: 159px

#scale-icon[data-size='L'] svg
  width: 24px
  height: 24px


.wrapper
  :global(.ReactCrop__crop-selection:not(.ReactCrop--no-animate .ReactCrop__crop-selection))
    border-radius: 8%
    border: 1px solid rgba(255, 255, 255, 1)
    background-image: none
    &:after
      pointer-events: none
      content: ''
      display: block
      position: absolute
      left: 33%
      right: 33%
      top: 0
      bottom: 0
      border-left: 1px solid rgba(255, 255, 255, 0.6)
      border-right: 1px solid rgba(255, 255, 255, 0.6)
    &:before
      pointer-events: none
      content: ''
      display: block
      position: absolute
      top: 33%
      bottom: 33%
      left: 0
      right: 0
      border-top: 1px solid rgba(255, 255, 255, 0.6)
      border-bottom: 1px solid rgba(255, 255, 255, 0.6)

    :global(.ReactCrop__drag-handle)
      border-radius: 50px
      height: 10px
      width: 10px
      background-color: #fff

      &:global(.ord-nw)
        top: 2.2%
        left: 2.2%
      &:global(.ord-sw)
        bottom: 2.2%
        left: 2.2%
      &:global(.ord-ne)
        top: 2.2%
        right: 2.2%
      &:global(.ord-se)
        bottom: 2.2%
        right: 2.2%
