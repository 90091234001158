@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  flex-direction: column
  align-items: center
  background-color: rgba(245, 245, 245, 1)
  overflow: hidden

  &[data-plug-type='desktop-large']
    min-height: 324px
    padding: 8px 20px 18px
  &[data-plug-type='desktop-shrink']
    min-height: 414px
    padding: 8px 10px 16px
  &[data-plug-type='mobile-slider']
    min-width: 100vw
    max-width: 100vw
    margin-left: -12px
    min-height: 360px
    padding: 8px 12px 22px

.title
  color: var(--c-gray-59)
  [data-plug-type='desktop-large'] &
    margin-bottom: 16px
  [data-plug-type='desktop-shrink'] &,
  [data-plug-type='mobile-slider'] &
    margin-bottom: 8px

.slider
  [data-plug-type='desktop-large'] &
    width: 575px
    border-radius: 16px
    overflow: hidden
  [data-plug-type='desktop-shrink'] &,
  [data-plug-type='mobile-slider'] &
    width: 280px
    &:global(.swiper)
      overflow: visible

  [data-plug-type='mobile-slider'] &
    width: 100%
    align-self: flex-start
    margin-left: initial
    margin-right: initial
    :global(.swiper-slide)
      width: initial

.arrows
  display: flex
  justify-content: center
  gap: 4px
  align-items: center

  [data-plug-type='desktop-large'] &
    margin-top: 16px
  [data-plug-type='desktop-shrink'] &
    margin-top: 12px

.arrow-left,
.arrow-right
  display: flex
  justify-content: center
  align-items: center
  border: none

.arrow-left
  transform: rotate(180deg)

.arrow-left-wrapper,
.arrow-right-wrapper
  position: relative

//.autoplay-progress
//  pointer-events: none
//  position: absolute
//  right: -3px
//  bottom: -3px
//  z-index: 10
//  width: 54px
//  height: 54px
//  display: flex
//  align-items: center
//  justify-content: center
//
//.autoplay-progress svg
//  position: absolute
//  left: 0
//  top: 0
//  z-index: 10
//  width: inherit
//  height: inherit
//  stroke-width: 2px
//  stroke: url(#gradient)
//  stroke-linecap: round
//  fill: none
//  stroke-dasharray: 125.6
//
//
//.autoplay-progress svg:first-child
//  --progress1: 0
//  stroke-dashoffset: calc(125.6px * (1 - var(--progress1)))
//  transform: rotate(-90deg)
//  stroke: url(#gradient)
//
//.autoplay-progress svg:last-child
//  --progress2: 0
//  stroke: black
//  stroke-dashoffset: calc(125.6px * (1 - var(--progress2)))
//  transform: rotate(90deg)
//
//@keyframes test
//  0%
//    stroke-dashoffset: 155
//  100%
//    stroke-dashoffset: 0

.autoplay-progress
  pointer-events: none
  position: absolute
  right: -2px
  bottom: -2px
  z-index: 10
  width: 52px
  height: 52px
  display: flex
  align-items: center
  justify-content: center


.autoplay-progress svg
  width: inherit
  height: inherit
  position: absolute
  transform: rotate(-90deg)
  z-index: 2
  .circle
    --progress3: 0
    stroke-width: 2px
    stroke: url(#radialGradient)
    stroke-linecap: round
    stroke-dasharray: 157.07963267948966
    stroke-dashoffset: calc(155px * (1 - var(--progress3)))




