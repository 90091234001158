@import '@responsive'
@import '@mixins'
@import '@fonts'


.card
  display: flex
  flex-direction: column
  text-decoration: none
  color: initial

  &[data-size="L"]
    width: 297px

  &[data-size="ML"]
    width: 261px

  &[data-size="M"]
    width: 245px

  &[data-size="S"]
    width: 171px

.skeleton-card
  &[data-size="L"]
    height: 274px

  &[data-size="ML"]
    height: 252px

  &[data-size="M"]
    height: 252px

  &[data-size="S"]
    height: 243px
