@import '@responsive'
@import '@mixins'
@import '@fonts'

.card
  display: flex
  align-items: stretch
  &[data-plug-type="desktop-large"]
    width: 575px
    height: 200px
  &[data-plug-type="desktop-shrink"]
    flex-direction: column
    width: 280px
  &[data-plug-type="mobile-slider"]
    flex-direction: column
    width: 261px

.image
  position: relative
  width: 100%
  height: 100%
  border-radius: 16px
  overflow: hidden
  background-color: rgba(0, 0, 0, 0.2)
  flex-shrink: 0
  [data-plug-type="desktop-large"] &
    width: 200px
    height: 200px
  [data-plug-type="desktop-shrink"] &,
  [data-plug-type="mobile-slider"] &
    height: 156px

  img
    width: inherit
    height: inherit
    object-fit: cover

.icon
  position: absolute
  bottom: 12px
  right: 12px
  width: 32px
  height: 32px

.content
  display: flex
  flex-direction: column
  gap: 24px
  justify-content: space-between
  border-radius: 16px
  overflow: hidden
  background-color: #fff
  padding: 15px 16px 16px 16px
  flex: 1
  [data-plug-type="desktop-shrink"] &,
  [data-plug-type="mobile-slider"] &
    padding: 12px 12px 12px 12px
    min-height: 148px

.title
  & > *
    display: inline-block

.content-footer
  display: flex
  justify-content: space-between
  align-items: flex-end
  gap: 24px

.date
  color: var(--c-gray-59)
