@import '@responsive'
@import '@mixins'
@import '@fonts'

.answer
  display: flex
  justify-content: flex-start
  align-items: flex-start
  gap: 24px
  +responsive(mobile)
    gap: 16px
    display: grid
    grid-template-columns: min-content 1fr
    grid-template-rows: min-content min-content

.photo-wrap
  display: block

.content
  width: 100%
  display: flex
  flex-direction: column
  gap: 14px
  align-items: flex-start

.content-head
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center

.head-main
  display: flex
  justify-content: flex-start
  gap: 18px
  align-items: center

.head-info-wrap
  display: flex
  gap: 4px
  flex-direction: column

.user-name
  margin-bottom: 2px
  font-weight: 500
  font-size: 13px
  line-height: 20px

.detailed-wrapper
  display: flex
  flex-wrap: wrap
.detailed,
.date
  color: var(--c-gray-59)

.date
  +responsive(mobile)
    grid-column: 1 / 3
