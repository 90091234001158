@import '@responsive'
@import '@mixins'
@import '@fonts'

.select-type-wrap
  margin-top: 12px
  margin-bottom: 10px

.select-type-label
  margin-bottom: 10px

.select-type-list
  display: flex
  justify-content: flex-start
  gap: 6px
  margin-bottom: 10px

.footer
  display: flex
  flex-direction: column
  gap: 20px
  margin-top: 60px

.footer-warning
  color: var(--c-gray-59)
  text-align: center

.data-submit-wrap
  display: flex
  justify-content: center
  align-items: center
  gap: 6px

.data-submit-btn-secondary
  width: 100%
  max-width: 240px

.data-submit-btn-primary
  width: 100%
  max-width: 240px
