@import '@responsive'
@import '@mixins'
@import '@fonts'

@keyframes test
  0%
    transform: scale(1)
  50%
    transform: scale(1.25)
  100%
    transform: scale(1)

.button
  svg
    fill: transparent
    transition: all .2s ease
  &[data-active=true] svg
    fill: red
    stroke: red
    animation: test .3s ease
