
button.button
  white-space: nowrap
  padding: 11px 14px 11px 12px
  & > div
    gap: 5px
  &[data-active=true]
    background: var(--c-gray-95)
    box-shadow: var(--e-inner-button)
    &:hover
      background: var(--c-gray-92)

.icon
  display: block
  width: 16px
  height: 16px
  color: var(--c-afisha)
