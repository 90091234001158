@import '@responsive'
@import '@mixins'
@import '@fonts'

.content
  max-width: 845px

  .content-blockquote
    position: relative
    padding: 24px 0 24px 80px
    max-width: 765px
    margin: 20px 0 0 0
    +responsive(mobile)
      margin: 12px 0 12px 0
      padding: 46px 0 0 0
    p
      @include font-medium

    cite
      margin-top: 12px
      display: block
      font-style: normal
      color: #969696
    svg
      position: absolute
      left: 0
      top: 24px
      width: 40px
      height: 40px
      +responsive(mobile)
        top: 0
        width: 42px
        height: 42px
  a
    position: relative
    color: var(--c-afisha)
    text-decoration: underline
    text-decoration-color: transparent
    text-decoration-skip-ink: none
    text-decoration-thickness: from-font
    &:hover
      text-decoration-color: var(--c-afisha)
      -webkit-text-decoration-color: red
      -moz-text-decoration-color: red

  b
    @include font-medium
    font-weight: 500

  p.content-text
    padding-bottom: 20px
    +responsive(mobile)
      padding-bottom: 12px

  b.content-text
    display: block
    padding-bottom: 20px
    +responsive(mobile)
      padding-bottom: 12px

  i.content-text
    display: block
    margin-bottom: 20px
    color: #969696
    font-style: normal
    i
      font-style: normal
    +responsive(mobile)
      margin-bottom: 12px

  &-header
    margin-top: 36px
    padding-bottom: 20px
    +responsive(mobile)
      margin-top: 20px
      padding-bottom: 12px
    &[data-has-alt=true]
      padding-bottom: 10px
      +responsive(mobile)
        padding-bottom: 8px
    b
      font-weight: 500
      @include font-a-headline

  a
    transition: color .2s ease
    &:hover
      color: #FF3118

  &-img
    display: inline-block
    width: 100%
    overflow: hidden
    border-radius: 16px
    background-color: rgba(0, 0, 0, 0.05)
    margin-bottom: 20px
    +responsive(mobile)
      margin-bottom: 12px

    &[data-has-alt=true]
      margin-bottom: 10px
      +responsive(mobile)
        margin-bottom: 8px

.content-announce
  margin-bottom: 36px

.social
  display: flex
  margin-top: 36px
  padding: 24px
  gap: 14px 20px
  justify-content: space-between
  align-items: center
  border-radius: 16px
  background-color: #F2F2F2
  +responsive(mobile)
    margin-top: 20px
    padding: 20px 16px
    flex-direction: column
    p
      text-align: center
  &-text
    max-width: 497px
  &-list
    display: flex
    gap: 24px
  &-link
    color: transparent
    font-size: 0
    width: 40px
    height: 40px
    display: block
    &:hover
      svg
        fill: var(--c-afisha)
    svg
      transition: all .2s ease
      fill: black


.content-skeleton
  display: flex
  flex-direction: column
  gap: 36px

.content-skeleton-main-text
  height: 101px
  width: 100%

  &__1,
  &__2,
  &__3,
  &__4
    height: 31px
    +responsive(mobile)
      height: 18px

  &__1
    width: 100%
    transform: translateY(0)
    +responsive(mobile)
      width: 88%
  &__2
    width: 95%
    transform: translateY(35px)
    +responsive(mobile)
      width: 100%
      transform: translateY(24px)
  &__3
    width: 85%
    transform: translateY(70px)
    +responsive(mobile)
      width: 100%
      transform: translateY(48px)
  &__4
    display: none
    +responsive(mobile)
      display: block
      width: 65%
      transform: translateY(72px)

.content-skeleton-template-image
  height: 806px
  width: 100%
  +responsive(mobile)
    height: 502px
  &__title
    width: 636px
    height: 31px
    transform: translateY(0)
    +responsive(mobile)
      height: 28px
      width: 307px
  &__image
    width: 100%
    height: 560px
    transform: translateY(51px)
    +responsive(mobile)
      height: 234px

  &__text-1,
  &__text-2,
  &__text-3,
  &__text-4,
  &__text-5,
  &__text-6,
  &__text-7,
  &__text-8
    height: 27px
    +responsive(mobile)
      height: 18px

  &__text-1
    width: 100%
    transform: translateY(647px)
    +responsive(mobile)
      width: 90%
      transform: translateY(302px)
  &__text-2
    width: 96%
    transform: translateY(680px)
    +responsive(mobile)
      width: 100%
      transform: translateY(326px)
  &__text-3
    width: 88%
    transform: translateY(713px)
    +responsive(mobile)
      width: 100%
      transform: translateY(350px)
  &__text-4
    width: 96%
    transform: translateY(746px)
    +responsive(mobile)
      width: 65%
      transform: translateY(374px)
  &__text-5
    width: 70%
    transform: translateY(779px)
    +responsive(mobile)
      width: 96%
      transform: translateY(412px)
  &__text-6
    +responsive(mobile)
      width: 100%
      transform: translateY(436px)
  &__text-7
    +responsive(mobile)
      width: 100%
      transform: translateY(460px)
  &__text-8
    +responsive(mobile)
      width: 65%
      transform: translateY(484px)


.content-skeleton-social
  height: 88px
  width: 100%
  +responsive(mobile)
    height: 163px

.quick-booking
  margin-top: 10px
  margin-bottom: 56px
  +responsive(mobile)
    margin-bottom: 36px
