@import '@fonts'
@import '@responsive'

.cover
  margin-bottom: 48px
  +responsive(mobile)
    margin-bottom: 24px
  .promo
    position: absolute
    top: 24px
    left: 24px
    +responsive(mobile)
      top: 16px
      left: 16px

.description
  margin-bottom: 52px
  +responsive(mobile)
    margin-bottom: 37px
.cover-main
  min-width: 350px
  display: flex
  justify-content: flex-end
  flex-direction: column
  gap: 12px
  color: #fff
  +responsive(mobile)
    padding-top: 16px
    color: #000
    gap: 6px

.date,
.cover-additional
  opacity: 0.7
  +responsive(mobile)
    color: #969696
    opacity: 1

.cover-additional
  & > span:nth-child(2):before
    content: ''
    display: inline-block
    vertical-align: middle
    margin: 0 7px
    width: 3px
    height: 3px
    border-radius: 50%
    background-color: #fff
    +responsive(mobile)
      background-color: #969696

.restaurants-list
  position: relative
  min-height: 500px
  display: flex
  flex-wrap: wrap
  gap: 36px 16px
  transition: opacity .3s ease
  &[data-loading=true]
    opacity: 0.7
    pointer-events: none
  +responsive(mobile)
    gap: 28px 9px

  .restaurants-item
    +responsive(tablet)
      min-width: initial
      width: calc(100%/3 - 11px)
    +responsive(mobile)
      width: calc(100%/3 - 6px)
    @media only screen and (max-width: 540px)
      width: calc(100%/2 - 5px)
    @media only screen and (max-width: 360px)
      width: 100%


.recommended,
.afisha-selected,
.advert
  margin-top: 60px
  +responsive(mobile)
    margin-top: 37px

.header-selection
  display: flex
  gap: 16px
  margin-bottom: 24px
  align-items: start
  & > *:last-child
    position: relative
    top: 2px
    +responsive(only-desktop-large)
      top: 4px
    +responsive(mobile)
      top: 0
  +responsive(mobile)
    justify-content: space-between
    margin-bottom: 16px

.title
  -webkit-hyphens: auto
  -moz-hyphens: auto
  -ms-hyphens: auto
  hyphens: auto

.pagination
  margin-top: 36px
  +responsive(mobile)
    margin-top: 45px


.favorite
  position: absolute
  right: 24px
  bottom: 24px
  +responsive(mobile)
    bottom: 32px
    right: 12px
  svg
    stroke: white

