@import '@responsive'
@import '@mixins'
@import '@fonts'

.modal
  padding-bottom: 24px
  +responsive(mobile)
    padding-bottom: 0

.wrapper
  padding: 16px 20px
  max-width: 500px
  box-sizing: content-box
  margin: auto
  transition: opacity .3s ease
  &[data-loading=true]
    pointer-events: none
    opacity: 0.6
  +responsive(mobile)
    margin-top: 24px
    max-width: initial
    padding: initial

.title
  text-align: center
  margin-bottom: 28px
  width: 100%
  margin-left: auto
  margin-right: auto
  +responsive(mobile)
    text-align: left
    margin-bottom: 48px

.buttons
  display: flex
  justify-content: center
  gap: 6px
  width: 100%
  margin: auto
  .button
    flex: 1
    max-width: 240px
    +responsive(mobile)
      flex: initial
      max-width: initial
  +responsive(mobile)
    max-width: initial
    flex-direction: column
    gap: 8px
