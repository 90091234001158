@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  gap: 8px 4px
  flex-wrap: wrap
  opacity: 0
  pointer-events: none
  transition: all .3s ease
  &[data-is-visible=true]
    opacity: 1
    pointer-events: initial
  +responsive(mobile)
    flex-wrap: nowrap
    position: relative
    left: -12px
    min-width: 100vw
    padding: 2px 12px 2px 12px
    overflow: auto
    &::-webkit-scrollbar
      display: none

.divider
  width: 0
  border-left: 1px solid #D9D9D9
  height: 24px
  margin: auto 5px
