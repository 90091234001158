@import '@responsive'
@import '@mixins'
@import '@fonts'

.modal
  padding-bottom: 24px
  +responsive(mobile)
    padding-bottom: 0
    min-height: calc(100vh - 45px)
  .error
    margin-left: 0
    margin-top: 0

  +responsive(mobile)
    [data-content]
      padding-bottom: 85px
    [data-shadow-bottom]
      display: none
    .textarea
      textarea
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10) inset
        border: none
        padding: 20px
      span
        margin-left: 0
        margin-top: 12px

.wrapper
  height: 100%
  display: flex
  flex-direction: column
  gap: 24px
  transition: opacity .3s ease
  +responsive(mobile)
    justify-content: space-between
    gap: 16px

  &[data-loading=true]
    pointer-events: none
    opacity: 0.6

  .button
    +responsive(mobile)
      position: fixed
      bottom: 16px
      left: 12px
      right: 12px


.inner
  flex: 1
  display: flex
  flex-direction: column
  gap: 24px
  +responsive(mobile)
    justify-content: space-between
    gap: 16px

.header
  display: flex
  gap: 16px
  justify-content: flex-start
  align-items: center
  margin-right: 50px
  +responsive(mobile)
    gap: 27px
    margin-right: 0
    align-items: flex-start
    flex-direction: column

.rate-group
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: 8px

.rate
  height: 80px
  width: 80px
  border-radius: calc(80px / 2)
  border: 1px solid var(--c-black-a01)
  font-size: 2em
  color: transparent
  background-color: var(--c-white)
  background-repeat: no-repeat
  background-size: 32px 32px
  padding: 0
  cursor: pointer
  &-like svg,
  &-dislike svg
    stroke: var(--c-gray-59)

  &-like.active
    background: rgba(242, 242, 242, 1)
    svg
      stroke: var(--c-green-1)

  &-dislike
    transform: rotate(180deg)
    &.active
      background: rgba(242, 242, 242, 1)
      svg
        stroke: var(--c-afisha)


