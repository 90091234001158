@import '@fonts'
@import '@responsive'

.rating
  display: inline-flex
  justify-content: center
  align-items: center
  position: relative
  color: #fff
  transition: all .2s ease
  border: 1px solid transparent

  &[data-size='S']
    height: 28px
    padding: 5.5px 10px 4.5px 10px
    border-radius: 14px
  &[data-size='M']
    height: 36px
    padding: 8.5px 16px 7.5px 16px
    border-radius: 20px

  &[data-mode='fill']
    color: #FFFFFF
    &[data-color='green']
      background-color: #31BE00
    &[data-color='gray']
      background-color: rgba(178, 178, 178, 0.40)
      backdrop-filter: blur(4px)
      -webkit-backdrop-filter: blur(4px) // TODO: fix autoprefixer
      transform: translate3d(0, 0, 0) // must use translate3d for blur optimization
    &[data-color='red']
      background-color: #FF3118

  &[data-mode='stroke']
    background: transparent
    &[data-color='green']
      color: #31BE00
      border-color: #31BE00
    &[data-color='gray']
      color: #000
      border-color: rgba(178, 178, 178, 0.40)
    &[data-color='red']
      color: #FF3118
      border-color: #FF3118
