@import '@responsive'

.notices
  position: fixed
  display: grid
  justify-items: center
  box-sizing: border-box
  width: 100%
  bottom: 0
  grid-auto-flow: row
  gap: 10px
  padding: 0 16px 16px 16px
  z-index: 350
  &--desktop
    padding: 0 0 35px 0
  &:empty
    padding: 0
    pointer-events: none
