@import '@responsive'

.card
  display: block
  position: relative

  width: calc(804px / 2)
  height: calc(280px / 2)

  +responsive(mobile)
    width: calc(534px / 2)
    height: calc(192px / 2)

  img
    position: absolute
    top: auto
    bottom: 0
    left: 0
    right: 0
