@import '@responsive'
@import '@mixins'
@import '@fonts'

.price-range-wrap
  position: relative
  font-weight: inherit // 400
  font-size: inherit // 13px
  line-height: inherit // 18px
  display: inline-flex

  &--white span
    color: rgba(255, 255, 255, 0.70)
    &[data-active=true]
      color: var(--c-white)

  &--black span
    color: #969696
    &[data-active=true]
      color: var(--c-black)

  &.link
    cursor: pointer
    span
      transition: all .2s ease

    &:hover span
      color: var(--c-afisha)
      opacity: 0.5
      &[data-active=true]
        opacity: 1

.tooltip-text
  color: white
