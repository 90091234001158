@import '@responsive'
@import '@mixins'
@import '@fonts'


.description
  display: -webkit-box
  -webkit-line-clamp: 4
  -webkit-box-orient: vertical
  overflow: hidden

.action-preview-label
  margin-top: 8px
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  gap: 8px
  background-color: rgba(224, 238, 255, 1)
  padding: 6px 8px
  border-radius: 28px

  &__description-wrapper
    position: relative
    flex-shrink: 1
    margin-left: 6px
    width: 100%
    height: 18px

  &__description
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis


  &__arrow
    position: absolute
    left: 22px
    top: -9px
    width: 31px
    height: 10px
    color: rgba(224, 238, 255, 1)

  &__link
    flex-shrink: 0
  &__button
    flex-shrink: 0
  #action-preview-label-button svg
    height: 14px
    width: 14px

