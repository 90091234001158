$textareaRadius: 24px
$counterGapTopSize: 6px
$counterGapBottomSize: calc(16px)
$textareaHeight: 129px

.restaurant-info-textarea
  position: relative
  width: 100%
  height: auto
  min-height: $textareaHeight

.restaurant-info-textarea-object
  width: calc(100% - 64px)
  height: inherit
  min-height: inherit
  max-height: inherit
  opacity: 0.5
  padding: 16px 16px calc(129px - 16px - 69px) 16px
  border: none
  border-radius: $textareaRadius 0 0 $textareaRadius
  background: transparent
  resize: none
  font-size: 15px
  line-height: 23px
  opacity: 1
  resize: none
  box-sizing: border-box
  color: #000000
  caret-color: #ff3118
  &::placeholder
    color: #969696
    opacity: 1
  &::-ms-input-placeholder
    color: #969696
  &:focus
    outline: none
  &[data-is-empty=false]
    color: #000000
    &::placeholder
      color: #000000
    &::-ms-input-placeholder
      color: #000000
.restaurant-info-textarea-counter
  position: absolute
  z-index: 1
  top: auto
  bottom: 0
  left: 0
  right: calc(64px + 16px)
  color: #969696
  background-color: #FFFFFF
  user-select: none
  -webkit-user-select: none
  padding: $counterGapTopSize 8px $counterGapBottomSize 16px
  height: calc($counterGapTopSize + 18px + $counterGapBottomSize)
  border-radius: 0 0 0 $textareaRadius
  &:after
    opacity: 1
    display: block
    content: ''
    position: absolute
    z-index: 1
    right: 0
    left: 0
    top: auto
    bottom: 100%
    height: 8px
    pointer-events: none
    background: linear-gradient(0deg, #FFFFFF, transparent)
    z-index: 1

#button
  position: absolute
  z-index: 2
  bottom: 16px
  right: 18px
  svg
    stroke: #FFFFFF
