@import '@fonts'
@import '@responsive'
@import '@ownerGlobal'

// *** megaphone tab page ***

.page-content-white-block
  @include page-content-white-block

// * news-editor *

$pageGap: 24px

.news-editor-content
  width: 100%
  display: flex
  align-items: flex-start
  justify-content: flex-start
  flex-wrap: nowrap
  flex-direction: column
  gap: $pageGap

.news-editor-page-content-section
  display: block
  width: 100%
  &.news-editor-page-content-section--margin-bottom
    margin-bottom: calc(36px - $pageGap)

.news-editor
  position: relative
  padding-right: 140px
  min-height: 48px
  margin: 3px 0
.news-editor-title
  margin-bottom: 7px
.news-editor-description
  color: #969696
.news-editor-button
  position: absolute
  top: 50%
  right: 0
  transform: translate3d(0,-50%,0)
  width: 140px

.news-editor-icon-edit
  width: 20px
  height: 20px
  pointer-events: none
.news-editor-icon-remove
  width: 25px
  height: 25px
  pointer-events: none

$menuGapX: 8px
.news-editor-menu-wrap
  position: relative
  min-height: calc(48px)
.news-editor-menu-title
  margin-bottom: 34px
.news-editor-menu-list
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  width: 100%
  gap: 12px $menuGapX
.news-editor-menu-list-item
  position: relative
  width: calc(50% - $menuGapX)
  max-width: calc(50% - $menuGapX)
  padding-left: 78px
$menuPhotoSize: 62px
.news-editor-menu-photo-wrap
  position: absolute
  top: 0
  left: 0
  width: $menuPhotoSize
  height: $menuPhotoSize
  border-radius: 12px
  overflow: hidden
  img
    max-height: 100% !important
    max-width: auto !important
    min-height: $menuPhotoSize !important
    min-width: $menuPhotoSize !important
    width: auto !important
    height: auto !important
    position: absolute !important
    top: 50% !important
    left: 50% !important
    transform: translate3d(-50%, -50%, 0)
.news-editor-menu-photo
  width: 100%
  height: 100%
  border-radius: inherit
.news-editor-menu-name
  margin-bottom: 4px
  font-size: 18px !important
  line-height: 22px !important
.news-editor-menu-price
  color: #969696
  margin-bottom: 4px
.news-editor-menu-description
  // empty
.news-editor-edit-button
  position: absolute
  top: 0
  right: 0
  width: 48px
  height: 48px

.news-editor-history
  @include page-content-white-block
  position: relative
  height: 72px
  min-height: 72px
  &.news-editor-history--is-open
    height: auto
    padding-top: 72px
.news-editor-history-toggle-button
  position: absolute
  display: block
  width: 100%
  height: 72px
  min-height: inherit
  top: 0
  left: 0
  padding: 0 0 0 16px
  border-radius: inherit
.news-editor-history-title
  pointer-events: none
.news-editor-history-toggle-icon
  pointer-events: none
  width: 32px
  height: 32px
  position: absolute
  right: 16px
  top: 50%
  transform: translate3d(0, -50%, 0)
  &.news-editor-history-toggle-icon--is-open
    transform: translate3d(0, -50%, 0) rotate(180deg)
.news-editor-history-list-wrap
  // empty
.news-editor-history-list
  // empty
$historyPhotoSize: 62px
.news-editor-history-item
  position: relative
  padding-left: 78px
  padding-right: 124px
  min-height: calc($historyPhotoSize + 16px)
  border-bottom: 1px solid rgb(225, 225, 225, 0.5)
  margin-bottom: 14px
  padding-bottom: 14px
  display: flex
  justify-content: center
  align-items: flex-start
  flex-direction: column
  img
      max-height: 100% !important
      max-width: auto !important
      min-height: $historyPhotoSize !important
      min-width: $historyPhotoSize !important
      width: auto !important
      height: auto !important
      position: absolute !important
      top: 50% !important
      left: 50% !important
      transform: translate3d(-50%, -50%, 0)
  .news-editor-history-actions-wrap
    opacity: 0
    transition: all 0.15s ease
  &:hover
    .news-editor-history-actions-wrap
      opacity: 1
      transition: all 0s ease
  &:last-child
    min-height: $historyPhotoSize
    border-bottom: 0
    margin-bottom: 0
    padding-bottom: 0
    .news-editor-history-actions-wrap
      top: 50%
.news-editor-history-item-photo-wrap
  position: absolute
  top: 0
  left: 0
  width: $historyPhotoSize
  height: $historyPhotoSize
  border-radius: 12px
  overflow: hidden
.news-editor-history-item-photo
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  border-radius: inherit
.news-editor-history-item-description
  margin-bottom: 2px
  color: #969696
  display: flex
  justify-content: flex-start
  align-items: center
.news-editor-history-item-bullit
  display: block
  background-color: #969696
  width: 3px
  height: 3px
  border-radius: 50%
  margin: 0 6px
.news-editor-history-item-text
  // empty
.news-editor-history-actions-wrap
  display: flex
  justify-content: flex-end
  align-items: center
  flex-direction: row
  gap: 12px
  position: absolute
  right: 0
  top: calc(50% - (14px / 2))
  transform: translate3d(0, -50%, 0)
.news-editor-history-action-remove,
.news-editor-history-action-edit
  width: 48px
  height: 48px
.news-editor-history-action-remove
  // empty
.news-editor-history-action-edit
  // empty
.news-editor-history-show-more
  margin-top: 24px
  width: 100%
