@import "@responsive"
@import "@mixins"
@import "@fonts"

.schedule
  div:global(#field-select-input)
    height: 56px
    border-radius: 40px
    button
      right: 16px
      svg
        height: 20px
        width: 20px

div.selector
  width: 132px
  :global(#field-select-dropdown)
    top: initial
    bottom: calc(100% + 5px)
    max-height: 240px
    min-width: 200px
  &:nth-child(2)
    order: -1

.buttons
  display: flex
  gap: 8px

