@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  position: relative
  @media only screen and (min-width: 1850px)
    border-radius: 32px
    overflow: hidden

.slider
  align-items: flex-end
  &:global(.keen-slider:not([data-keen-slider-disabled]))
    border-radius: 0
    overflow: visible

.controls
  position: absolute
  z-index: 1
  top: 0
  left: 0
  right: 0
  bottom: 0
  pointer-events: none
  +responsive(mobile)
    display: none


// ** для того чтобы пользователь случайно не кликнул по ссылке когда долистает до конца мы оставляем
// кнопку на прежнем месте в течении некоторого времени, после чего перемещаем за границы, чтобы она не мешала.

.arrow-prev,
.arrow-next
  height: 331px
  width: 116px
  color: transparent
  user-select: none
  pointer-events: all
  position: absolute
  cursor: pointer
  top: 50%
  // **
  transition: opacity .2s, transform .0s ease .0s
  opacity: 0

  // **
  &:disabled
    pointer-events: initial
    transition: opacity .2s, transform .0s ease .7s

  &:hover
    opacity: 1
    &:disabled
      opacity: 0
  svg
    width: inherit
    height: inherit
    color: #FFFFFF

// **
.arrow-prev
  left: 0
  transform: translate(0, -50%) rotate(180deg)
  &:disabled
    transform: translate(-100%, -50%) rotate(180deg)
.arrow-next
  right: 0
  transform: translate(0, -50%)
  &:disabled
    transform: translate(100%, -50%)
