@import '@responsive'
@import '@mixins'
@import '@fonts'


.header
  display: flex
  align-items: center
  justify-content: space-between
  height: 80px
.logo
  display: flex
  align-items: center
  gap: 15px
.logo-text
  top: 2px
  position: relative
  display: flex
  align-items: flex-start
  gap: 7px
  +responsive(mobile)
    flex-direction: column
    gap: 5px

  & > svg
    width: 135px
    height: 26px
    +responsive(mobile)
      width: 112px
      height: 15px

.mail
  color: #333333
  transition: color .2s ease
  opacity: 0.8
  &:hover
    color: var(--c-afisha)
  +responsive(mobile)
    display: none
