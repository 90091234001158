@import "@responsive"
@import "@mixins"
@import "@fonts"

.widget
  display: flex
  flex-direction: column
  gap: 18px
  padding: 16px 16px 14px 16px
  background-color: rgba(242, 242, 242, 1)
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) inset
  border-radius: 16px
  +responsive(mobile)
    gap: 12px
    padding: 12px

.selectors
  max-width: 670px
  +responsive(mobile)
    max-width: 480px

.rest-title
  color: var(--c-afisha)
