@import '@responsive'
@import '@mixins'
@import '@fonts'


.metro
  display: flex
  gap: 0 10px
  align-items: center
  flex-wrap: wrap
