@import '../../../6_shared/styles/responsive'
@import '../../../6_shared/styles/mixins'
@import '../../../6_shared/styles/fonts'

.subtitle
  color: var(--c-gray-59)
  margin-top: 4px

.dishes
  margin-top: 24px
  display: flex
  flex-direction: column
  gap: 24px
  +responsive(mobile)
    gap: 20px
    margin-top: 0

.dish
  display: flex
  gap: 16px

.dish-content
  display: flex
  flex-direction: column
  gap: 4px

.dish-price
  color: var(--c-gray-59)
  font-size: 13px
  line-height: 18px

.dish-photo
  flex-shrink: 0
  border-radius: 12px
  overflow: hidden
  display: block
  object-fit: cover
