@import '@shared/styles/responsive'
@import '@shared/styles/mixins'
@import '@shared/styles/fonts'


.slot
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  position: relative
  color: #000
  transition: all .2s ease
  user-select: none
  -moz-user-select: none
  -webkit-user-select: none
  flex-grow: 1

  min-width: 81px
  height: 52px
  padding: 0 17px
  font-size: 15px
  line-height: 26px
  letter-spacing: 0.5px
  border-radius: 47px
  background-color: #fff
  border-width: 1px
  border-style: solid
  border-color: rgba(233, 233, 233, 1)
  &:hover,
  &:focus
    box-shadow: var(--e-inner-button)
  &:disabled
    border-color: rgb(217, 217, 217)

  +responsive(mobile)
    min-width: 74px

.slot-skeleton
  width: 81px
  height: 52px
  border: 1px solid rgba(233, 233, 233, 1)
  border-radius: 47px
  padding: 0 !important
