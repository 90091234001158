@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  height: 219px
  +responsive(only-desktop-large)
    height: 297px
  +responsive(mobile)
    flex-direction: column
    gap: 20px
    height: initial
