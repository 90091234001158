@import '@responsive'
@import '@mixins'
@import '@fonts'

.buttons
  background-color: #fff
  box-shadow: 0 0 15px 15px #fff
  position: fixed
  bottom: 20px
  left: 20px
  right: 20px
  display: flex
  gap: 6px
  z-index: 2
  +responsive(mobile)
    left: 10px
    right: 10px

.remove
  flex-shrink: 0
.save
  flex: 1
  &[data-loading=true]
    pointer-events: none
    .save-text
      opacity: 0

.save-text
  transition: all .2s ease
  opacity: 1
