@import @responsive

$c-grey-contour: #E9E9E9
$w-padding-layout--mob: 16px
$h-header--mob: 92px
$rad-content--desk: 12px
$booking-grid-w: 597px

.block
  display: grid
  grid-template-columns: min-content auto
  background: #FFFFFF
  border-radius: 12px
  padding: 12px
  gap: 12px
  margin-bottom: 12px
  +responsive(mobile)
    grid-auto-rows: min-content

.block-date
  flex-direction: row
  justify-content: space-between
  +responsive(mobile)
    grid-template-columns: auto
    flex-direction: column
  .booking-label
    padding-top: 13px
    +responsive(mobile)
      padding-top: 0

.block-time
  grid-template-columns: auto
  flex-direction: column
  .error
    grid-column: initial

.content
  display: flex
  gap: 12px
  flex-wrap: wrap
  justify-content: flex-start
  +responsive(mobile)
    gap: 6px

.button
  height: 52px
  width: auto
  border-radius: calc(52px / 2)
  background: none
  cursor: pointer
  border: none
  font-size: 15px
  line-height: 20px
  padding: 0 16px
  margin: 0
  box-shadow: inset 0 0 0 1px $c-grey-contour
  color: #000000
  transition: all 0.2s ease
  &:hover
    box-shadow: inset 0 0 0 1px #8C8C8C
  &:active
    color: #FF3118
  &--date
    min-width: 125px
    +responsive(mobile)
      min-width: initial
      padding-left: 24px
      padding-right: 24px

  &--time
    min-width: 81px
    +responsive(mobile)
      min-width: 74px
  &__show-all-time
    display: flex
    min-width: 81px
    align-items: center
    justify-content: center
    +responsive(mobile)
      min-width: 52px
    svg
      width: 20px
      height: 20px

  &--selected
    box-shadow: inset 0 0 0 2px #FF3118
    &:hover
      box-shadow: inset 0 0 0 2px #FF3118
  &--disabled
    pointer-events: none
    color: #D5D5D5
    cursor: not-allowed
    user-select: none

.button--placeholder
  cursor: default
  pointer-events: none
  background: #F6F6F6
  box-shadow: inset 0 0 0 1px #F6F6F6
  color: transparent
  min-width: 81px
  +responsive(mobile)
    min-width: 74px

.error
  grid-column: 1 / 3
  font-size: 13px
  line-height: 17px
  color: #F41212
  display: block
  +responsive(mobile)
    grid-column: initial

.no-slots-message
  padding: 1em 0
  animation: fadeIn 1s

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1
