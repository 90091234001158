@import '@responsive'

.button
  &[data-size=M]
    padding-left: 16px
    padding-right: 12px
  &[data-size=S]
    padding-left: 10px
    padding-right: 5px
.icon
  width: 17px
  height: 17px
  margin-left: 2px
  margin-bottom: -1px
  +responsive(mobile)
    width: 16px
    height: 16px
    margin-left: 0
    margin-bottom: 0
