@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  position: relative
  margin-top: 10px
  display: flex
  flex-direction: column
  gap: 7px
  padding: 11px 10px 8px 10px
  background-color: var(--c-gray-95)
  border-radius: 16px
  width: 100%

.arrow
  position: absolute
  top: -9px
  left: 22px
  color: var(--c-gray-95)
  width: 31px
  height: 10.5px

.title
  display: inline-block
  margin-left: 4px

div.schedule
  gap: 2px
  max-height: 40px
  max-width: 255px
  & > div:first-child
    gap: 2px

  .slot
    height: 40px
    min-width: 65px
    padding: 0 15px
    font-size: 13px
    line-height: 18px
    letter-spacing: initial
    &:global(.skeleton)
      width: 69px
      min-width: initial
      +responsive(mobile)
        width: 60px

    +responsive(mobile)
      padding: 0 13px
      min-width: 64px

  .dots
    height: 40px
    width: 40px
    padding: 4px
    flex-shrink: 0
