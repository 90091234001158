@import '@responsive'
@import '@mixins'
@import '@fonts'

.block
  position: relative
  display: block
  border-radius: 16px
  flex-shrink: 0
  padding: 10px 8px 8px 10px
  width: 100%

  &[data-size="L"]
    height: 198px

  &[data-size="ML"]
    height: 174px

  &[data-size="M"]
    height: 168px

  &[data-size="S"]
    aspect-ratio: 1 / 1
    padding: 6px

.img-wrapper
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: hidden
  border-radius: 16px
  -webkit-transform: translateZ(0)
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%)

.img
  position: relative
  display: block
  min-width: 100%
  width: auto
  height: 100%
  object-fit: cover
  transform: scale(1) translate3d(-0,0,0)
  transition: transform .5s ease
  transform-origin: 50% 50%
  background-color: rgba(0, 0, 0, 0.05)
.block
  &:hover
    .img
      transform: scale(1.03) translate3d(0,0,0)

.content
  height: 100%

.wrapper
  cursor: pointer
