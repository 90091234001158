@import "react-datepicker/dist/react-datepicker.min.css";

/* base */
.react-datepicker__tab-loop {
    position: absolute;
    height: 0;
    width: 0;
}

.react-datepicker-popper {
    z-index: 20;
    background: white;
    border-radius: 24px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    top: 12px !important;
    right: -12px !important;
    width: 360px;
    height: auto;
}

.react-datepicker__close-icon {
    position: absolute;
    top: auto;
    left: auto;
    right: calc(0px - 4px - 12px + 20px);
    bottom: calc(0px - 40px - 12px - 20px);
    z-index: 21;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border: none;
    width: 44px;
    height: 44px;
    border-radius: calc(26px / 2);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 24px 24px;
    cursor: pointer;
    display: none;
}

.react-datepicker {
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    padding: 0 16px 16px 16px;
    border: none;
    width: 100%;
}

.react-datepicker .react-datepicker__month-container{
    width: 100%;
}

.react-datepicker .react-datepicker__header{
    background-color: transparent;
    border: none;
    padding: 0;
}
.react-datepicker .react-datepicker__header-container {
    display: flex;
    height: 74px;
    align-items: center;
    justify-content: space-between;
}
.react-datepicker .react-datepicker__header-container--owner {
    display: grid;
    grid-template-columns: repeat(2, min-content) 1fr;
}
.react-datepicker .react-datepicker__header-month-title {
    font-size: 18px;
    line-height: 22px;
    margin: 0 10px 0 0;
    padding: 0;
    appearance: none;
    background: none;
    border: none;
    text-align: left;
    color: #000000;
    opacity: 1;
    transition: opacity 0.15s ease;
}
.react-datepicker .react-datepicker__header-month-title:active {
    opacity: 0.62;
}
.react-datepicker .react-datepicker__header-month-change-wrap {
    height: 32px;
    min-width: 80px;
}
.react-datepicker .react-datepicker__header-month-change {
    height: 32px;
    width: 32px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}
.react-datepicker .react-datepicker__header-month-change svg {
    pointer-events: none;
}
.react-datepicker .react-datepicker__header-month-change.react-datepicker__header-month-change--prev {
    margin-right: 16px;
}
.react-datepicker .react-datepicker__header-month-change[disabled] {
    opacity: 0.15;
    cursor: default;
    pointer-events: none;
}
.react-datepicker .react-datepicker__month {
    display: grid;
    grid-auto-flow: row;
    gap: 4px;
    margin: 4px 0 0 0;
}
.react-datepicker-popper[data-placement^=bottom]{
    padding-top: 0
}
.react-datepicker-popper {
    overflow: hidden;
}
.react-datepicker .react-datepicker__day-names,
.react-datepicker .react-datepicker__week {
    display: flex;
    justify-content: space-between;
}
.react-datepicker .react-datepicker__day-name,
.react-datepicker .react-datepicker__day {
    display: flex;
    justify-content: center;
    align-items: center;
}
.react-datepicker .react-datepicker__day-names {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 13px;
    line-height: 16px;
    color: #969696;
    height: 28px;
    margin-bottom: 8px;
}
.react-datepicker .react-datepicker__day-name,
.react-datepicker .react-datepicker__day {
    width: 100%;
}
.react-datepicker .react-datepicker__day-name {
    user-select: none;
    height: 16px;
    text-transform: lowercase;
}
.react-datepicker .react-datepicker__day-name:nth-child(6),
.react-datepicker .react-datepicker__day-name:nth-child(7) {
    color: #FF3118;
}
.react-datepicker .react-datepicker__day-weekend {
    color: #FF3118;
}
.react-datepicker .react-datepicker__day--disabled .react-datepicker__day-weekend {
    color: #ccc;
}
.react-datepicker .react-datepicker__day {
    height: 40px;
    border-radius: calc(52px / 2);
    -webkit-tap-highlight-color: transparent;
    position: relative;
    cursor: pointer;
    margin: 0;
}
.react-datepicker .react-datepicker__day::before,
.react-datepicker .react-datepicker__day::after{
    content: '';
    pointer-events: none;
    position: absolute;
    height: 40px;
    width: 40px;
    box-sizing: content-box;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.react-datepicker .react-datepicker__day::before {
    opacity: 0;
    background-color: #fff;
    transition: border-color 0.35s ease, background-color 0.35s ease, color 0.35s ease;
    border: 2px solid #FF3118;
    z-index: -1;
}
.react-datepicker .react-datepicker__day::after{
    background-color: transparent;
    transition: all .2s ease;
    z-index: 1;
}
.react-datepicker .react-datepicker__day span {
    z-index: 2;
}
.react-datepicker .react-datepicker__day:not(.react-datepicker__day--selected,.react-datepicker__day--disabled):hover::after {
    background-color: #f0f0f0;
    border: none;
    opacity: 1;
    z-index: 1;
}
.react-datepicker__day:hover {
    background-color: transparent;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--disabled {
    cursor: default;
}
.react-datepicker .react-datepicker__day.react-datepicker__day--outside-month {
    opacity: 0;
    pointer-events: none;
}
.react-datepicker .react-datepicker__day.react-datepicker__day--selected {
    z-index: 1;
    color: black
}
.react-datepicker .react-datepicker__day.react-datepicker__day--selected::before {
    opacity: 1;
}
.react-datepicker .react-datepicker__range-mode:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    height: 40px;
    background-color: #F5F5F5;
    opacity: 0;
    transition: opacity 0.35s ease;
    z-index: -2;
}
.react-datepicker .react-datepicker__range-mode:before {
    opacity: 0;
}
.react-datepicker .react-datepicker__day--in-selecting-range:after,
.react-datepicker .react-datepicker__day--in-range:after {
    opacity: 1;
}
.react-datepicker .react-datepicker__day--selecting-range-start:after,
.react-datepicker .react-datepicker__day--range-start:after {
    opacity: 0;
}
.react-datepicker .react-datepicker__day--selecting-range-start + .react-datepicker__day--in-selecting-range:after,
.react-datepicker .react-datepicker__day--selecting-range-start + .react-datepicker__day--in-range:after, .react-datepicker .react-datepicker__day--range-start + .react-datepicker__day--in-selecting-range:after,
.react-datepicker .react-datepicker__day--range-start + .react-datepicker__day--in-range:after {
    left: -50%;
}
.react-datepicker .react-datepicker__day--selecting-range-end:before,
.react-datepicker .react-datepicker__day--range-end:before {
    opacity: 1;
}
.react-datepicker .react-datepicker__day--selecting-range-end:after,
.react-datepicker .react-datepicker__day--range-end:after {
    right: 50%;
}
.react-datepicker .react-datepicker__day--selecting-range-end:first-of-type:after,
.react-datepicker .react-datepicker__day--range-end:first-of-type:after {
    opacity: 0;
}
.react-datepicker__header-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    transition: all 0.15s ease;
    cursor: pointer;
    justify-self: flex-end;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    opacity: 0.4;
    background-size: contain;
    background-color: transparent;
}
.react-datepicker__header-close:hover:not(:disabled),
.react-datepicker__header-close:focus:not(:disabled) {
    opacity: 1;
    transform: scale(1.05);
    will-change: transform;
    outline: none;
}

.react-datepicker .react-datepicker__day--keyboard-selected {
    background-color: transparent;
}


/* mobile */
.booking.booking--mobile .react-datepicker-popper {
    top: 12px !important;
    right: 12px !important;
    left: -12px !important;
    bottom: auto !important;
    width: calc(100vw - 24px);
    box-shadow: none;
    height: 328px;
}

.booking.booking--mobile .react-datepicker {
    padding: 0 0 0 0;
}
.booking.booking--mobile .react-datepicker .react-datepicker__header-container {
    height: 36px;
    margin-bottom: 16px;
}
.booking.booking--mobile .react-datepicker .react-datepicker__month {
    padding: 0 8px;
}
.booking.booking--mobile .react-datepicker .react-datepicker__day-names {
    height: 20px;
    padding: 0 8px;
}
.booking.booking--mobile .react-datepicker .react-datepicker__day,
.booking.booking--mobile .react-datepicker .react-datepicker__day-name {
    width: 40px;
}
.booking.booking--mobile .react-datepicker .react-datepicker__day::before,
.booking.booking--mobile .react-datepicker .react-datepicker__day-name::before {
    height: 40px;
    width: 40px;
    width: 100%;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--selected {
    background-color: transparent
}
