@import '@responsive'
@import '@mixins'
@import '@fonts'

$title-gap: 4px

.main-title
  margin-top: 36px
  padding-bottom: $title-gap
  margin-bottom: calc(12px - $title-gap)
  display: -webkit-box
  -webkit-line-clamp: 4
  -webkit-box-orient: vertical
  overflow: hidden
  +responsive(mobile)
    margin-top: 5px

.main-description
  margin-bottom: 32px
  color: #737373
  max-width: 687px
  +responsive(mobile)
    margin-bottom: 16px


.main-title-skeleton
  margin-top: 36px
  margin-bottom: 12px
  +responsive(mobile)
    margin-top: 5px

  &__svg
    width: 540px
    height: 40px
    +responsive(mobile)
      height: 56px
      width: 85%
  &__rect
    &--1
      width: 100%
      height: 40px
      +responsive(mobile)
        height: 26px
    &--2
      width: 85%
      height: 26px
      transform: translateY(30px)

.main-description-skeleton
  margin-bottom: 32px
  max-width: 767px

  +responsive(mobile)
    max-width: 550px
    margin-bottom: 16px

  &__svg
    height: 66px
    width: 100%
    +responsive(mobile)
      height: 72px

  &__rect
    &--1,
    &--2,
    &--3,
    &--4
      height: 18px
      +responsive(mobile)
        height: 15px

    &--1
      width: 88%
      transform: translateY(0)
      +responsive(mobile)
        width: 85%
        transform: translateY(0)
    &--2
      width: 100%
      transform: translateY(24px)
      +responsive(mobile)
        width: 100%
        transform: translateY(19px)
    &--3
      width: 80%
      transform: translateY(48px)
      +responsive(mobile)
        width: 100%
        transform: translateY(38px)
    &--4
      width: 60%
      transform: translateY(57px)

