@import '../../../../../6_shared/styles/responsive'
@import '../../../../../6_shared/styles/mixins'
@import '../../../../../6_shared/styles/fonts'

.card
  position: relative
  width: 546px
  max-width: 546px
  height: 100%

  z-index: 0
  &:after
    content: ''
    display: block
    position: absolute
    left: 0
    top: 0
    height: calc(100% - 16px)
    width: 100%
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.12)
    pointer-events: none
    background-color: #fff
    border-radius: 24px
    z-index: -1

.gradient
  position: absolute
  display: block
  top: 306px
  height: 24px
  width: 100%
  z-index: 1
  pointer-events: none
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%,  rgba(255, 255, 255, 0) 100%)

.scroll-wrapper
  position: relative
  height: calc(100% - 307px)
  overflow-y: scroll
  overflow-x: visible
  &::-webkit-scrollbar
    display: none

.scroll-content
  display: flex
  flex-direction: column
  gap: 40px
  padding: 24px 20px
  min-height: calc(100% - 16px)
  border-radius: 0 0 24px 24px
  background-color: #fff
  margin-bottom: 16px

.meta
  display: flex
  flex-direction: column
  gap: 24px

.table
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 16px

.table-cell
  display: flex
  gap: 6px
  flex-direction: column
  &--long
    grid-column: 1 / 3

.cell-title
  display: inline-block
  flex-shrink: 0
  color: var(--c-gray-69)

.cell-data
  display: flex
  flex-direction: column
  & > *
    display: inline-block

.cell-data-link
  transition: 0.2s ease
  &:hover
    opacity: 0.6

.metro-list
  margin-top: 4px
  display: flex
  gap: 12px

#metro-item span
  font-size: 15px
  line-height: 20px

#tags ul
  overflow: visible

#menu :global(.keen-slider)
  overflow: visible

.header-top
  display: flex
  justify-content: space-between

.rest-additional-info-wrap
  display: inline-flex
  gap: 3px
  align-items: center
  vertical-align: center

.rest-tag
  color:  rgba(150, 150, 150, 1)

.price-dot
  position: relative
  top: -1px
  width: 4px
  height: 4px
  background-color:  rgba(150, 150, 150, 1)
  opacity: 1
  border-radius: 50%
  margin: 0 4px


.rating-wrapper
  display: flex
  gap: 8px
  align-items: center
  color: rgba(150, 150, 150, 1)

.schedule-button
  margin-top: 12px
  color: rgba(49, 190, 0, 1)

.main-buttons-wrapper
  margin-top: 20px
  display: flex
  gap: 8px
  width: 100%
  align-items: center
  & > *
    flex-shrink: 0

.rest-actions-wrap
  display: flex
  gap: 8px
  svg
    color: #fff

.rest-booking-button
  flex: 1


.chevron
  position: absolute
  top: -9px
  right: 25px
  width: 34px
  height: 34px
  z-index: 1

.button-close
  z-index: 0
