@import '@responsive'
@import '@mixins'
@import '@fonts'


.wrapper
  display: inline-flex
  justify-content: center
  background-color: rgba(164, 255, 48, 1)
  &[data-size='XL']
    width: 56px
    height: 56px
    padding: 20px 2px
    border-radius: 30px
  &[data-size='L']
    width: 48px
    height: 48px
    border-radius: 48px
    padding: 16px 2px

.text
  @include font-a-headline
  color: var(--c-black)
  font-size: 13px
  line-height: 16px
