@import '@responsive'
@import '@mixins'
@import '@fonts'

.link
  width: 100%
  display: flex
  align-items: flex-start
  justify-content: space-between
  padding: 10px 12px
  border-radius: 20px
  background: #FFF
  transition: all .2s ease
  gap: 10px
  .icon
    width: 20px
    height: 20px
    color: rgba(217, 217, 217, 1)
    transition: all .25s ease
  &:hover
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15) inset
    .icon
      color: rgb(94, 94, 94)
