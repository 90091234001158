@import '@responsive'
@import '@mixins'
@import '@fonts'

.popup-wrapper
  z-index: 50
  &[data-status='open']
    transition: transform .18s ease

.popup
  background-color: #fff
  border-radius: 20px
  padding: 16px 20px 20px 20px
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2)
  min-width: 200px
  opacity: 0
  scale: 1
  transform-origin: center

  &[data-status='initial']
    scale: 0.9
    transform-origin: center
  &[data-status='open']
    opacity: 1
    scale: 1
    transition: opacity .18s ease, scale .18s ease

  &[data-status='close']
    opacity: 0
    scale: 0.9
    transition: opacity .2s ease, transform 2s ease 1s, scale 0s ease .3s

.arrow
  fill: white
