@import '@responsive'

.disabled
  opacity: 0.4
  &__value
    opacity: 0.5

.wrapperTextarea
  position: relative

  [data-textarea-wrapper] + .button
    position: absolute
    bottom: 20px
    right: 24px
    +responsive(mobile)
      right: 20px
    svg
      stroke: #FFFFFF

  [data-textarea-wrapper]:not(:focus) + .button:disabled
    opacity: 0

.textarea
  padding: 20px 92px 20px 24px
  box-shadow: var(--e-inner-schedule)
  border: none
  +responsive(mobile)
    padding: 20px 76px 20px 20px
    font-size: 15px
    font-weight: 400
    line-height: 23px
  &:hover:not(disabled),
  &:focus:not(disabled)
    box-shadow: var(--e-inner-schedule)
  &:focus:not(disabled)
    outline-color: transparent



.error
  display: inline-block
  margin-top: 12px
  margin-left: 0
