@import "@responsive"
@import "@mixins"
@import "@fonts"

.widget
  display: flex
  flex-direction: column
  gap: 14px
  padding: 16px 16px 12px 16px
  background-color: rgba(242, 242, 242, 1)
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) inset
  border-radius: 16px

.header
  display: flex
  flex-direction: column
  gap: 8px

.work-time
  color: rgba(49, 190, 0, 1)

.schedule
  max-width: 615px
