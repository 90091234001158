@import '@fonts'
@import '@responsive'
@import '@ownerGlobal'

// *** chatbubbles tab page ***

.page-content
  // empty

.page-content-white-block
  @include page-content-white-block
  &.page-content-white-block--no-padding
    @include page-content-white-block--no-padding

.page-content-section
  width: 100%
  &.page-content-section--centered
    position: absolute
    top: 50%
    left: 50%
    transform: translate3d(-50%, -50%, 0)
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 40px
  &[data-loading=true]
    opacity: 0.7
    pointer-events: none

// * review-filter *

.review-filter-header
  position: relative
  display: block
  margin-bottom: 12px
.review-filter
  display: flex
  justify-content: flex-start
  align-items: center
  gap: 4px
.review-filter-tab-item
  // empty
  &[data-selected=true]
    .review-filter-tab-button
      background: #E1E1E1
      pointer-events: none
.review-filter-tab-button
  display: flex
  justify-content: flex-start
  flex-wrap: nowrap
  align-items: center
.review-filter-tab-text
  white-space: nowrap
.review-filter-tab-label
  margin-left: 10px
  font-size: 13px
  color: #969696

// * review-sort *

.review-sort-button
  position: relative
.review-sort-item-text
  padding: 0 0 0 calc(12px + 10px)
  white-space: nowrap
.review-sort-icon-arrow-down
  position: absolute
  top: 50%
  left: 16px
  transform: translate3d(0, -50%, 0)
  width: 12px
  height: 12px
  svg
    position: absolute
    top: 0
    left: 0
.review-sort-access-select
  color: transparent
  border: none
  background: none
  -webkit-appearance: none
  appearance: none
  position: absolute
  top: 0
  right: 0
  z-index: 2
  height: 100%
  width: 100%
  padding-right: 16px
  cursor: pointer
  border-radius: 20px
.review-sort-access-item
  // empty

// * review-list *

.review-list-widjet
  // empty
.review-list-item
  display: block
  margin-bottom: 24px
  padding-bottom: 24px
  border-bottom: 1px solid rgba(225, 225, 225, 0.5)
  &:last-child
    margin-bottom: 0
    padding-bottom: 0
    border-bottom: none
.review-list-show-all-wrap
  margin-top: 12px
  margin-bottom: 48px
  width: 100%
.review-list-show-all-button
  width: 100%
  height: 60px
  min-height: 60px
  border-radius: calc(60px / 2)
.review-list-show-all-button-text
  // empty
