@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  flex-direction: column
  gap: 20px
  +responsive(mobile)
    gap: 16px
  .error
    margin-left: 0
    margin-top: 0
    +responsive(mobile)
      grid-column: 1 / 3

.header
  display: grid
  gap: 16px
  grid-auto-flow: column
  justify-content: flex-start
  align-items: center
  +responsive(mobile)
    gap: 12px
    grid-auto-flow: initial
    grid-template-columns: auto min-content
    grid-template-rows: auto
    justify-content: space-between

.rate-group
  display: flex
  gap: 8px

.rate
  height: 80px
  width: 80px
  border-radius: calc(80px / 2)
  border: 1px solid var(--c-black-a01)
  font-size: 2em
  color: transparent
  background-color: var(--c-white)
  background-repeat: no-repeat
  background-size: 32px 32px
  padding: 0
  cursor: pointer
  &-like svg,
  &-dislike svg
    stroke: var(--c-gray-59)

  &-like.active
    background: rgba(242, 242, 242, 1)
    svg
      stroke: var(--c-green-1)

  &-dislike
    transform: rotate(180deg)
    &.active
      background: rgba(242, 242, 242, 1)
      svg
        stroke: var(--c-afisha)
