@import 'keen-slider/keen-slider.min.css';
@import './datepicker-custom.css';
@import 'react-image-crop/dist/ReactCrop.css';

*,
*::before,
*::after,
*:hover,
*:active,
*:focus {
  box-sizing: border-box;
}

html,
body,
#react-app-container,
#__next {
  height: 100%;
  width: 100%;
}

html {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /* colors */
  --c-afisha: hsla(6, 100%, 55%, 1);
  --c-green-1: hsla(105, 100%, 37%, 1);
  --c-green-2: hsla(105, 83%, 68%, 1);
  --c-green-3: hsla(105, 62%, 52%, 1);
  --c-gold: hsla(43, 100%, 56%, 0.15);
  --c-yellow: hsla(58, 100%, 59%, 1);
  --c-daily: hsla(273, 100%, 50%, 1);

  --c-black: hsla(0, 0%, 0%, 1);
  --c-black-a01: hsla(0, 0%, 0%, 0.1);
  --c-black-a05: hsla(0, 0%, 0%, 0.5);
  --c-black-a15: hsla(0, 0%, 0%, 0.15);

  --c-gray-20: hsla(0, 0%, 20%, 1);
  --c-gray-45: hsla(0, 0%, 45%, 1);
  --c-gray-59: hsla(0, 0%, 59%, 1);
  --c-gray-61-a32: hsla(0, 0%, 61%, 0.32);
  --c-gray-69: hsla(0, 0%, 69%, 1);
  --c-gray-70: hsla(0, 0%, 70%, 1);
  --c-gray-85: hsla(0, 0%, 85%, 1);
  --c-gray-88: hsl(0, 0%, 82%);
  --c-gray-90: hsla(0, 0%, 90%, 1);
  --c-gray-92: hsla(0, 0%, 92%, 1);
  --c-gray-95: hsla(0, 0%, 95%, 1);

  --c-white: rgb(255, 255, 255, 1);
  --c-white-a072: rgba(255, 255, 255, 0.72);
  --c-white-a07: rgba(255, 255, 255, 0.7);
  --c-white-a05: rgba(255, 255, 255, 0.5);
  --c-white-a035: rgba(255, 255, 255, 0.35);

  /* sizes H */
  --h-button: 48px;
  --h-footer-gap: 24px;
  --h-footer: 470px;
  --h-header: 80px;

  /* sizes W */
  --w-content: 703px;
  --w-footer-aside: 235px;
  --w-narrow-selection: 609px;
  --w-page-padding: 24px;
  --w-rest-card: 297px;
  --w-wide-selection: 609px;
  --w-page-wide: 1628px;

  /* Effects */
  --e-inner-button-border: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
  --e-inner-button: inset 0 0 8px rgba(0, 0, 0, 0.15);
  --e-inner-schedule: inset 0 0 10px rgba(0, 0, 0, 0.1);
  --e-inner-thumb: inset 0 0 60px rgba(0, 0, 0, 0.1);
  --e-inner-cards: inset 0 0 100px rgba(0, 0, 0, 0.1);
  --e-outer-map-filter: 0 0 20px rgba(0, 0, 0, 0.2);
  --e-blur-bg-8: 8px;
}

body {
  margin: 0;
}

/* *typography section* */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 0;
  margin-block-end: 0.618em;
}

h1 {
  font-size: calc(2em);
  font-weight: 700;
}
h2 {
  font-size: calc(1.5em);
}
h3 {
  font-size: calc(1.17em);
}
h4 {
  font-size: calc(1em);
}
h5 {
  font-size: calc(0.83em);
}
h6 {
  font-size: calc(0.67em);
}

p {
  margin-block-start: 0;
  margin-block-end: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* *blocks section* */
ul,
menu,
dir {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  list-style-type: none;
}

/* *inputs section* */
input,
textarea,
keygen,
select,
button {
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
}

img,
svg {
  font-size: calc(0.67em);
}

hr {
  margin-block-start: 1.618em;
  margin-block-end: 0;
  outline: none;
  border: none;
  border-top: 4px dashed hsla(187, 42%, 51%, 62%);
  height: 0;
  max-width: calc(100vw / 1.618);
  width: 100%;
}

/* *for SEO, important* */
.visually-hidden,
[visually-hidden] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* TODO: not used, convert to mixin */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a {
  color: black;
  text-transform: none;
  text-decoration: none;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  color: black;
  text-transform: none;
}

.global-container {
  max-width: calc(var(--w-page-wide) + var(--w-page-padding) * 2);
  margin: auto;
  padding: 0 var(--w-page-padding);
  width: 100%;
}
@media (max-width: 768px) {
  .global-container {
    padding: 0 12px;
  }
}

.content-container {
  margin: 0 auto;
}
@media (min-width: 1280px) and (max-width: 1592px) {
  .content-container {
    max-width: 1236px;
  }
}
@media (min-width: 960px) and (max-width: 1280px) {
  .content-container {
    max-width: 923px;
  }
}

.temp-block {
  padding: 1em 0;
  margin: 2em 0;
}
.temp-block button, .temp-block a {
  border: 2px solid hsl(0, 0%, 61.8%);
  min-height: 3em;
  font-weight: 600;
  padding: 1em;
  border-radius: 1em;
}
.temp-nowrap {
  white-space: nowrap;
}
