@import '@responsive'
@import '@mixins'
@import '@fonts'

.img
  overflow: hidden
  z-index: 0
  &:before
    content: ''
    display: block
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.12) inset
    z-index: 1

  &:after
    content: ''
    display: block
    position: absolute
    width: 440px
    height: 220px
    top: 88px
    left: 77px
    pointer-events: none
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)
    z-index: 1

.logo
  position: absolute
  right: 10px
  bottom: 8px
  width: 92px
  height: 32px
  z-index: 2

