@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  flex-direction: column
  gap: 36px

.pagination-wrapper
  display: flex
  gap: 4px
  justify-content: center
  align-items: center
.pagination-text
  line-height: 16px
  text-align: inherit
.pagination-empty
  height: 24px
  margin-bottom: 36px

.pagination-prev,
.pagination-next
  width: 30px
  height: 60px
  display: block
  border: 2px solid
  user-select: none
  color: transparent
  border: none
  background-repeat: no-repeat
  background-size: 9px 18px
  border-radius: 12px
  transition: all 0.2s ease
  &:not(:disabled):hover
    background-color: #f2f2f2
  &[disabled]
    opacity: 0.4
    user-select: none
    cursor: not-allowed
.pagination-prev,
.pagination-next
  display: flex
  justify-content: center
  align-items: center
  svg
    width: 9px
    height: 18px
    color: #000
.pagination-first,
.pagination-item,
.pagination-last
  border: none
  width: 60px
  height: 60px
  border-radius: 50%
  text-align: center

.pagination-first,
.pagination-item,
.pagination-last
  transition: all 0.2s ease
  &:disabled
    pointer-events: none
    user-select: none
  &[data-selected=true]
    pointer-events: none
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1) inset
  &:hover
    box-shadow: none
    background-color: #f2f2f2

.show-more-wrapper
  position: relative
  height: 56px
