@import '@fonts'
@import '@responsive'

.text
  margin: 0
  text-align: left
  text-decoration: none
  white-space: pre-wrap
  @include font-regular

  &[data-size='XXL']
    font-size: 32px
    line-height: 36px

  &[data-size='XL']
    font-size: 24px
    line-height: 28px
    &[data-paragraph]
      line-height: 31px
    &[data-paragraph][data-medium]
      @include font-medium
    &[data-numbers]
      letter-spacing: 0.96px

  &[data-size='L']
    font-size: 20px
    line-height: 24px
    &[data-medium]
      @include font-medium

  &[data-size='ML']
    font-size: 18px
    line-height: 22px
    &[data-paragraph]
      line-height: 27px
    &[data-medium]
      @include font-medium

  &[data-size='M']
    font-size: 15px
    line-height: 20px
    &[data-medium]
      @include font-medium
    &[data-paragraph]
      line-height: 23px
    &[data-numbers]
      line-height: 23px
      letter-spacing: 0.9px
    &[data-uppercase]
      line-height: 23px
      text-transform: uppercase

  &[data-size='S']
    font-size: 13px
    line-height: 18px
    &[data-numbers]
      letter-spacing: 0.78px
    &[data-uppercase]
      letter-spacing: 0.13px
      text-transform: uppercase
