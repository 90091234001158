@import '@responsive'
@import '@mixins'
@import '@fonts'

@layer widgets
  .wrapper
    border-bottom: 1px solid #EBEBEB
    padding-bottom: 4px
    &:last-child
      border-bottom: none
      padding-bottom: 0
    &[data-open=true]
      svg
        transform: rotate(270deg)
      .answer
        //transition-delay: .2s
        transition: height .2s ease 0s
        &__text
          transition: color .2s ease .15s
          color: #737373

  .question
    display: flex
    justify-content: space-between
    align-items: flex-start
    gap: 32px
    padding: 22px 0 13px 0
    cursor: pointer
  .question__title
    margin-top: 4px


  .arrow
    flex-shrink: 0
    width: 32px
    height: 32px
    svg
      width: 100%
      height: 100%
      transition: transform .2s ease
      transform: rotate(90deg)

  .answer
    overflow: hidden
    transition: height .2s ease .15s

    &__text
      transition: color .2s ease 0s
      color: transparent
      padding-bottom: 12px
