@import '@responsive'
@import '@mixins'
@import '@fonts'

.button
  font-size: inherit
  min-height: 60px
  height: 60px
  appearance: none
  text-decoration: none
  outline: none
  margin: 0
  user-select: none
  white-space: nowrap
  transition: all .2s ease

  line-height: 58px
  border-radius: 30px
  background: #fff
  border: 1px solid #e9e9e9
  color: #000
  cursor: pointer
  box-shadow: none

  &[data-color='white']
    background-color: #fff
    -webkit-tap-highlight-color: rgba(0,0,0,0)

  &[data-color='red']
    background-color: #ff3118
    border: 1px solid #ff3118
    color: #fff
    &:hover
      box-shadow: 4px 4px 24px rgba(240,41,62,.4)

  &[data-color='black']
    background: #000000
    border: 1px solid #000000
    color: #FFFFFF
    &:active:not(:disabled)
      color: rgba(255, 255, 255, 0.54)
    &:hover:not(:disabled)
      box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.4)

  &:hover:not(:disabled)
    transform: scale(1.05)
    will-change: transform

  &[disabled]
    opacity: 0.4
    pointer-events: none
