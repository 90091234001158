@import '@responsive'
@import '@mixins'

.container
  display: flex
  flex-direction: column

.header
  display: flex
  justify-content: space-between
  align-items: flex-start

.title
  margin-top: 2px
  +responsive(mobile)
    margin-top: 4px


