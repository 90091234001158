@import '@responsive'

$c-grey-contour: #E9E9E9
$w-padding-layout--mob: 16px
$h-header--mob: 92px
$rad-content--desk: 12px
$booking-grid-w: 597px

.booking
  position: fixed
  z-index: 320 // +1000 for index under floating header
  top: 0
  left: 0
  right: 0
  bottom: 0
  box-sizing: border-box

  &--hidden
    visibility: hidden
    opacity: 0
  &--desktop
    height: auto

.booking-scroll-wrap
  overflow: auto
  position: fixed
  z-index: 30
  width: 100%
  height: 100%
  top: 0
  left: 0

.booking-content
  max-width: $booking-grid-w
  width: 100%
  background: #F2F2F2
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12), 0 12px 16px rgba(0, 0, 0, 0.12), 0 16px 32px rgba(0, 0, 0, 0.12)
  z-index: 313
  // transition: all 0.35s
  transition-timing-function: cubic-bezier(1, 0, 1, 1)
  //@include family-regular
  font-size: 15px
  opacity: 1
  transform: translate3d(0%, 0%, 0)
  left: auto
  right: 0
  top: 0
  bottom: 0
  height: 100vh
  position: fixed
  padding-top: 76px
  border-radius: $rad-content--desk 0 0 $rad-content--desk
  &::after
    content: ''
    background: linear-gradient(#F2F2F2, #f2f2f200)
    position: absolute
    z-index: 1
    top: 76px
    left: 0
    right: 0
    width: 100%
    height: 25px
    +responsive(mobile)
      top: 92px
  &.booking-content--success
    padding-top: 0
    &::after
      display: none
  &.booking-content--confirm
    &::after
      display: none
  +responsive(mobile)
    opacity: 1
    transition-timing-function: cubic-bezier(0, 1, 1, 1)
    transform: translate3d(-50%, 0%, 0)
    left: 50%
    right: 0
    // TODO: use env(safe-area-inset-top) for top screen padding
    top: 8px
    height: calc(100% - 8px)
    bottom: 0
    position: fixed
    padding-bottom: 0
    padding-top: $h-header--mob
    border-radius: 16px 16px 0 0
    &.booking-content--success
      padding-top: 0
      padding-bottom: 0
      .booking-header
        padding-top: 0
      &::after
        display: none

.booking-overlay
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 311
  transition: opacity 0.35s
  background-color: rgba(0, 0, 0, 0.5)
  backdrop-filter: blur(2px)
  -webkit-backdrop-filter: blur(2px) // TODO: fix autoprefixer
  transform: translate3d(0, 0, 0) // must use translate3d for blur optimization
  animation: a-modal-background 0.2s ease
  opacity: 1
  transition-timing-function: cubic-bezier(0, 1, 1, 1)
