@import "@responsive"

// для пропорций от ширины экрана
@function vw($px)
  $vw-context: 375 * 0.01 * 1px
  @return $px / $vw-context * 1vw
  @return $px

$modal-padding: 30px

@keyframes a-mode-modal
  0%
    transform: scale(1.1)
    opacity: 0
    visibility: hidden
  100%
    transform: scale(1)
    opacity: 1
    visibility: visible

@keyframes a-mode-dropdown
  0%
    transform: translateY(200px)
    opacity: 0
  50%
    opacity: 1
  100%
    transform: translateY(0)

@keyframes a-modal-background
  0%
    opacity: 0
  100%
    opacity: 1


.modal
  text-align: center
  overflow-y: auto
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 50
  background-color: rgba(0, 0, 0, 0.5)
  backdrop-filter: blur(2px)
  -webkit-backdrop-filter: blur(2px) // TODO: fix autoprefixer
  transform: translate3d(0, 0, 0) // must use translate3d for blur optimization
  animation: a-modal-background 0.2s ease
  +responsive(mobile)
    padding-top: 44px
    display: flex
    align-items: flex-end
    backdrop-filter: none
    -webkit-backdrop-filter: none // TODO: fix autoprefixer
  &:after
    display: inline-block
    vertical-align: middle
    width: 0
    height: 100%
    content: ''

  #button-close
    z-index: 10
    grid-column: 2 / 3
    flex-shrink: 0
    +responsive(desktop)
      padding: 6px
      svg
        height: 48px
        width: 48px
    +responsive(tablet)
      padding: 6px
      svg
        height: 48px
        width: 48px
    +responsive(mobile)
      display: flex
      justify-content: center
      align-items: center
      height: 34px
      width: 34px
      border: none
      padding: 0
      svg
        height: 32px
        width: 32px

    &.button-close--absolute
      position: absolute
      top: 24px
      right: 24px
      +responsive(mobile)
        top: 16px
        right: 20px
    &.button-close--static
      position: relative
      padding: 0
      height: initial
      width: initial
      border: none
      svg
        width: 48px
        height: 48px
      +responsive(mobile)
        padding: 3px
        right: 0
        top: 0
        svg
          width: 24px
          height: 24px
          color: #909099

.desktop-size
  &-XS .inner
    max-width: calc(480px + ($modal-padding * 2))
  &-S .inner
    max-width: calc(620px + ($modal-padding * 2))
  &-M .inner
    max-width: calc(800px + ($modal-padding * 2))
  &-L .inner
    max-width: calc(920px + ($modal-padding * 2))
  &-XL .inner
    max-width: calc(1040px + ($modal-padding * 2))

.inner
  position: relative
  display: inline-block
  vertical-align: middle
  width: 100%
  +responsive(mobile)
    vertical-align: bottom
    max-height: 100%
    overflow: visible
    display: flex
    flex-direction: column
    align-items: flex-end

.container
  position: relative
  text-align: initial
  white-space: initial
  background-color: #ffffff
  display: grid
  grid-template-rows: auto 1fr
  box-sizing: border-box
  overflow: hidden
  margin: 60px $modal-padding !important
  margin: 0
  padding: 24px 0 26px 0
  border-radius: 32px
  +responsive(mobile)
    width: 100vw
    max-height: calc(100vh - 44px)
    padding: 16px 0 0 0
    border-radius: 24px 24px 0 0
    margin: 0 !important

.header
  padding: 0 24px 12px 24px
  +responsive(mobile)
    padding: 0 12px 24px 12px
    max-width: 100vw

.header-top
  display: grid
  justify-content: space-between
  grid-template-columns: 84% auto
  align-items: start
  gap: 30px

.header-title
  overflow: hidden
  width: 100%
  padding-top: 5px
  +responsive(mobile)
    padding-top: 0

.content
  position: relative
  overflow: auto
  margin: 0
  padding: 0 24px 0 24px
  +responsive(mobile)
    margin: -12px -10px 0px 0
    padding: 12px 22px 20px 12px

.content-shadow
  +responsive(mobile)
    position: fixed
    height: 0
    left: 11px
    right: 11px
    pointer-events: none
    z-index: 1
    &:after
      display: block
      content: ''
      position: absolute
      left: 0
      right: 0
    &.top:after
      top: -12px
      height: 17px
      background: linear-gradient(180deg, #FFFFFF 0%, transparent 100%)
    &.bottom
      bottom: 0
      &:after
        bottom: 0
        height: 25px
        background: linear-gradient(0deg, #FFFFFF 0%, transparent 100%)

.content-inner
  display: block

.mobile-mode--dropdown-image
  +responsive(mobile)
    .container
      padding-top: 24px
      margin-top: -20px
    .header
      display: block
      padding: 0 16px 12px 16px
    .dropdown-image
      position: relative
      width: 100%
      overflow: hidden
      border-radius: 24px 24px 0 0
      height: vw(240px)
      max-height: 370px
      object-fit: cover
      flex-shrink: 0
      img
        object-fit: cover
    #button-close
      z-index: 10
      position: absolute
      top: 12px
      background-color: transparent
      right: 12px
      color: white
      margin-top: 0
