@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  gap: 16px
  margin-bottom: 24px
  align-items: start
  & > *:last-child
    position: relative
    top: 2px
    +responsive(only-desktop-large)
      top: 4px
    +responsive(mobile)
      top: 0
  +responsive(mobile)
    width: 100%
    gap: 12px
    justify-content: space-between
    margin-bottom: 16px

.title-link
  transition: color .2s ease
  &:hover
    color: var(--c-afisha)

.button[data-size=S]
  +responsive(mobile)
    height: 28px


.svg-skeleton,
.rect-skeleton
  width: 533px
  height: 40px
  +responsive(mobile)
    width: 307px
    height: 28px

