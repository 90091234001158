@import '@responsive'
@import '@mixins'
@import '@fonts'

.card
  display: grid
  grid-template-columns: auto 68px
  grid-template-rows: min-content min-content
  padding: 12px
  min-height: 131px
  gap: 24px 16px
  border-radius: 16px
  background-color: #fff

.content,
.block-type-and-place
  display: flex
  flex-direction: column
.content
  gap: 8px
.block-type-and-place
  gap: 6px
