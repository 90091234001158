@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  height: 219px
  +responsive(only-desktop-large)
    height: 297px
  +responsive(mobile)
    height: initial

.photo
  position: relative
  width: 219px
  height: 100%
  flex-shrink: 0
  border-radius: 16px
  overflow: hidden
  background-color: var(--c-black)
  color: var(--c-white)
  object-fit: cover
  object-position: center
  +responsive(only-desktop-large)
    width: 297px
  img
    object-fit: cover
    transition: transform .5s ease
    transform: scale(1)
    cursor: pointer
    &:hover
      transform: scale(1.03)

.content
  cursor: pointer
  padding: 24px 24px 10px 24px
  border-radius: 16px
  width: 100%
  height: 100%
  overflow: hidden
  position: relative
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10) inset

  &__inner
    position: relative
    overflow: hidden
    height: 100%
    &::after
      pointer-events: none
      content: ''
      position: absolute
      bottom: 0
      left: 0
      right: 0
      width: 100%
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
      height: 82px

.subtitle
  color: var(--c-gray-59)
  margin-bottom: 4px

.title
  margin-bottom: 12px
  transition: color .2s ease
  &:hover
    color: var(--c-afisha)
  +responsive(only-desktop-large)
    max-width: initial

.description[data-size=M][data-paragraph]
  line-height: 22px


