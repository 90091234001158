@import '@responsive'
@import '@mixins'
@import '@fonts'

.quick-filter
  margin-top: 28px
  +responsive(mobile)
    margin-top: 20px


.quick-filter-title > a > *
  text-wrap: initial
