@import '@responsive'
@import '@mixins'
@import '@fonts'


$paymentItemWidth: 297px
$paymentImageHeight: 198px

@mixin webkit-3d
  transform: translate3d(0, 0, 0)
  -webkit-transform: translate3d(0, 0, 0)
  perspective: 1000
  -webkit-perspective: 1000

.payment-item
  border-radius: 16px
  width: $paymentItemWidth
  position: relative
.payment-item-afisha-selected
  position: absolute
  z-index: 1
  top: -4px
  right: 16px
  svg
    width: 28px
    height: 28px
.payment-name
  font: inherit
  font-size: 20px
  font-weight: 400
  line-height: 24px
  margin-bottom: 4px
  cursor: pointer
.payment-genre,
.payment-time-descr
  display: block
  font: inherit
  font-size: 13px
.payment-genre
  color: hsla(0, 0%, 59%, 1)
  margin-bottom: 4px

.payment-item-wrap
  position: relative
  margin: 0
  border-top-right-radius: inherit
  border-bottom-right-radius: inherit
  border-top-left-radius: inherit
  border-bottom-left-radius: inherit
  background: #e7e7e7
  width: inherit
  height: $paymentImageHeight
  margin-bottom: 8px
  overflow: hidden
  cursor: pointer
  &:hover
    .payment-item-picture
      transform: scale(1.03) translate3d(0, 0, 0)
.payment-item-picture
  min-width: 100%
  min-height: 100%
  max-height: $paymentImageHeight
  border-top-right-radius: inherit
  border-bottom-right-radius: inherit
  border-top-left-radius: inherit
  border-bottom-left-radius: inherit
  object-fit: cover
  position: absolute
  transform: scale(1) translate3d(0, 0, 0)
  transition: all .35s ease
  transform-origin: center
  font-size: 10px
  color: #595959
.payment-item-open-modal-wrap
  @include webkit-3d
  width: 100%
  height: 100%
  display: block
  position: absolute
  top: 0
  left: 0
  z-index: 3
  display: flex
  gap: 4px
  justify-content: flex-end
  align-items: flex-end
  height: $paymentImageHeight
  padding: 8px
  overflow: hidden
  border-radius: inherit
  pointer-events: none
.payment-item-open-modal
  @include webkit-3d
  $openModalHeight: 32px
  border: none
  padding: 0 10px
  pointer-events: all
  box-sizing: border-box
  position: relative
  height: $openModalHeight
  border-radius: calc($openModalHeight / 2)
  line-height: calc($openModalHeight - 1px)
  font-size: 13px
  font-weight: 400
  background: #ffffff
  cursor: pointer
  transition: all 0.2s ease
  &:hover
    background-color: #f2f2f2


.favorite
  pointer-events: all
