@import "@responsive"
@import "@mixins"
@import "@fonts"

.widget
  display: flex
  gap: 12px
  +responsive(mobile)
    flex-wrap: wrap
    gap: 8px

a.link:hover
  text-decoration: none
