@import '@responsive'
@import '@mixins'
@import '@fonts'

$calendarArrowsWidth: 32px
$calendarHeightDesktop: 70px

$calendarHeightMobile: 52px

.calendar-wrap-prev,
.calendar-wrap-next
  border-radius: 12px
  text-align: center
  color: transparent
  width: $calendarArrowsWidth
  height: $calendarHeightDesktop
  +responsive(mobile)
    height: $calendarHeightMobile
  position: absolute
  top: auto
  bottom: 0
  &::before,
  &::after
    display: none
    content: ''
    position: absolute
    top: 0
    bottom: 0
    width: 100%
    background: transparent
    z-index: 1
    pointer-events: none

.calendar-wrap-prev
  left: 0
  &::before
    border-top-left-radius: inherit
    border-bottom-left-radius: inherit
  &::after
    left: 100%
    width: 20px
    right: auto
    background: linear-gradient(270deg, #FFFFFF00 0%, #FFFFFFFF 100%)
    pointer-events: none
  &.calendar-wrap-prev--has-overlay
    &::after
      display: block
  &.calendar-wrap-prev--without-button
    &::after
      left: 0

.calendar-wrap-next
  right: 0
  &::before
    border-top-right-radius: inherit
    border-bottom-right-radius: inherit
  &::after
    right: 100%
    width: 20px
    left: auto
    background: linear-gradient(270deg, #FFFFFFFF 0%, #FFFFFF00 100%)
    pointer-events: none
  &.calendar-wrap-next--has-overlay
    &::after
      display: block
  &.calendar-wrap-next--without-button
    &::after
      right: 0

.calendar-button-prev,
.calendar-button-next
  display: flex
  align-items: center
  justify-content: center
  background-color: #fff
  width: 100%
  height: 100%
  border-radius: inherit
  pointer-events: all
  border: 1px solid rgba(225, 225, 225, 1)
  position: relative
  z-index: 2
  user-select: none
  color: transparent
  transition: all 0.2s
  svg
    width: 24px
    height: 24px
    color: black
    transition: color .2s ease
  disabled &:hover
    box-shadow: inset 0 0 8px var(--c-black-a15)
  &:active
    background-color: rgb(242, 242, 242)
  button:disabled &
    svg
      color: var(--c-gray-88)

    pointer-events: none

.calendar-button-prev
  transform: rotate(180deg)
