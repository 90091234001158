@import '@responsive'
@import '@mixins'
@import '@fonts'


.section
  display: flex
  flex-direction: column
  gap: 20px

.header
  display: flex
  justify-content: space-between
  align-items: center

.title
  margin-bottom: 0

.slider:global(.keen-slider:not([data-keen-slider-disabled]))
    overflow: visible
