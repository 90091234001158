@import '@responsive'
@import '@mixins'
@import '@fonts'

.control
  display: flex
  flex-direction: column
  align-items: center
  width: 56px
  border-radius: 28px
  background-color: #fff
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2)

.separator
  background-color: var(--c-gray-92)
  height: 1px
  width: 40px


.zoomIn
  border-radius: 50% 50% 0 0

.zoomOut
  border-radius: 0 0 50% 50%
