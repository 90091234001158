@import '@responsive'
@import '@mixins'
@import '@fonts'

@keyframes show
  0%
    opacity: 0
  100%
    opacity: 1


.image-wrapper
  position: relative

.image
  border-radius: 24px
  display: block
  background-color: #dedede
  object-fit: cover

.badge-gallery
  position: absolute
  display: inline-flex
  align-items: center
  gap: 6px
  left: 20px
  bottom: 20px
  border-radius: 31px
  padding: 12px 16px
  background: rgba(178, 178, 178, 0.35)
  backdrop-filter: blur(8px)
  color: white
  animation: show .4s ease .4s
  animation-fill-mode: both
  svg
    width: 16px
    height: 16px

.badge-gallery-count
  position: relative
  top: 1px

.arrows
  height: auto
  position: absolute
  top: 247px
  right: 20px
  display: inline-flex
  align-items: center
  gap: 4px
  z-index: 1
  animation: show .4s ease .4s
  animation-fill-mode: both
  button:last-child
    transform: rotate(180deg)

.arrow
  &:disabled svg
    opacity: 0.4
