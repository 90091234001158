@import '@shared/styles/responsive'
@import '@shared/styles/mixins'
@import '@shared/styles/fonts'

.wrapper
  display: flex
  gap: 8px
  overflow: hidden
  max-height: 52px
  +responsive(mobile)
    gap: 6px

.slots
  display: flex
  flex-wrap: wrap
  gap: 8px
  +responsive(mobile)
    gap: 6px

.slot-error
  color: #7c7c7c
