@import '@responsive'
@import '@mixins'
@import '@fonts'

.photo-wrap
  display: block
  grid-row: 1 / 3
  +responsive(mobile)
    grid-row: initial

.content
  display: flex
  flex-direction: column
  gap: 14px
  align-items: flex-start
  grid-column: 2
  overflow: hidden
  +responsive(mobile)
    grid-column: 1 / 3
    gap: 8px
    justify-content: space-between

.content-head
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center

.head-main
  display: flex
  justify-content: flex-start
  gap: 18px
  align-items: center
  +responsive(mobile)
    flex-direction: row-reverse
    align-items: flex-start
    width: 100%
    justify-content: space-between

.like-type
  font-size: 2em
  color: transparent
  padding: 3.2px 13.2px
  border: 1px solid var(--c-gray-88)
  display: flex
  justify-content: center
  align-items: center
  border-radius: 20px
  svg
    position: relative
    height: 28px
    width: 28px
    +responsive(mobile)
      height: 24px
      width: 24px
  &--positive
    border-color: #31BE00
    svg
      color: #31BE00

  &--negative
    border-color: var(--c-gray-88)
    transform: rotate(180deg)
    svg
      color: var(--c-gray-59)

  +responsive(mobile)
    padding: 5.2px
    svg
      height: 24px
      width: 24px


.head-info-wrap
  display: flex
  gap: 4px
  flex-direction: column

.date-action-wrapper
  display: flex
  align-items: center
  gap: 15px
  +responsive(mobile)
    max-height: 28px
    width: 100%
    justify-content: space-between

  button.settings-button
    background-color: transparent
    +responsive(mobile)
      padding: 3px

.disclose-comment
  +responsive(mobile)
    button
      display: none

div.settings
  padding: 4px

.settings-modal
  background-color: transparent
  & [data-shadow-top],
  & [data-shadow-bottom]
    display: none

.settings-modal-content
  background-color: #fff
  border-radius: 24px
  padding: 4px 4px
  & > button
    padding: 12px 12px
  .setting-cancel
    color: #666666

.user-name
  color: #000

.detailed-wrapper
  display: flex
  flex-wrap: wrap
  gap: 0 4px
.detailed,
.date
  color: var(--c-gray-59)
  +responsive(mobile)
    color: #737373

.create-answer-wrapper
  width: 100%

.answer
  margin-top: 22px
  grid-column: 2
  grid-row: 3
  [data-mode='row'] &
    +responsive(mobile)
      grid-column: 1 / 3
      margin-left: 28px

