@import '@responsive'

.header
  height: auto
  background-color: #F2F2F2
  border-radius: inherit
  display: flex
  justify-content: flex-start
  align-items: flex-start
  position: absolute
  z-index: 30
  top: 0
  left: 0
  width: 100%
  padding-top: 16px

  button.button-close
    position: absolute
    right: 10px
    top: 10px
    background-color: transparent
    color: #969699

.title-wrapper
  padding: 0 65px 0 16px
  display: flex
  justify-content: flex-start
  flex-direction: column
  gap: 8px
  margin-bottom: 0 !important // conflict with reset

.title[data-size=h3]
  width: 100%
  text-align: left
  display: block
  text-transform: uppercase
  font-size: 28px
  line-height: 32px
  letter-spacing: 0.28px
  +responsive(mobile)
    font-size: 22px
    line-height: 24px
    letter-spacing: 0.22px

.rest-name
  font-size: 15px
  line-height: 20px
  color: #909099
  font-weight: 400

.main
  padding: 20px 12px 92px
  height: 100%
  max-height: 100%
  overflow-x: hidden
  overflow-y: auto
  &::-webkit-scrollbar
    width: 0
  +responsive(mobile)
    padding: 20px 15px 92px

  &::after
    // bottom margin
    content: ''
    height: 96px
    width: 100%
    position: absolute
    pointer-events: none

.fields
  display: flex
  flex-direction: column
  gap: 12px
  background: #ffffff
  border-radius: 12px
  padding: 12px
  margin-bottom: 16px
  +responsive(mobile)
    margin-bottom: 12px

.footer
  background: white
  position: absolute
  z-index: 30
  bottom: 0
  left: 0
  right: 0
  border-radius: inherit
  background: linear-gradient(180deg, rgba(246, 246, 246, 0) 0%, #F6F6F6 45.84%)
  padding: 12px 12px 12px 26px
  height: 92px
  justify-content: space-between
  display: flex
  align-items: center
  gap: 12px
  +responsive(mobile)
    padding: 10px 16px

button.submit
  padding-left: 70px
  padding-right: 70px
  height: 68px
  border-radius: 70px
  +responsive(mobile)
    width: 100%
    height: 60px
    padding-left: 20px
    padding-right: 20px
    font-size: 15px
  &:active:not(:disabled)
    color: rgba(255, 255, 255, 0.54)
  &:hover:not(:disabled)
    box-shadow: 4px 4px 24px rgba(240, 41, 62, 0.4)

.policy
  margin-bottom: 16px
.policy-link
  color: #969696
  text-decoration: underline
  cursor: pointer
  transition: all .2s ease
  &:hover
    color: var(--c-afisha)
