@import '@fonts'
@import '@responsive'

.card
  position: relative
  border-radius: 24px
  background-color: rgba(0, 0, 0, 0.05)

  &[data-size="D2x1"]
    width: 610px
    min-width: 610px
    height: 297px
    .title
      max-width: 350px
  &[data-size="D1x1"]
    width: 297px
    min-width: 297px
    height: 297px
  &[data-size="M1x1"]
    width: 261px
    min-width: 261px
    height: 261px

  &:hover .image
    transform: scale(1.03) translate3d(0,0,0)

.button
  position: absolute
  z-index: 1
  bottom: 12px
  right: 12px
  svg
    stroke: black
    fill: transparent

.numbers
  @include font-a-headline
  position: absolute
  top: 12px
  left: 16px
  z-index: 1
  font-size: 60px
  font-style: normal
  font-weight: 500
  line-height: 60px
  letter-spacing: -1.2px
  color: var(--c-white)

.title
  position: absolute
  z-index: 1
  bottom: 16px
  left: 16px
  margin-bottom: 0
  width: calc(100% - 60px)
  color: var(--c-white)
  display: -webkit-box
  -webkit-line-clamp: 8
  -webkit-box-orient: vertical
  overflow: hidden


.image-wrapper
  overflow: hidden
  border-radius: 24px
  width: 100%
  min-width: 100%
  height: 100%
  display: block
  position: relative
  -webkit-transform: translateZ(0)
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%)
  background-color: rgba(0, 0, 0, 0.05)
  &::after,
  &::before
    content: ''
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.15) 100%)
    //border-radius: 24px
    position: absolute
    bottom: 0
    left: 0
    right: 0
    width: 100%
    height: 70%
  &::before
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.2) 100%)
    height: 30%
    bottom: initial
    top: 0
    z-index: 1
    pointer-events: none

.image-inner
  min-height: 297px
  max-width: 100%
  border-radius: 24px
  position: relative
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.image
  object-fit: cover
  transform: scale(1) translate3d(-0,0,0)
  transition: transform .6s ease

#promo
  position: absolute
  right: 12px
  top: 12px
  z-index: 2

.afisha-selection-chevron--medium
  position: absolute
  left: 13px
  top: -6px
  z-index: 2
  svg
    width: 29px
    height: 50px
.afisha-selection-chevron--big
  position: absolute
  left: 16px
  top: -8px
  z-index: 2
  svg
    width: 36px
    height: 64px

.favorite
  position: absolute
  right: 12px
  bottom: 12px
  z-index: 1

.skeleton-card
  position: relative

  &[data-size="D2x1"]
    width: 610px
    min-width: 610px
    height: 297px
  &[data-size="D1x1"]
    width: 297px
    min-width: 297px
    height: 297px
  &[data-size="M1x1"]
    width: 261px
    min-width: 261px
    height: 261px

.skeleton-inner
  width: 100%
  height: 100%
