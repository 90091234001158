@import '@responsive'
@import '@mixins'
@import '@fonts'

@keyframes close-navigation-bottom
  0%
    background: white
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2)
    height: auto
  99%
    background: transparent
    box-shadow: 0 0 20px rgba(0, 0, 0, 0)
    height: auto
  100%
    height: 72px

.nav-wrapper
  display: flex
  flex-direction: column
  height: 48px

.nav
  position: relative
  display: flex
  gap: 4px
  padding: 12px
  box-sizing: border-box
  height: 72px
  z-index: 1
  top: -12px
  border-radius: 32px

  &:after
    content: ''
    display: block
    position: absolute
    left: -28px
    right: -28px
    top: -4px
    bottom: -28px
    z-index: -1
    pointer-events: none
  &[data-opened=true]:after
    pointer-events: initial

  &[data-opened=true]
    transition: all .25s ease
    background: white
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2)
    height: auto
    position: relative

  &[data-opened=false]
    animation-direction: alternate
    animation-name: close-navigation-bottom
    animation-duration: .25s
    animation-delay: 0s

  .nav-item
    padding: 15px 16px

.nav-top
  display: flex
  overflow: hidden
  gap: 4px
  flex-wrap: wrap
  height: 48px
  & > .nav-item
    display: flex
    flex-grow: 1

.nav-bottom
  position: relative
  margin-top: 4px
  display: flex
  flex-wrap: wrap
  gap: 4px
  margin-right: calc((4px + 84px + 4px + 48px) * -1)
  transition: opacity .25s ease
  opacity: 0
  pointer-events: none
  [data-opened=true] &
    opacity: 1
    pointer-events: initial
