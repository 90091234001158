@import '../../../6_shared/styles/responsive'
@import '../../../6_shared/styles/mixins'
@import '../../../6_shared/styles/fonts'

.rest-schedule
  cursor: pointer
  display: flex
  align-items: center
  gap: 4px
  background-color: transparent
  border: none
  padding: 0
  color: #8DF16B
  +responsive(mobile)
    color: #31BE00
  &[data-not-click=true]
    pointer-events: none

  &-icon
    margin-top: -2px
    height: 16px
    width: 16px
    transition: transform .2s ease
    transform: rotate(0)
    &[data-opened=true]
      transform: rotate(180deg)

.popup
  width: 318px
  & > div:first-child
    margin-bottom: 16px

.popup-list
  display: flex
  flex-direction: column
  gap: 8px
  +responsive(mobile)
    & > *
      padding: 16px 0
      border-bottom: 1px solid #F2F2F2
      &:first-child
        padding-top: 0
      &:last-child
        border-bottom: none
        padding-bottom: 0

.popup-list-item
  justify-content: space-between
  display: flex
  gap: 4px
  +responsive(mobile)
    justify-content: space-between

  & [data-is-open=false]
    opacity: 0.3

.notice
  margin-top: 20px
  display: flex
  gap: 12px
  padding: 6px 11px
  border-radius: 12px
  background-color: #F2F2F2
  +responsive(mobile)
    gap: 8px
    padding: 14px 15px
    background-color: rgba(1, 1, 1, 0.03)
  svg
    margin-top: 2px
    height: 20px
    width: 20px
    color: #B3B3B3
    +responsive(mobile)
      color: #969696

.notice-text
  display: inline-block
  color: #B3B3B3
  @include font-regular
  font-size: 10px
  font-style: normal
  font-weight: 400
  line-height: 13px
  max-width: 150px
  align-self: center
  +responsive(mobile)
    font-size: 13px
    line-height: 18px
    max-width: initial
    color: rgba(8, 8, 8, 0.56)


