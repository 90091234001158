@import '@fonts'
@import '@responsive'

.access-restricted-section
  width: 100%
  &.access-restricted-section--centered
    position: fixed
    top: 50%
    left: 50%
    transform: translate3d(-50%, -50%, 0)
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 40px

.access-restricted
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  gap: 20px
  max-width: 798px
  min-width: 798px
  width: 798px
  padding: 24px 16px

.access-restricted-title
  white-space: nowrap

.access-restricted-description
  color: #8c8c8c
  text-align: center
  width: 496px

.access-restricted-mail-link
  text-decoration: underline
  color: inherit

.access-restricted-button
  margin-bottom: 0
