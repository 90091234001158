
button.button
  white-space: nowrap
  padding-left: 14px
  padding-right: 14px
  & > div
    gap: 5px
  &[data-active=true]
    background-color: var(--c-gray-95)
    box-shadow: var(--e-inner-button)
    &:hover
      background-color: var(--c-gray-92)

  &__counter
    color: var(--c-gray-59)
    font-size: 13px

.skeleton
  height: 40px
  width: 100px
.skeleton-rect
  width: 100%
  height: 100%
