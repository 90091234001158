@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  height: 100%
  display: flex
  align-items: flex-start
  gap: 16px


.grid-container
  position: relative
  height: 100%
  z-index: 0
  margin-right: 45px
  max-width: 546px

.grid
  position: relative
  display: flex
  flex-direction: column
  padding: 16px 20px
  gap: 20px
  height: calc(100% + 24px)
  background-color: #fff
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15)
  border-radius: 24px


.title
  z-index: 2
  max-width: 470px
  text-wrap: initial

.list-wrapper
  position: relative
  height: 100%

.list
  display: flex
  flex-wrap: wrap
  gap: 32px 0
  justify-content: space-between
  width: 506px
  height: 100%
  overflow: auto
  padding-top: 16px
  transform: translateY(-16px)
  padding-bottom: 250px
  &::-webkit-scrollbar
    display: none

.gradient
  display: block
  position: absolute
  left: -10px
  right: -10px
  top: -17px
  height: 24px
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.9) 20%, rgba(255, 255, 255, 0) 40%)
  z-index: 1
  &[data-visible=true]
    transition: all .2s ease
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.9) 20%, rgba(255, 255, 255, 0) 100%)

.icon svg
  transform: rotate(90deg)

#button-open-grid
  padding: 10px 12px 10px 20px
  margin-top: 16px
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2)
  border: none
  & > div
    display: flex
    align-items: center
    gap: 6px
    svg
      transform: rotate(-90deg)
      width: 18px
      height: 20px


.not-found
  margin-top: 10px
  display: flex
  width: 100%
  text-align: center
