@import '@responsive'
@import '@mixins'
@import '@fonts'

.container
  display: flex
  justify-content: center
  width: 100%
  padding-left: 24px
  padding-right: 24px
  +responsive(mobile)
    padding-left: 12px
    padding-right: 12px

.content
  width: 100%
  max-width: 100%
