@import '@responsive'

.sticky
  position: fixed
  left: 50%
  transform: translateX(-50%)
  z-index: 12
  pointer-events: none
  opacity: 0
  bottom: 0
  transition: opacity 0.35s ease-in-out
  &[data-visible=true]
    opacity: 1
    visibility: visible
    pointer-events: all

.desktop-widget
  margin-bottom: 20px
  max-width: 1268px
  width: calc(100vw - 48px)
  min-height: 84px
  border-radius: calc(76px / 2)
  background-color: var(--c-white)
  display: flex
  gap: 20px
  justify-content: space-between
  align-items: center
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.12)
  padding: 8px 8px 8px 31px
  [data-visible-quick-booking=true] &
    padding-right: 16px

.mobile-widget
  flex-shrink: 1
  width: 100%

.left-side
  display: flex
  flex-direction: column
  gap: 4px
  align-items: flex-start
  flex-shrink: 0

.schedule-data
  color: var(--c-green-3)


.button
  display: flex
  justify-content: center
  align-items: center
  padding: 24px 45px
  min-width: 280px
  min-height: 68px
  background-color: #000000
  color: #fff
  border-radius: 70px
  cursor: pointer
  transition: background-color .2s ease
  &:hover
    background-color: #2d2d2d

  [data-visible-quick-booking=true] &
    min-height: 56px
    padding: 18px 28px
    min-width: 169px

.buttons-wrapper
  display: flex
  gap: 6px
  align-items: center
  .promo-badge
    flex-shrink: 0
  .promo-badge[data-size='XL']
    display: flex
    align-items: center
    justify-content: center
    height: 68px
    width: 68px
    border-radius: 50px
    [data-visible-quick-booking=true] &
      height: 56px
      width: 56px
    +responsive(mobile)
      height: 56px
      width: 56px

  +responsive(mobile)
    width: calc(100vw - 24px)
    margin-bottom: 14px

.quick-booking
  margin-right: 2px
  max-height: 56px
