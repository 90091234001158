@import '@responsive'
@import '@mixins'
@import '@fonts'

.container
  display: flex
  justify-content: center
  padding-left: 24px
  padding-right: 24px
  // border: 4px solid cyan
  +responsive(only-desktop-large)
    padding-left: 48px
    padding-right: 48px
  +responsive(mobile)
    padding: 0 12px

.content
  max-width: 1236px
  width: 100%
  +responsive(only-desktop-large)
    max-width: 1394px
  +responsive(tablet)
    max-width: 923px
