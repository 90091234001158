@import "@responsive"

h2.title[data-size='N4']
  text-align: center
  +responsive(mobile)
    font-size: 28px
    line-height: 110%
    letter-spacing: initial
.title
  text-wrap: balance
.subtitle
  color: #333
  opacity: 0.8
  text-align: center
  text-wrap: balance
  +responsive(mobile)
    opacity: 1

.footer-button,
.opportunities-button,
.header-button
  &[data-size='XL']
    padding-top: 22px
    padding-bottom: 22px
    height: initial
    +responsive(mobile)
      padding-top: 18px
      padding-bottom: 18px

.main
  display: flex
  flex-direction: column
  align-items: center
  gap: 130px
  +responsive(mobile)
    gap: 60px

.header
  display: flex
  align-self: flex-start
  justify-content: start
  margin-top: 40px
  margin-left: 102px
  gap: 63px
  +responsive(tablet)
    margin-left: 0
    gap: 32px
  +responsive(mobile)
    text-align: center
    flex-direction: column-reverse
    margin-left: 0
    width: 100%
    padding: 0 12px

  .subtitle
    text-align: left
    +responsive(mobile)
      max-width: 375px
      br
        display: none
      text-align: center

  &-content
    margin-top: 150px
    flex-shrink: 0
    max-width: 610px
    display: flex
    flex-direction: column
    gap: 28px
    align-items: flex-start
    +responsive(mobile)
      margin-top: 0
      gap: 12px
      align-items: center
      text-align: center
      max-width: initial
      width: 100%

  &-media
    +responsive(mobile)
      position: relative

  &-button
    min-width: 218px
    +responsive(mobile)
      margin-top: 6px
      width: 100%

.opportunities
  display: flex
  flex-direction: column
  align-items: center
  gap: 16px
  margin-top: -20px
  +responsive(mobile)
    gap: 8px
    margin-top: initial

  &-description
    color: #333
    opacity: 0.8

  &-grid
    margin-top: 24px
    max-width: 922px
    display: grid
    grid-auto-flow: row
    grid-template-columns: 1fr 1fr
    grid-template-rows: repeat(5, min-content)
    gap: 6px
    +responsive(mobile)
      margin-top: 16px
      gap: 12px
      grid-template-columns: initial
      grid-template-rows: auto

  &-item
    position: relative
    display: flex
    flex-direction: column
    gap: 12px
    padding: 24px 32px
    border-radius: 32px
    background-color: #F5F5F5
    overflow: hidden
    +responsive(mobile)
      border-radius: 24px
      padding: 20px 24px
    &:nth-child(1)
      order: 1
    &:nth-child(2)
      order: 2
      grid-row: 2 / 5
      .opportunities-image
        position: relative
        left: 50%
        transform: translateX(-50%)
        margin-bottom: -250px
        max-width: 100%
        object-fit: contain
        margin-top: 30px
      +responsive(mobile)
        grid-row: initial
        min-height: 330px
        .opportunities-image
          margin-bottom: -245px
          bottom: initial
          margin-top: 10px
    &:nth-child(3)
      order: 3
      grid-row: 1 / 3
      min-height: 308px
      .opportunities-image
        align-self: flex-end
        position: relative
        margin-bottom: -50px
        right: -15px
        bottom: 20px
        max-width: 100%
        object-fit: contain
      +responsive(mobile)
        order: 4
        grid-row: initial
        min-height: 308px
        .opportunities-image
          right: initial
          transform: scale(1.05)
          margin-bottom: -15px
          bottom: 6px
          align-self: center
    &:nth-child(4)
      order: 4
      +responsive(mobile)
        order: 3
    &:nth-child(5)
      order: 5

  &-button
    order: 6
    grid-row: 5 / 6
    grid-column: 1 / 3
    margin-top: 8px
    +responsive(mobile)
      margin-top: initial
      grid-row: initial
      grid-column: initial


.slider-section
  display: flex
  flex-direction: column
  gap: 12px
  +responsive(mobile)
    gap: 8px

.slider-wrapper
  position: relative

.slider
  margin-top: 24px
  max-width: 1127px
  border-radius: 48px
  +responsive(only-tablet)
    width: min(923px, 100vw - 48px) !important
  +responsive(mobile)
    margin-top: 36px
    margin-bottom: 21px
    width: calc(100vw - 24px) !important

.slide
  display: flex
  width: 1127px
  aspect-ratio: 1127 / 500
  +responsive(tablet)
    min-width: 820px
    width: min(923px, 100vw - 48px)
  +responsive(mobile)
    height: 100%
    width: 240px
    min-width: initial
    aspect-ratio: initial
    transition: all .35s ease
    [data-active-slide=true] &
      transform: scale(1.14)

  & > *
    border-radius: 48px
    overflow: hidden
    +responsive(mobile)
      border-radius: 24px

  img
    transition: transform 1.3s ease 3s
    transform: scale(1) translateY(25px)
    [data-active-slide=true] &
      transition-delay: 0s
      transform: scale(1) translateY(0)

.slide-content
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: flex-start
  background-color: #1C1D21
  padding: 40px 40px 32px 40px
  width: 38.24%
  flex-shrink: 0
  gap: 20px
  color: white
  +responsive(tablet)
    padding: 25px 25px 30px 25px
  +responsive(mobile)
    width: 100%
    gap: 35px
    padding: 16px 16px 13px 16px
  svg
    margin-top: 30px
    height: 40px

  .slide-title
    font-size: 40px
    line-height: 110%
    text-wrap: balance
    +responsive(tablet)
      font-size: 32px
    +responsive(mobile)
      font-size: 21px

  .slide-description
    max-width: 336px

.slide-image
  background: #F2F2F2
  position: relative
  flex: 1
  +responsive(mobile)
    display: none
  img
    object-fit: contain

.slider-controls
  position: absolute
  z-index: 1
  top: 0
  left: max(calc(173px - 20vw), -150px)
  right: max(calc(173px - 20vw), -150px)
  bottom: 0
  pointer-events: none
  +responsive(tablet)
    left: min(calc(150px - 20vw), -70px)
    right: min(calc(150px - 20vw), -70px)
  @media screen and (max-width: 1100px)
    display: none
.slider-arrow--prev,
.slider-arrow--next
  width: 52px
  height: 269px
  user-select: none
  pointer-events: all
  position: absolute
  cursor: pointer
  top: 50%
  +responsive(tablet)
    width: 40px
    height: 200px
  &:hover svg
    color: var(--c-afisha)
  svg
    transition: color .4s ease
    width: inherit
    height: inherit
    color: black
.slider-arrow--prev
  left: 0
  transform: translate(0, -50%) rotate(180deg)
.slider-arrow--next
  right: 0
  transform: translate(0, -50%)

.improvement
  display: flex
  flex-direction: column
  align-items: center
  gap: 16px
  +responsive(mobile)
    gap: 8px

  &-list
    margin-top: 24px
    display: flex
    flex-direction: column
    gap: 6px
    max-width: 922px
    +responsive(mobile)
      margin-top: 16px
      gap: 12px
  &-item
    display: flex
    & > *
      background-color: #F5F5F5
      padding: 24px 32px
      border-radius: 32px
      +responsive(mobile)
        padding: 20px
        border-radius: 24px
    .improvement-item-index
      width: 234px
      flex-shrink: 0
      +responsive(mobile)
        width: calc(45px + 10vw)
        padding-left: 16px



.footer
  display: flex
  flex-direction: column
  align-items: center
  max-width: 775px
  text-align: center
  margin: auto
  +responsive(tablet)
    max-width: initial
    width: 100%
  .subtitle
    margin-top: 40px
    text-align: center
    +responsive(mobile)
      margin-top: 24px

  &-wrapper
    width: 100%

  &-icon
    margin-top: 40px
    width: 72px
    height: 72px
    +responsive(mobile)
      margin-top: 24px
      width: 60px
      height: 60px

  &-mail
    display: inline-block
    margin-top: 10px
    transition: color .2s ease
    color: #333333
    opacity: 0.8
    &:hover
      color: var(--c-afisha)

  &-button
    margin-top: 40px
    width: 100%
    +responsive(mobile)
      margin-top: 34px

