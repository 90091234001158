@import '@responsive'
@import '@mixins'
@import '@fonts'

.modal
  +responsive(mobile)
    height: 100vh

.modal-content
  width: 100%
  display: grid
  grid-template-columns: min-content 1fr
  gap: 12px 16px
  grid-template-rows: min-content auto

.review
  width: 100%
  display: grid
  grid-template-columns: min-content 1fr
  grid-template-rows: auto
  gap: 14px 24px
  +responsive(mobile)
    padding: 16px
    border-radius: 24px
    width: 320px
    gap: 12px 16px
    height: 336px
    grid-template-rows: min-content auto
    background: var(--grey-95-bg, #F2F2F2)
  [data-mode='row'] &
    background: transparent
    width: 100%
    border-radius: 0
    height: initial
    padding: 0 0 24px 0
    border-bottom: 1px solid rgba(150, 150, 150, 0.2)
    &:last-child
      border-bottom: none
      padding-bottom: 0
