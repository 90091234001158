@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper,
.wrapper-user-path
  display: flex
  flex-direction: column
  gap: 60px
  +responsive(mobile)
    gap: 40px

.list
  margin-top: 36px
  display: flex
  flex-direction: column
  gap: 60px
  +responsive(mobile)
    margin-top: 0px
    display: flex
    flex-direction: initial
    gap: initial
    margin-bottom: initial

.pagination
  margin-top: 60px
