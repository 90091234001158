@import @responsive
$c-grey-contour: #E9E9E9
$w-padding-layout--mob: 16px
$h-header--mob: 92px
$rad-content--desk: 12px
$booking-grid-w: 597px

.opened
  +responsive(mobile)
    width: 100%
    :global(.react-datepicker)
      padding: 0

.button
  height: 52px
  width: auto
  border-radius: calc(52px / 2)
  background: none
  cursor: pointer
  border: none
  font-size: 15px
  line-height: 20px
  padding: 0 15px
  margin: 0
  box-shadow: inset 0 0 0 1px $c-grey-contour
  color: #000000
  transition: all 0.2s ease
  &:hover
    box-shadow: inset 0 0 0 1px #8C8C8C
  &:active
    color: #FF3118
  &--selected,
  &--red
    box-shadow: inset 0 0 0 2px #FF3118
    &:hover
      box-shadow: inset 0 0 0 2px #FF3118
    svg
      color: red
  &--disabled
    pointer-events: none
    color: #D5D5D5
    cursor: not-allowed
    user-select: none

.button-text
  display: flex
  align-items: center
  gap: 9px
  svg
    width: 23px
    height: 23px


