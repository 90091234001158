@import '@responsive'
@import '@mixins'
@import '@fonts'

.stat-source-descr
  color: #B3B3B3
  display: flex
  justify-content: flex-start
  align-content: flex-start
  gap: 8px

.stat-source-descr-text
// empty

.stat-source-descr-logo
  display: block
.stat-source-descr-logo
  width: 91px
  height: 16px
