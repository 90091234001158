
.button
  display: flex
  justify-content: center
  align-items: center
  min-height: 48px
  padding: 4px 25px
  box-sizing: border-box
  user-select: none
  -moz-user-select: none
  -webkit-user-select: none

  background: #FFFFFF
  border: 1px solid #E1E1E1
  border-radius: 24px
  cursor: pointer

  color: black
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0)
  transition: box-shadow .2s ease, border-color .2s ease
  font-size: 15px

  &[data-checked=true]
    border-color: #FF3118
    border-width: 2px
    padding: 4px 24px
    &[data-disabled=false]:hover
      border-color: rgba(255, 49, 24, 0.7)

  &[data-disabled=true]
    cursor: default
    opacity: 0.5

  &[data-checked=false][data-disabled=false]:focus,
  &[data-checked=false][data-disabled=false]:hover
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.15)

.error
  border-color: #FF3118
  &[data-disabled=false]:hover
    box-shadow: inset 0 0 8px rgba(255, 49, 24, 0.4)
  &[data-disabled=false]:focus
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0)
