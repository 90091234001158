@import '@responsive'

.button
  cursor: pointer
  display: inline-flex
  justify-content: center
  align-items: center
  transition: all .2s ease

  // default
  padding: 18px 28px
  font-size: 15px
  border-radius: 30px
  border: 1px solid #000

  &:focus
    outline: 0

  &:disabled
    cursor: not-allowed
    user-select: none
    opacity: 0.4

  .icon
    flex-shrink: 0

  &[data-size='XS']
    height: 28px
    width: 28px
    padding: 5px
    .icon
      height: 18px
      width: 18px

  &[data-size='S']
    height: 32px
    width: 32px
    padding: 6px
    .icon
      height: 20px
      width: 20px

  &[data-size='M']
    height: 40px
    width: 40px
    padding: 8px
    .icon
      height: 24px
      width: 24px

  &[data-size='L']
    height: 48px
    width: 48px
    padding: 10px
    .icon
      height: 28px
      width: 28px

  &[data-size='XL']
    height: 56px
    width: 56px
    padding: 12px
    .icon
      height: 32px
      width: 32px

  &[data-mode='white-border']
    background-color: #fff
    border-color: var(--c-gray-88)
    box-shadow: inset 0 0 0 transparent
    &:hover,
    &.active
      box-shadow: var(--e-inner-button)
    &:disabled
      border-color: var(--c-gray-88)
      box-shadow: inset 0 0 0 transparent

  &[data-mode='white-shadow']
    background-color: #fff
    border-color: transparent
    box-shadow: var(--e-inner-button)
    &:hover,
    &.active
      background-color: var(--c-gray-95)
    &:disabled
      background: #fff

  &[data-mode='transparent']
    background-color: rgba(179, 179, 179, 0.4)
    border-color: transparent
    box-shadow: initial
    backdrop-filter: blur(4px)
    -webkit-backdrop-filter: blur(4px) // TODO: fix autoprefixer
    transform: translate3d(0, 0, 0) // must use translate3d for blur optimization
    &:hover,
    &.active
      background-color: rgba(179, 179, 179, 0.65)
    &:disabled
      background: rgba(179, 179, 179, 0.2)

  &[data-mode='white']
    background-color: #fff
    border-color: transparent
    box-shadow: initial
    &:hover,
    &.active
      background-color: var(--c-gray-92)
    &:disabled
      background-color: #fff

  &[data-mode='black']
    background-color: #000
    border-color: transparent
    box-shadow: initial
    &:hover,
    &.active
      background-color: var(--c-gray-20)
    &:disabled
      opacity: 0.1

  &.skeleton
    padding: 0
    border: none

