@import '@responsive'
@import '@mixins'
@import '@fonts'

.popup
  +responsive(from-tablet)
    display: grid
    grid-template-rows: min-content min-content 1fr
  +responsive(mobile)
    height: calc(100vh - 45px)
    & [data-content-inner]
      height: 100%
      display: grid
      grid-template-rows: min-content 1fr

.wrapper-lists
  display: flex
  flex-direction: column
  overflow: hidden

.list-checked-wrapper
  position: relative
  overflow: hidden
  padding: 1px 0

.list-checked
  display: flex
  flex-wrap: wrap
  overflow: auto
  gap: 12px 24px
  padding: 20px 0
  max-height: 165px
  &::-webkit-scrollbar
    width: 0
  &-shadow
    position: absolute
    height: 0
    left: 0
    right: 0
    pointer-events: none
    z-index: 1
    &:after
      display: block
      content: ""
      position: absolute
      left: 0
      right: 0
      height: 20px
    &--top
      &:after
        top: -1px
        background: linear-gradient(180deg, #FFFFFF 0%, transparent 100%)
    &--bottom
      bottom: -1px
      &:after
        bottom: 0
        background: linear-gradient(0deg, #FFFFFF 0%, transparent 100%)

.list-wrapper
  position: relative
  margin-bottom: 86px
  overflow: hidden
  padding: 1px 0
  flex: 1
  max-height: 295px
  +responsive(mobile)
    max-height: initial
    margin-bottom: 55px

.list
  height: 100%
  display: flex
  overflow: auto
  flex-direction: column
  gap: 12px
  padding: 20px 0
  margin-bottom: 70px
  &::-webkit-scrollbar
    width: 0
  +responsive(mobile)
    gap: 0
    &::-webkit-scrollbar
      width: initial

  &-shadow
    position: absolute
    height: 0
    left: 0
    right: 0
    pointer-events: none
    z-index: 1
    &:after
      display: block
      content: ""
      position: absolute
      left: 0
      right: 0
      height: 25px
    &--top
      &:after
        top: -1px
        background: linear-gradient(180deg, #FFFFFF 0%, transparent 100%)
    &--bottom
      bottom: -1px
      &:after
        bottom: 0
        background: linear-gradient(0deg, #FFFFFF 0%, transparent 100%)

.tag-mobile
  display: block
  cursor: pointer
  padding: 16px 0
  border-bottom: 1px solid #F2F2F2
  &:first-child
    padding-top: 0
  &:last-child
    padding-bottom: 0
    border-bottom: none

.divider
  height: 1px
  background-color: #E1E1E1
