@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  gap: 3px
  color: var(--c-gray-59)

.type-url
  cursor: pointer
  transition: color .2s ease
  &:hover
    color: var(--c-afisha)

