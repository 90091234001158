@import "@responsive"
@import "@mixins"
@import "@fonts"

.schedule
  max-width: 501px

.slot
  height: 56px

.dots
  width: 56px
  height: 56px
