@import '@fonts'
@import '@responsive'

.cover-main
  padding-top: 50px
  min-width: 350px
  display: flex
  justify-content: flex-end
  flex-direction: column
  gap: 12px
  color: #fff
  +responsive(mobile)
    padding-top: 16px
    color: #000
    gap: 6px

.date,
.cover-additional
  opacity: 0.7
  +responsive(mobile)
    color: #969696
    opacity: 1

.cover-additional
  & > span:nth-child(2):before
    content: ''
    display: inline-block
    vertical-align: middle
    margin: 0 7px
    width: 3px
    height: 3px
    border-radius: 50%
    background-color: #fff
    +responsive(mobile)
      background-color: #969696

.wrapper
  min-height: 100vh
  display: flex
  flex-direction: column
  gap: 60px
  +responsive(mobile)
    gap: 32px

.plug
  height: 100vh

.author
  margin-bottom: 36px
  +responsive(only-desktop-large)
    margin-left: 88px

.content
  margin-bottom: 60px
  +responsive(only-desktop-large)
    margin-left: 88px
  .content-announce
    margin-bottom: 36px
    +responsive(mobile)
      margin-bottom: 20px

.restaurants
  &-title
    margin-bottom: 24px
  &-list
    display: flex
    gap: 24px 16px
    flex-wrap: wrap

.title
  -webkit-hyphens: auto
  -moz-hyphens: auto
  -ms-hyphens: auto
  hyphens: auto

.favorite
  position: absolute
  right: 24px
  bottom: 24px
  +responsive(mobile)
    bottom: 32px
    right: 12px
  svg
    stroke: white
