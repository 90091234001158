@import '@fonts'
@import '@responsive'

.page-dev-button
  z-index: 10
  position: fixed
  bottom: 1em
  right: 1em
  button
    &:hover
      color: salmon

// *** page-content ***

.page-content-wrap
  max-width: 1080px
  min-width: 880px // TODO: temp fix

.page-content-section
  margin-bottom: 48px
  &.page-content-section--fixed
    position: fixed
    left: auto
    top: auto
    bottom: 0.618em
    right: 0.618em
    padding: 0.618em
    border-radius: calc(0.618em * 2)
    border: 0.618em solid #FFFFFF
    background: #F2F2F2
  &.page-content-section--centered
    position: fixed
    top: 50%
    left: 50%
    transform: translate3d(-50%, -50%, 0)
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 40px

.page-content-two-cols-with-right-block
  display: flex
  justify-content: space-between
  gap: 6px
  margin-bottom: 6px
  & > *
    width: 100%
  & > *:last-child
    max-width: 307px

.page-content-white-block
  background-color: #FFFFFF
  padding: 16px
  border-radius: 24px

// *** own-book-rest-info ***

$logoSize: 78px
$btnSize: 48px
$padding: 16px
.own-book-rest-info
  position: relative
  padding-left: calc($logoSize + $padding)
  padding-right: calc($btnSize + $padding)
  min-height: $logoSize
.own-book-rest-info-logo
  width: $logoSize
  height: $logoSize
  border-radius: 50%
  background: #F2F2F2
  position: absolute
  left: 0
  top: 50%
  transform: translate3d(0, -50%, 0)
.own-book-rest-info-text-wrap
  // empty
.own-book-rest-info-name
  margin-bottom: 6px
.own-book-rest-info-address
  color: #969696
.own-book-rest-info-expand-button
  position: absolute
  right: 0
  top: 0
  width: $btnSize
  height: $btnSize

// *** own-book-client-info ***

$ritghtButtonSpace: calc(187px + 4px)
.own-book-client-info
  position: relative
  padding-right: calc($ritghtButtonSpace + 16px)
.own-book-client-info-description-wrap
  // empty
.own-book-client-info-title
  margin-bottom: 12px
.own-book-client-info-text
  color: #969696
  max-width: 634px
.own-book-client-info-create-button
  position: absolute
  width: $ritghtButtonSpace
  min-width: $ritghtButtonSpace
  white-space: nowrap
  top: 50%
  right: 16px
  z-index: 1
  transform: translate3d(0, -50%, 0)

// *** own-book-list ***

.own-book-list-title
  margin-bottom: 24px

.own-book-list-filter
  margin-bottom: calc(38px - 13px)
  display: flex
  justify-content: flex-start
  align-items: flex-start
  gap: 6px
.own-book-list-filter-elem
  width: 300px
.own-book-list-filter-label
  width: 100%
  display: block
  padding-bottom: 8px
  pointer-events: none
.own-book-list-filter-control
  padding: 0 16px
  min-height: 48px
  height: 48px
  line-height: calc(48px - 2px)
  width: 100%
  border-radius: calc(48px / 2)
  appearance: none
  background-color: #fff
  border-color: var(--c-gray-88)
  box-shadow: inset 0 0 0 transparent !important
  color: #000000
  text-align: left
  color: #969696
  appearance: none
  -webkit-appearance: none
  -moz-appearance: none
  -ms-progress-appearance: none
  pointer-events: all
  cursor: pointer
  transition: all 0.2s ease
  &:hover,
  &:focus
    box-shadow: var(--e-inner-button) !important
.own-book-list-filter-button-icon
  // empty

$tableBorderSize: 3px
$tablePadding: 13px 16px
.own-book-list-table-wrap
  // empty
.own-book-list-table
  width: 100%
  margin-bottom: 20px
.own-book-list-table-header
  // empty
.own-book-list-table-header-cell
  padding: $tablePadding
  color: #666666
.own-book-list-table-item
  // empty
.own-book-list-table-item-cell
  padding: $tablePadding
  position: relative
  &::after
    content: ''
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: calc(100% - $tableBorderSize)
    background-color: #FFFFFF
    z-index: 1
    opacity: 1
  &:first-child::after
    border-top-left-radius: 16px
    border-bottom-left-radius: 16px
  &:last-child::after
    border-top-right-radius: 16px
    border-bottom-right-radius: 16px
.own-book-list-table-item-cell-content
  display: block
  position: relative
  transform: translate3d(0, calc($tableBorderSize / 2), 0)
  z-index: 2
.own-book-list-table-footer
  display: flex
  justify-content: space-between
  gap: 16px
.own-book-list-table-footer-button
  display: block
.own-book-list-table-footer-text
  display: block
.own-book-list-table-footer-icon
  width: 24px
  height: 24px
  display: block
  margin-right: 10px
  &.own-book-list-table-footer-icon--right
    rotate: 180deg
    margin-right: 0
    margin-left: 10px

// *** booking-is-not-enabled ***

.booking-is-not-enabled
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  gap: 16px
  max-width: 576px
  min-width: 576px
  width: 576px
.booking-is-not-enabled-title
  white-space: nowrap
.booking-is-not-enabled-description
  color: #8c8c8c
  text-align: center
  margin-bottom: 20px
.booking-is-not-enabled-mail-link
  text-decoration: underline
  color: inherit

// end
