@import '@fonts'
@import '@responsive'
@import '@ownerGlobal'

.page-content-white-block
  @include page-content-white-block
  &.page-content-white-block--no-padding
    @include page-content-white-block--no-padding

// *** restaurant tab page ***

// * tabs-preview *

.tabs-preview
  display: flex
  justify-content: space-between
  gap: 30px
.tabs-preview-list
  width: 100%
  min-width: 190px
  max-width: 236px
  display: flex
  justify-content: flex-start
  align-items: flex-start
  flex-direction: column
  gap: 12px
.tabs-preview-item-button
  width: 100%
  height: 68px
  min-height: 68px
  border: 1px solid salmon
  border-radius: 16px !important
  padding: 0 12px 0 12px !important
  display: block
  text-align: left
  box-shadow: inset 0 0 0 1px #E1E1E1 !important
  transition: box-shadow 0.15s ease
  &[data-selected=true]
    box-shadow: inset 0 0 0 2px #FF3118 !important
  &:hover
    background: transparent !important
.tabs-preview-item-button-text
  display: block
  font-size: 18px
  line-height: 22px
  margin-bottom: 4px
.tabs-preview-item-button-descr
  display: block
  font-size: 13px
  line-height: 18px
  color: #969696

.tabs-preview-rest-cards
  display: flex
  gap: 16px
  position: relative
  min-height: 305px
  max-width: 460px
  width: 100%
  overflow: hidden

  &:after
    display: block
    content: ''
    position: absolute
    right: 0
    top: 0
    bottom: 0
    width: 116px
    height: 100%
    background: linear-gradient(270deg, white, transparent)
    z-index: 1
    transition: width .4s ease
    +responsive(tablet)
      width: 70px
  & > *
    flex-shrink: 0
.tabs-preview-rest-card


// * restaurant-info *

.restaurant-info
  // empty
.page-content-section
  width: 100%
  margin-bottom: 24px
.restaurant-info-page-header-wrap
  position: relative
.restaurant-info-content-margin
  margin-top: 12px

// * restaurant-info-link *

.restaurant-info-link-wrap
  position: relative
.restaurant-info-link-title
  margin-bottom: 8px
.restaurant-info-link-text-row
  display: flex
  justify-content: flex-start
  align-items: flex-start
.restaurant-info-link-text
  margin-right: 13px
  color: var(--c-black)
  height: 23px
.restaurant-info-link-copy-wrap
  display: flex
  justify-content: flex-start
  align-items: center
  gap: 12px
  color: #969696
.restaurant-info-link-copy-state
  pointer-events: none
  user-select: none
  -webkit-user-select: none
  overflow: hidden
  opacity: 0
  transition: opacity 1.5s ease
  &[data-is-visible=true]
    transition: opacity 0.0s ease
    opacity: 1
.restaurant-info-link-button
  max-height: 23px
  height: 23px
  width: 23px
  &:hover
    background: none !important
    box-shadow: none !important
.restaurant-info-link-icon
  width: 21px
  height: 21px
  display: block
  pointer-events: none
.restaurant-info-link-aciton-link
  position: absolute
  top: 50%
  right: 4px
  transform: translate3d(0, -50%, 0)
  color: #FF3118
  transition: color 0.15s ease

// end
