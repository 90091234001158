@import '@fonts'

.textarea
  display: block
  width: 100%
  height: 48px
  padding: 14px 16px
  box-sizing: border-box

  background: #FFFFFF
  border: 1px solid #E1E1E1
  border-radius: 24px
  resize: none

  color: black
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0)
  transition: box-shadow .2s ease, border-color .2s ease
  font-size: 15px
  line-height: 20px
  @include font-regular

  &::placeholder
    color: #969696
  &:focus:not(:disabled),
  &:hover:not(:disabled)
    outline: none
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.15)
