@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  height: calc(50% - 128px)

.sticky
  position: sticky
  top: 20px

