@import '@responsive'
@import '@mixins'
@import '@fonts'

$buttonSize: 24px
$calendarDeselectGap: 8px

$calendarHeightDesktop: 70px
$calendarHeightMobile: 52px

$calendarListGapDesktop: 6px
$calendarListGapMobile: 3px

.calendar-item
  display: flex
  &[disabled=true],
  &[disabled]
    .calendar-item-button
      opacity: 0.4
      pointer-events: none

.calendar-month-label
  position: relative
  display: block
  color: #969696
  text-transform: capitalize
  font-size: 13px
  line-height: 18px
  transform: rotate(180deg)
  writing-mode: vertical-rl
  width: 24px
  height: $calendarHeightDesktop
  padding: 12px 1px 0 0
  margin-right: $calendarListGapDesktop
  +responsive(mobile)
    height: $calendarHeightMobile
    padding: 11px 0px 0 0
    margin-right: $calendarListGapMobile
  &::after
    content: ''
    position: absolute
    display: block
    background-color: rgba(0, 0, 0, 0.12)
    width: 1px
    height: 52px
    left: 3px
    top: 9px
    +responsive(mobile)
      height: 36px
      left: 0px
      top: 8px

.calendar-item-button
  text-align: left
  width: 60px
  height: $calendarHeightDesktop
  padding: 8px 14px
  +responsive(mobile)
    text-align: center
    width: 39px
    height: $calendarHeightMobile
    padding: 6px 6px
  border-radius: 16px
  box-sizing: border-box
  transition: all 0.2s
  background: #ffffff
  cursor: pointer
  &:hover
    box-shadow: inset 0 0 8px var(--c-black-a15)
  &:active
    background: rgb(242, 242, 242)
  &[data-selected=true]
    box-shadow: inset 0 0 8px var(--c-black-a15)
    background: rgb(242, 242, 242)

.calendar-item-day-of-week
  font-size: 13px
  line-height: 18px
  margin-bottom: 4px
  +responsive(mobile)
    margin-bottom: 0px
  color: #969696
  &.calendar-item-day-of-week--holiday
    color: #FF3118

.calendar-item-day-of-month
  font-size: 24px
  line-height: 28px
  letter-spacing: 0.04em
  +responsive(mobile)
    font-size: 15px
    line-height: 20px
    font-weight: 500

.calendar-deselect
  color: transparent
  background-color: #000000
  width: $buttonSize
  height: $buttonSize
  border-radius: calc($buttonSize / 2)
  position: absolute
  z-index: 1
  right: calc($calendarDeselectGap * -1)
  top: calc($calendarDeselectGap * -1)
  display: block
  transition: background-color 0.2s
  svg
    color: white
  &:hover
    background-color: #3a3a3a
