@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  flex-direction: column
  gap: 36px

.main-description
  color: var(--c-gray-59)

.photo-description
  margin-top: 10px
  color: var(--c-gray-59)
