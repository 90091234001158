@import '@fonts'

.body
  background-color: #F2F2F2

.body-white
  z-index: 1
  position: relative
  background-color: #FFFFFF

.wrapper
  height: 100%
  position: relative
  transition: all 0.4s ease
  opacity: 1
  &[data-is-loading=true]
    opacity: 0.5
    pointer-events: none

.spinner
  position: fixed
  z-index: 10
  transform: scale(2)

.container
  &[data-is-owner=true]
    max-width: initial




