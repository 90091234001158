@import '@fonts'
@import '@responsive'

.header
  margin: 0
  text-wrap: balance

  @include font-a-headline

  &[data-size='h1']
    font-size: 38px
    font-weight: 500
    line-height: 40px
    letter-spacing: -0.38px
    &[data-super='true']
      font-size: 60px
      font-weight: 500
      line-height: 60px
      letter-spacing: -0.38px

  &[data-size='h2']
    font-size: 32px
    font-weight: 500
    line-height: 36px
    letter-spacing: -0.32px

  &[data-size='h3']
    font-size: 24px
    font-weight: 500
    line-height: 27px
    letter-spacing: -0.24px

  &[data-size='h4']
    font-size: 22px
    font-weight: 500
    line-height: 26px
    letter-spacing: -0.22px

  &[data-size='h5']
    font-size: 20px
    font-weight: 500
    line-height: 22px
    letter-spacing: -0.2px


.skeleton
  width: 60%
  &[data-size='h1']
    height: 40px

  &[data-super='true']
    height: 60px

  &[data-size='h2']
    height: 36px

  &[data-size='h3']
    height: 27px

  &[data-size='h4']
    height: 26px

  &[data-size='h5']
    height: 22px

  .skeleton-rect
    width: 100%
    height: 100%
