@import '@responsive'
@import '@mixins'
@import '@fonts'

.container-content
  margin: max(40px, calc(50vh - 250px)) auto 0 auto
  max-width: 450px

.content
  margin-top: 20px
  display: flex
  gap: 12px
  flex-direction: column
  align-items: center
  text-align: center
  justify-content: center
  .text
    text-align: center
    max-width: 380px
