@import "@responsive"

.user
  position: relative
  z-index: 10

.modal
  display: flex
  flex-direction: column
  gap: 20px
  +responsive(mobile)
    gap: 4px

.dropdown
  display: flex
  flex-direction: column
  gap: 12px
  position: absolute
  right: -12px
  top: -12px
  padding: 20px 10px 12px 10px
  min-width: 280px
  background-color: #fff
  border-radius: 32px
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.20)

  &-header
    display: flex
    flex-direction: column
    padding: 0 10px
    gap: 4px
    margin-bottom: 4px
    +responsive(mobile)
      display: grid
      gap: 2px 16px
      padding: 0
      margin-bottom: 8px
      grid-template-rows: min-content min-content
      grid-template-columns: min-content min-content

  &-close
    position: absolute
    top: 12px
    right: 12px

  &-avatar
    margin-bottom: 8px
    +responsive(mobile)
      grid-row: 1 / 3
      margin-bottom: 0

  &-main
    display: flex
    flex-direction: column

  &-link
    +responsive(mobile)
      border-bottom: 1px solid #E1E1E1
      &:last-child
        border-bottom: none
    a
      display: block
      padding: 12px 10px
      border-radius: 20px
      text-decoration: none
      color: #000
      cursor: pointer
      &:hover
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15) inset
      +responsive(mobile)
        border-radius: 0
        padding: 16px 0
        &:hover
          box-shadow: none

  &-add-event > div
    display: flex
    gap: 7px
    svg
      width: 24px
      height: 24px

.fullname
  +responsive(mobile)
    align-self: flex-end

.login,
.avatar
  transition: opacity .3s ease
  opacity: 1
  pointer-events: initial
  &[data-loading=true]
    pointer-events: none
    opacity: 0.7
