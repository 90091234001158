@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  flex-direction: column
  gap: 24px
  padding: 24px
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10) inset
  border-radius: 16px
  +responsive(mobile)
    padding: 0
    box-shadow: initial
    border-radius: initial
    gap: 20px

.cards
  display: flex
  flex-wrap: wrap
  gap: 24px 20px
  justify-content: space-between
  +responsive(mobile)
    gap: 20px
    flex-direction: column
    flex-wrap: nowrap

.card
  width: calc(50% - 10px)
  +responsive(mobile)
    width: 100%
    max-width: 500px
