@import '@fonts'
@import '@responsive'

$itemsGap: 4px

.container
  font-size: 15px
  line-height: 20px
  width: 247px
  min-width: 212px
  max-width: 247px
.list-wrap
  width: auto
  height: auto
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: nowrap
  flex-direction: column
  gap: $itemsGap
  margin-bottom: 16px

.list-item
  border-radius: calc(48px / 2)
  height: 48px
  width: 100%
  display: flex
  align-items: center
  justify-content: flex-start
  flex-wrap: nowrap
  padding-right: 12px
  gap: 2px
  &[data-is-first=true]
    margin-bottom: calc(16px - $itemsGap)
  &[data-has-side-button=true]
    padding-right: 0

.item-action-button
  border-radius: inherit
  position: relative
  font-size: inherit
  line-height: inherit
  display: flex
  align-items: center
  justify-content: flex-start
  flex-wrap: nowrap
  gap: 10px
  height: 100%
  width: 100%
  padding: 0 16px
  background-color: #ffffff
  transition: all 0.3s ease-in-out

  svg
    width: 20px
    min-width: 20px
    max-width: 20px
  span
    height: 20px
    text-align: left
    overflow: hidden

  &[data-with-status=true]
    padding-right: 92px

  &[data-selected=true]
    box-shadow: 0px 0px 8px 0px #00000026 inset
    background-color: transparent
    pointer-events: none
    transition: all 0s ease-in-out

  &:hover
    box-shadow: 0px 0px 8px 0px #00000026 inset
    background-color: #ffffff
    transition: all 0s ease-in-out
    &[data-selected=true]
      box-shadow: 0px 0px 8px 0px #00000026 inset
      background-color: transparent
      pointer-events: none
      transition: all 0s ease-in-out

.icon
  display: block
  width: 20px
  height: 20px
  pointer-events: none
.text
  // empty
.text-status
  color: #FF3118
  position: absolute
  right: 16px
  top: 50%
  transform: translateY(-50%)
  pointer-events: none

.item-button-add-news
  width: 48px
  min-width: 48px
  height: 48px
  min-height: 48px
  display: block
  border-radius: 50%
  position: relative
  transition: all 0.3s ease-in-out
  background-color: #ffffff
  &:hover
    background-color: mix(#F2F2F2, #FFFFFF, 38%)
    transition: all 0s ease-in-out

.item-button-add-news-icon
  height: 24px
  width: 24px
  display: block
  position: absolute
  right: 50%
  left: 50%
  transform: translate(-50%, -50%)
  pointer-events: none

.description
  color: #969696
  margin-bottom: 16px

.description-text
  display: block

.description-mail-link
  color: inherit
  text-decoration: underline
  transition: all 0.2s ease
  &:hover
    color: black
