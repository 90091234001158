@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 10px
  min-height: 148px
  border-radius: 24px
  background-color: transparent
  border: 1px solid rgba(225, 225, 225, 1)
  padding: 16px
  transition: all .2s ease
  &[data-image=false]
    cursor: pointer
    &:hover .title
      color: var(--c-afisha)

  &[data-loading=true]
    background-color: rgba(242, 242, 242, 1)

  &[data-image=true]
    background-color: rgba(242, 242, 242, 1)
    border-color: rgba(225, 225, 225, 1)

.img-wrapper
  overflow: hidden
  position: relative

.img-wrapper-img
  border-radius: 12px
  display: block
  width: 100%
  height: 100%
  max-width: 198px
  min-height: 100px
  min-width: 100px
  background-color: #e7e7e7

.delete
  position: absolute
  top: 8px
  right: 8px

.title
  position: relative
  display: flex
  align-items: center
  gap: 8px
  transition: color .3s ease

  svg
    width: 16px
    height: 16px

.description
  color: rgba(150, 150, 150, 1)
  text-align: center

.errors
  display: flex
  flex-direction: column

