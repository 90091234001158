@import '@responsive'
@import '@mixins'
@import '@fonts'

.close
  display: flex
  justify-content: center
  align-items: center
  width: 50px
  height: 40px
  position: absolute
  right: -45px
  top: 16px
  background-color: #fff
  border-radius: 0 28px 28px 0
  z-index: -1
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2)
  transition: all .2s ease
  svg
    width: 20px
    height: 20px
  &:hover
    background-color: var(--c-gray-95)
  &:disabled
    box-shadow: inset 0 0 0 transparent
