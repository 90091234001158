@import '@responsive'

.avatar
  display: block
  position: relative

  object-fit: contain
  object-position: center
  &[data-size=S]
    height: 40px
    width: 40px

  &[data-size=M]
    height: 48px
    width: 48px

  &[data-size=L]
    height: 56px
    width: 56px
    .afisha
      right: 0
      top: 0
      width: 20px
      height: 20px

  &[data-size=XL]
    height: 68px
    width: 68px
    .afisha
      right: -4px
      top: -4px
      width: 24px
      height: 24px
    .first-letter
      font-size: 24px
      line-height: 27px
      letter-spacing: -0.24px

.img,
.img-default
  display: block
  width: 100%
  height: 100%
  border-radius: 50%
  overflow: hidden
  background-color: #e5e5e5

.img-default
  display: flex
  justify-content: center
  align-items: center
  background-size: cover
  background-repeat: no-repeat


.afisha
  position: absolute
