@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  flex-direction: column
  margin-bottom: 12px
  gap: 12px

.item
  display: flex
  transition: opacity .2s ease
  &[data-disabled=true]
    opacity: 0.45

.side,
.main
  padding: 12px
  border-radius: 16px
  background-color: #E0EEFF

.side
  display: flex
  justify-content: center
  align-items: center
  width: 84px
  span
    @include font-a-headline
    font-size: 40px
    font-weight: 500
    line-height: 50px
    letter-spacing: -0.8px

  &[data-type='discount']
    span
      font-size: 16px

.main
  padding: 12px 16px
  display: flex
  align-items: center
  flex: 1
