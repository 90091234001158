@import '@responsive'
@import '@mixins'
@import '@fonts'

@keyframes spin
  0%
    transform: rotate(0deg) scale(1)
  50%
    transform: rotate(180deg) scale(1.5)
  100%
    transform: rotate(360deg) scale(1)

.wrapper
  position: relative
  height: 56px
  .show-more-button
    position: absolute
    left: 0
    right: 0
    top: 0
    opacity: 0
    height: 100%
    min-height: 100%
    pointer-events: none
    transition: all .2s ease
    &[data-visible=true]
      opacity: 1
      pointer-events: initial

.show-more-button
  width: 100%
  min-height: 100%
  height: 100%
