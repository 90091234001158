@import '@responsive'
@import '@mixins'
@import '@fonts'

.visually-hidden
  position: fixed !important
  padding: 0
  margin: -1px
  overflow: hidden
  clip: rect(0, 0, 0, 0)
  white-space: nowrap
  border: 0

.advert-wrap
  position: relative
  overflow: hidden

.advert
  display: flex
  align-items: center
  width: 100%
  position: relative

  > *
    position: relative
    z-index: 2

  &::before
    content: ''
    display: block
    z-index: 1
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0

  &.advert--is-dev
    &::before
      background-color: rgba(255, 79, 0, 0.23)
      box-shadow: inset 0 0 0 4px rgba(255, 81, 0, 0.62), 0 0 16px 0 rgb(255, 81, 0, 0.62)

.advert-text
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  font-size: 50px
  font-family: monospace
  font-weight: 600
  color: rgb(255, 81, 0, 0.62)
  line-height: 1em
  word-wrap: break-word
