@import '@responsive'
@import '@mixins'
@import '@fonts'

.inner
  position: relative

.slider
  border-radius: 0

.slider-controls
  position: absolute
  left: 0
  right: 0
  top: 0
  bottom: 0
  pointer-events: none
  z-index: 2
  @media (width <= 970px)
    display: none

.slider-arrow--prev,
.slider-arrow--next
  position: absolute
  top: 0
  bottom: 0
  pointer-events: none

  &:hover
    .arrow-button
      opacity: 1

  &:disabled
    pointer-events: none
    .arrow-button
      opacity: 0
      transform: translateX(-100%)
      transition: opacity .3s ease, transform 0s ease 0.7s
    .shadow
      opacity: 0

  .arrow-button
    position: absolute
    top: 0
    height: 100%
    opacity: 0
    width: 45px
    pointer-events: all
    transform: translateX(0)
    transition: opacity .3s ease, transform 0s ease 0s
    z-index: 2

  .shadow
    position: absolute
    top: 0
    left: 0
    width: 80px
    height: 100%
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))
    z-index: 1
    opacity: 1
    transition: opacity 1s ease

    &[data-is-animation=true]
      opacity: 1
      transition: opacity 0.2s cubic-bezier(0.05, 0.38, 0.58, 1)

  svg
    width: 32px

.slider-arrow--prev
  left: 0
  .arrow-button
    svg
      position: absolute
      top: 49px
      left: 0

.slider-arrow--next
  right: 0
  rotate: 180deg
  .arrow-button
    svg
      position: absolute
      bottom: 49px
      left: 0


.wrapper-skeleton
  display: flex
  gap: 16px
  max-height: 164px
  flex-wrap: wrap
  overflow: hidden
  +responsive(mobile)
    max-height: 128px
    gap: 8px
    flex-wrap: nowrap
    margin-left: -12px
    margin-right: -12px
    padding-left: 12px
    padding-right: 12px
  & > *
    flex-shrink: 0
