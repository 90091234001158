@import '@responsive'
@import '@mixins'
@import '@fonts'

.stat-wrap
  display: flex
  gap: 6px
  justify-content: flex-start
  align-items: flex-start
  flex-direction: column
  &:last-child
    margin-bottom: 0
.stat-title
// empty
.stat-value-wrap
  display: flex
  justify-content: flex-start
  align-items: flex-end
  gap: 8px
.stat-value-number
// empty
.stat-value-percent
  &[data-is-up=true]
    color: #31BE00
  &[data-is-up=false]
    color: #969696
