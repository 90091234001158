.button[data-mode='checkbox']
  cursor: pointer
  display: flex
  gap: 10px
  align-items: flex-start

  &[data-without-label=true]
    display: block
    gap: 0

  .checkmark
    width: 24px
    height: 24px
    border-radius: 6px
    background-color: transparent
    border: 1px solid #E1E1E1
    transition: all .2s ease
    svg
      color: transparent

  .title[data-size='M']
    padding: 2px 0

  &[data-checked=true]
    .checkmark
      background-color: var(--c-afisha)
      border: 1px solid transparent
      svg
        color: #fff

  &[data-disabled=true]
    opacity: 0.5


.button[data-mode='button']
  display: flex
  justify-content: center
  align-items: center
  height: 48px
  padding: 5px 20px
  white-space: nowrap
  box-sizing: border-box

  background: #FFFFFF
  border: 1px solid #E1E1E1
  border-radius: 28px
  cursor: pointer
  color: black
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0)
  transition: box-shadow .2s ease, border-color .2s ease
  font-size: 15px

  &[data-checked=true]
    border-color: #FF3118
    border-width: 2px
    padding: 5px 19px
    &[data-disabled=false]:hover
      border-color: rgba(255, 49, 24, 0.7)

  &[data-disabled=true]
    cursor: default
    opacity: 0.5

  &[data-checked=false][data-disabled=false]:focus,
  &[data-checked=false][data-disabled=false]:hover
    outline: none
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.15)


.error
  border-color: #FF3118
  &[data-disabled=false]:hover
    box-shadow: inset 0 0 8px rgba(255, 49, 24, 0.4)
  &[data-disabled=false]:focus
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0)
