$c-grey-contour: #E9E9E9

.booking-row-child
  &.booking-row-child--in-group
    display: flex
    align-items: flex-start
    justify-content: flex-start
    gap: 12px
    flex-direction: column
    background: #FFFFFF
    border-radius: 12px
    padding: 12px
    margin-bottom: 12px
  &.booking-row-child--with-calendar
  // padding-bottom: 340px
  &.booking-row-child--space-between
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 100%
    gap: 12px

.booking-form-elem-wrap
  margin: 0
  display: flex
  flex-direction: column
  &.booking-form-elem-wrap--with-margin-bottom
    margin-bottom: 16px
  &.booking-form-elem-wrap--row-children
    gap: 12px
    flex-direction: row
  &.booking-form-elem-wrap--row-time
    justify-content: flex-start
    width: 100%
    flex-wrap: wrap
    gap: 10px
  &.booking-form-elem-wrap--row-date
    gap: 12px
  &.booking-form-elem-wrap--open-calendar
    flex-wrap: wrap
    > div
      width: 100%

.booking-guest-count-wrap
  border: 1px solid $c-grey-contour
  position: relative
  height: 52px
  width: 140px
  border-radius: calc(52px / 2)
  transition: border-color 0.35s ease
  &:hover
    border: 1px solid #8C8C8C
.booking-guest-count-minus,
.booking-guest-count-plus
  display: flex
  justify-content: center
  align-items: center
  width: calc((140px - 42px) / 2)
  color: black
  height: 100%
  line-height: calc(52px - 2px)
  position: absolute
  top: 0
  border: none
  background-color: transparent
  background-repeat: no-repeat
  background-size: 24px 24px
  background-position: 50% 50%
  cursor: pointer
  transition: all .2s ease
  opacity: 0.6
  svg
    width: 20px
    height: 20px
  &:not(:disabled):hover
    opacity: 1
  &--disabled
    opacity: 0.2
.booking-guest-count-minus
  border-radius: inherit
  border-top-right-radius: 0
  border-bottom-right-radius: 0
  left: 0

.booking-guest-count-plus
  border-radius: inherit
  border-top-left-radius: 0
  border-bottom-left-radius: 0
  right: 0
.booking-guest-count-input
  border-radius: calc(52px / 2)
  width: 42px
  height: 100%
  text-align: center
  position: absolute
  top: 0
  left: 50%
  transform: translateX(-50%)
  border: none
  background-color: transparent
  color: #000000
  font-size: 15px
  line-height: 20px
  -moz-appearance: textfield
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0
  &:disabled
    color: #000000
