@import '@responsive'
@import '@mixins'
@import '@fonts'

.shadow
  position: absolute
  top: 0
  left: 0
  height: 100%
  z-index: 1
  opacity: 0
  pointer-events: none
  transition: opacity 1s ease
  @media (width <= 970px)
    opacity: 0 !important

  &.shadow-end
    left: initial
    right: 0

  &[data-is-show=true]
    opacity: 1
    pointer-events: initial
    transition: opacity 0.2s cubic-bezier(0.05, 0.38, 0.58, 1)
