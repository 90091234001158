@import './mixins'

// * usage:
// @import 'styles/fonts'
// @include font-regular
// @include font-medium
// @include font-a-headline

@mixin font-regular()
  font-weight: 400
  font-family: var(--font-regular)
@mixin font-medium()
  font-weight: 500
  font-family: var(--font-medium)

@mixin font-a-headline()
  font-weight: 500
  font-family: var(--font-a-headline)
