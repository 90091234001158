@import '@responsive'
@import '@mixins'
@import '@fonts'

.card
  display: flex
  gap: 16px

.img
  position: relative
  width: 114px
  height: 114px
  object-position: center
  object-fit: cover
  border-radius: 12px
  overflow: hidden
  background-color: #e8e8e8
  flex-shrink: 0
  +responsive(mobile)
    width: 68px
    height: 68px
  img
    object-fit: cover

.content
  display: flex
  flex-direction: column
  gap: 4px

.title

.subtitle
  color: #969696

.description
  display: -webkit-box
  -webkit-line-clamp: inherit
  -webkit-box-orient: vertical
  overflow: hidden

