@import '@fonts'
@import '@responsive'
@import '@ownerGlobal'

$container-padding: 16px
$flex-gap: 15px
$aside-width: 247px

.page-wrap
  display: flex
  justify-content: flex-start
  gap: $flex-gap
  width: 100%
  height: 100%
  background-color: #F2F2F2
  padding: 0 $container-padding 0
  margin-bottom: 40px

.page-dev-button
  z-index: 10
  position: fixed
  bottom: 1em
  right: 1em
  button
    &:hover
      color: salmon

.page-marketing
  margin-left: max(0px, calc(50vw - (798px/2) - $aside-width - $container-padding - $flex-gap))
  max-width: 798px
  min-width: 703px
  width: 100%
  overflow: hidden
  position: relative
  @media only screen and (max-width: 1440px)
    margin: 0 auto


.page-booking
  position: relative
  width: 100%
