@import '@responsive'

@keyframes spin
  from
    transform: rotate(0deg) scale(2)
  50%
    transform: rotate(180deg) scale(3)
  to
    transform: rotate(360deg) scale(2)

section
  &.container
    +responsive(mobile)
      position: relative
      padding: 0
  .content
    position: relative
    z-index: 1
    display: flex
    min-height: calc(21vw + 200px)
    +responsive(only-desktop-large)
      min-height: initial
      //height: calc(21vw + 220px)
      height: 622px
      max-height: 622px
    +responsive(tablet)
      min-height: calc(21vw + 204px)
    +responsive(mobile)
      padding: 0
      flex-direction: column-reverse
      min-height: initial

.main,
.aside
  min-height: inherit
  box-sizing: border-box
  padding: 24px
  border-radius: 32px
  +responsive(mobile)
    flex-direction: column
    padding: 16px 12px 0 12px
    border-radius: 24px

.main
  overflow: initial
  display: flex
  flex-direction: column
  position: relative
  flex-shrink: 0
  width: 521px
  min-width: 292px
  background-color: #eeeeee
  z-index: 0
  +responsive(only-desktop-medium)
    width: 445px
  +responsive(tablet)
    width: 36%
  +responsive(mobile)
    flex-shrink: 1
    width: 100%
    height: auto
    background-color: #fff
    margin-top: -20px
    z-index: 1
    min-height: initial

  &.main-skeleton
    position: relative
    display: block
    padding: 0
    border-radius: 32px
    overflow: hidden
    background-color: #F2F2F2

    +responsive(mobile)
      height: 122px
      background-color: #fff


.main-blur-wrapper
  overflow: hidden
  position: absolute
  border-radius: 32px
  top: 0
  right: 0
  left: 0
  bottom: 0
  z-index: -2

.main-blur
  width: 100%
  height: 100%
  filter: blur(25px)
  -webkit-filter: blur(25px) // TODO: fix autoprefixer
  transform: rotate(180deg) scale(2) translate3d(0, 0, 0) // must use translate3d for blur optimization
  pointer-events: none
  img
    object-position: center
    object-fit: cover

  &:after
    content: ''
    position: absolute
    display: block
    top: 0
    right: 0
    left: 0
    bottom: 0
    background-color: hsla(0, 0%, 15%, 0.15)
  +responsive(mobile)
    display: none

.aside-wrapper
  width: 100%
  height: 100%
  +responsive(mobile)
    height: 61.6vw
    overflow: clip
    position: relative
    margin-top: -5px

  &.aside-wrapper-skeleton
    position: relative
    display: block
    border-radius: 32px
    overflow: hidden
    background-color: #F2F2F2
    +responsive(mobile)
      border-radius: 32px 32px 0 0
      overflow: clip
      background-color: transparent
.aside-container
  width: 100%
  height: 100%
  +responsive(mobile)
    height: 1000px

.aside
  display: flex
  align-items: flex-end
  justify-content: flex-end
  position: relative
  height: 100%
  flex: 1
  background-color: #eeeeee
  z-index: 0
  transition: opacity .3s ease
  [data-visible=false] &
    opacity: 0
  [data-visible=true] &
    opacity: 1
  +responsive(mobile)
    position: sticky
    flex: initial
    width: 100%
    top: 10px
    margin-top: 5px
    height: 61.6vw
    min-height: initial
    padding-top: 12px
    padding-bottom: calc(20px + 12px)
  &.aside-skeleton
    padding: 0
    background-color: #F2F2F2
    border-radius: 24px 24px 0 0
    overflow: hidden

  &-inner
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    overflow: hidden
    border-radius: 32px
    pointer-events: none
    +responsive(mobile)
      border-radius: 24px 24px 0 0

    &:after,
    &:before
      content: ''
      display: block
      position: absolute
      left: 0
      right: 0
      bottom: 0
      z-index: -1
      pointer-events: none
    &:before
      top: 0
      box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.10) inset
    &:after
      height: 40%
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 2.00%, rgba(0, 0, 0, 0.35) 34.90%, rgba(0, 0, 0, 0.15) 68.38%, rgba(0, 0, 0, 0.00) 100%)
      +responsive(mobile)
        height: 100%
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.00) 50.69%, rgba(0, 0, 0, 0.15) 100%)
.img
  display: block
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  object-fit: cover
  object-position: center
  z-index: -2

.absolute-skeleton
  position: absolute
  left: 0
  right: 0
  top: 0
  bottom: 0

.svg-skeleton
  display: block
  height: 100%
  width: 100%

.rect-skeleton
  display: block
  height: 100vh
  width: 100%

.line-skeleton
  &--1
    width: 176px
    height: 18px
    transform: translateY(16px) translateX(12px)
  &--2
    width: 307px
    height: 28px
    transform: translateY(42px) translateX(12px)
  &--3
    width: 331px
    height: 18px
    transform: translateY(78px) translateX(12px)
  &--4
    width: 207px
    height: 18px
    transform: translateY(104px) translateX(12px)
