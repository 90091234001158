@import '@responsive'
@import '@mixins'
@import '@fonts'

.card
  position: relative
  display: flex
  flex-direction: column
  justify-content: flex-end
  align-items: flex-start
  gap: 20px
  padding: 24px
  color: #ffffff

  [data-cover-size="S"] &
    padding: 16px
    gap: 12px

.rating
  position: absolute
  top: 24px
  left: 24px
  [data-cover-size="S"] &
    top: 16px
    left: 16px

.content
  display: flex
  flex-direction: column
  gap: 8px
  padding-right: 110px
  text-wrap: wrap
  [data-cover-size="S"] &
    gap: 2px
    padding-right: 48px

.buttons-bottom
  display: flex
  gap: 6px
  align-items: center

.promo-badge
  +responsive(mobile)
    height: 40px
    width: 40px
    padding: 12px 1px
    border-radius: 30px
    :global(.promo-badge-cta-text)
      font-size: 10px

.favorite
  position: absolute
  right: 24px
  bottom: 24px
  +responsive(mobile)
    right: 16px
    bottom: 16px

.favorite-icon
  svg
    fill: transparent
    stroke: white
    transition: all .2s ease
  &[data-active=true] svg
    fill: var(--c-afisha)
    stroke: var(--c-afisha)


