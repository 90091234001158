@import '@responsive'
@import '@mixins'
@import '@fonts'

.content
  width: 100%
  overflow: hidden
  margin-bottom: 12px
  position: relative
  transition: all .2s ease

  &[data-no-margin=true]
    margin-bottom: 0px

  &[data-show=true]
    margin-bottom: 0
    .shadow
      pointer-events: none
      opacity: 0

.shadow
  position: absolute
  bottom: 0
  left: 0
  right: 0
  width: 100%

  height: 64px
  opacity: 1
  transition: all .2s ease
  +responsive(mobile)
    height: 48px

.button
  min-width: 80px
  max-height: 32px
  opacity: 1
  transition: all .2s ease, height .15s ease 0.15s
  &.open
    padding: 0
    opacity: 0
    pointer-events: none
    height: 0
    svg
      height: 0
      width: 0
