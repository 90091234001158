@import '@responsive'
@import '@mixins'
@import '@fonts'


.link
  position: relative
  padding-bottom: 12px

  &:after
    content: ''
    display: block
    position: absolute
    left: 0
    right: 0
    bottom: 0
    height: 1px
    background-color: var(--c-gray-88)
  &:last-child
    padding-bottom: 0
    &:after
      display: none

  &:hover
    .address,
    .name
      color: var(--c-afisha)

.address,
.name
  transition: color .18s ease

.address
  margin-top: 8px
  color: var(--c-gray-59)
