@import '@responsive'
@import '@mixins'

.wrapper
  +responsive(mobile)
    position: relative
  +responsive(only-desktop-large)
    max-width: 1187px

  img
    object-position: center

.container
  display: grid
  grid-template-columns: repeat(4, 1fr)
  gap: 8px
  height: 150px
  width: 100%
  +responsive(only-desktop-large)
    grid-template-columns: repeat(5, 1fr)
  +responsive(mobile)
    display: flex
    gap: initial
    height: 20vw
    min-height: 100px
    &:global(.keen-slider:not([data-keen-slider-disabled]))
      overflow: visible

.media-item
  cursor: pointer
  position: relative
  flex: 1
  overflow: hidden
  border-radius: 16px
  -webkit-transform: translateZ(0)
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%)
  transition: all .3s ease
  :global(.photo-wrapper)
    position: relative
    max-height: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.1)
    & > span
      max-height: 100%
  :global(.video-wrapper)
    background-color: rgba(0, 0, 0, 0.1)
  +responsive(mobile)
    transition: initial
    flex-basis: 40vw
    min-width: calc(100px + 5vw)

  &:hover
    img
      transform: scale(1.08)
    video
      transform: scale(1.28)

  img, video
    transition: all .3s ease
    height: 100%
    width: 100%
    display: block
    object-fit: cover
  video
    transform: scale(1.2)

  &[data-show-more=true]
    &:after
      position: absolute
      content: ''
      display: block
      top: 0
      bottom: 0
      left: 0
      right: 0
      background: rgba(0, 0, 0, 0.40)

  &-action-container
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 1

  &-more-number
    color: #fff

  &-play-button
    display: flex
    justify-content: center
    align-items: center
    background-color: #fff
    border-radius: 50%
    width: 44px
    height: 44px
    svg
      width: 28px
      height: 28px


.modal
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 410
  background-color: rgba(0, 0, 0, 0.85)
  //backdrop-filter: blur(0px)
  //-webkit-backdrop-filter: blur(0px) // TODO: fix autoprefixer
  //transform: translate3d(0, 0, 0) // must use translate3d for blur optimization
  +responsive(mobile)
    backdrop-filter: blur(8px)
    -webkit-backdrop-filter: blur(8px) // TODO: fix autoprefixer

  &__inner
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 24px 60px
    gap: 16px
    height: 100vh
    +responsive(mobile)
      padding: 24px 0

  &__main
    position: relative
    display: flex
    justify-content: center
    align-items: center
    flex: 1
    overflow: hidden
    width: 100%
    max-width: 1200px
    :global(.keen-slider)
      //border-radius: 24px
      +responsive(mobile)
        border-radius: 0
    :global(.photo-wrapper)
      display: flex
      position: relative
      width: 100%
      height: 100%
      & > span
        max-height: 100%

  &__main-item
    display: flex
    align-items: center
    justify-content: center
    height: calc(100vh - 116px)
    max-height: 700px

    [data-loading=true]
      filter: blur(5px)
    +responsive(only-tablet)
      max-height: 700px
    +responsive(mobile)
      height: initial
      width: 100%

    img, video
      max-height: calc(100vh - 116px)
      display: block
      overflow: hidden
      transition: filter .6s ease
      filter: blur(0px)
      +responsive(mobile)
        border-radius: 0
        height: initial
        width: 100%
    img
      object-fit: contain
      object-position: center

  &__thumbnail
    margin: auto
    max-width: 609px
    border-radius: 4px
    overflow: hidden
    flex-shrink: 0
    :global(.keen-slider)
      align-items: center
    +responsive(mobile)
      width: 100%

  &__thumbnail-item
    cursor: pointer
    min-width: 66px
    max-width: 66px
    height: 44px
    border-radius: 4px
    border: 2px solid transparent
    background-color: #313131
    +responsive(mobile)
      max-width: 66px
    &:global(.active)
      min-width: 90px
      height: 60px
      border: 2px solid #fff
    img
      object-fit: cover
      object-position: center

    img, video
      width: 100%
      height: 100%
    video
      transform: scale(1.5)
    img
      object-fit: cover
      object-position: center

  &__control
    position: absolute
    display: block
    top: calc(50% - 64px/2)
    transition: all .2s ease
    z-index: 1
    opacity: 1
    &:disabled,
    &[data-show=false]
      opacity: 0
      pointer-events: none
    svg
      transition: all .3s ease .3s
      width: 72px
      height: 64px
      color: #fff
    &:hover
      svg
        transition-delay: 0s
        transform: translateX(15px)

    &:nth-child(1)
      left: 155px
      &:hover
        svg
          transform: rotate(180deg) translateX(15px)
      svg
        transform: rotate(180deg)
    &:nth-child(2)
      right: 155px

  &__progress
    pointer-events: none
    position: absolute
    left: 0
    right: 0
    bottom: 12px
    text-align: center
    z-index: 1
    +responsive(mobile)
      bottom: 5px

  &__progress-text
    user-select: none
    display: inline-block
    margin: auto
    padding: 10px 24px
    border-radius: 30px
    background: rgba(77, 77, 77, 0.50)
    color: #fff
    +responsive(mobile)


  &__main-item-video-button
    position: absolute
    top: calc(50% - 54px/2)
    left: calc(50% - 54px/2)
    z-index: 1
    opacity: 0
    transition: all .5s ease
    pointer-events: none
    border-radius: 50%
    padding: 10px
    &[data-show=true]
      opacity: 1
      pointer-events: initial

    background-color: #fff
    svg
      height: 44px
      width: 44px


.close
  position: absolute
  top: 13px
  right: 11px
  z-index: 2
  height: 50px
  width: 50px
  padding: 5px
  svg
    color: #fff
    opacity: 0.75
