button.button[data-size=M]
  white-space: nowrap
  padding: 0 14px 0 10px

  .button__arrow[data-opened=true]
    transform: rotate(180deg)

.button
  & > div
    gap: 3px
  &[data-active=true]
    background: var(--c-gray-95)
    box-shadow: var(--e-inner-button)
    &:hover
      background: var(--c-gray-92)

  &__icon
    display: inline-flex
    justify-content: center
    align-items: center
    width: 18px
    height: 18px

  &__arrow,
  &__remove
    display: block
    background-position: center
    background-repeat: no-repeat
    width: inherit
    height: inherit
  &__arrow
    transition: transform .2s ease
    transform: rotate(0deg)
    &[data-opened=true]
      transform: rotate(180deg)
  &__remove
    height: 20px
    width: 20px
    color: #969696
  &__count
    margin-left: 5px
    color: #969696
    display: inline-block
