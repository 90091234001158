@import '@responsive'
@import '@mixins'
@import '@fonts'

#button
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2)
  svg
    width: 24px
    height: 24px
  +responsive(mobile)
    width: 40px
    height: 40px
    svg
      width: 24px
      height: 24px

