@import '@responsive'
@import '@mixins'
@import '@fonts'

.content
  display: flex
  flex-direction: column
  align-items: baseline
  gap: 4px
  padding-top: 8px

.skeleton-item
  position: absolute
  &.skeleton-item--1
    &[data-size="L"]
      top: 10px
      width: 152px
      height: 24px
      translate: 0 206px

    &[data-size="ML"]
      width: 144px
      height: 22px
      translate: 0 182px

    &[data-size="M"]
      width: 144px
      height: 22px
      translate: 0 182px

    &[data-size="S"]
      width: 140px
      height: 20px
      translate: 0 179px

  &.skeleton-item--2,
  &.skeleton-item--3
    height: 18px

  &.skeleton-item--2
    &[data-size="L"]
      width: 250px
      translate: 0 234px

    &[data-size="ML"]
      width: 217px
      translate: 0 210px

    &[data-size="M"]
      width: 217px
      translate: 0 210px

    &[data-size="S"]
      width: 162px
      translate: 0 203px

  &.skeleton-item--3
    &[data-size="L"]
      width: 196px
      translate: 0 256px

    &[data-size="ML"]
      width: 172px
      translate: 0 234px

    &[data-size="M"]
      width: 217px
      translate: 0 234px

    &[data-size="S"]
      width: 105px
      translate: 0 225px


