@import '@responsive'
@import '@mixins'
@import '@fonts'

.container
  display: flex
  justify-content: space-between
  padding-left: 24px
  padding-right: 24px
  gap: 22px
  // border: 4px solid salmon
  +responsive(only-desktop-medium)
    padding-left: calc(50vw - 618px)
  +responsive(only-desktop-large)
    padding-left: 48px
    padding-right: 24px
  +responsive(tablet)
    flex-direction: column
    align-items: center
  +responsive(mobile)
    padding: 0 12px

.main
  max-width: 923px
  width: 100%
  flex-shrink: 0
  +responsive(only-desktop-large)
    max-width: 1236px
  +responsive(tablet)
    max-width: 923px

.aside
  display: flex
  flex-direction: column
  gap: 256px
  max-width: 300px
  width: 100%
  +responsive(only-desktop-large)

  +responsive(tablet)
    max-width: 923px
