@import '@responsive'
@import '@mixins'
@import '@fonts'

.close
  position: absolute
  right: 16px
  top: 10px
  padding: 5px
  svg
    width: 32px !important
    height: 32px !important
