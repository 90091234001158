@import '@responsive'
@import '@mixins'
@import '@fonts'

.card
  display: flex
  flex-direction: column
  justify-content: flex-end
  padding: 24px
  color: #fff
  [data-cover-size='S'] &
    padding: 16px

.title
  padding-right: 76px
  [data-cover-size='S'] &
    padding-right: 52px

.promo
  position: absolute
  right: 24px
  top: 24px
  [data-cover-size='S'] &
    right: 16px
    top: 16px


.favorite
  position: absolute
  right: 24px
  bottom: 24px
  +responsive(mobile)
    right: 16px
    bottom: 16px
  svg
    stroke: white
