@import '@fonts'

.root
  @include font-regular
  color: var(--c-text)
  min-width: 100%
  min-height: 100vh
  display: flex
  flex-direction: column
  justify-content: space-between
  & > *:nth-child(2)
    flex: 1
