@import '@fonts'
@import '@responsive'
@import '@ownerGlobal'

// *** home tab page ***

.page-content
  // empty

.page-content-section
  width: 100%
  padding-bottom: 36px

.page-content-white-block
  @include page-content-white-block
  display: flex
  flex-direction: column
  gap: 20px
  &.page-content-white-block--no-padding
    @include page-content-white-block--no-padding

// * rest-info *

$avatarSize: 116px
$toggleBtnSize: 48px
.rest-info-main-data
  width: 100%
  min-height: 116px
  position: relative
  padding-left: 116px
  padding-right: 48px
  display: flex
  justify-content: flex-start
  align-items: center
  &.rest-info-main-data--no-photo
    padding-left: 0
.rest-info-logo-wrap
  width: 116px
  height: 116px
  position: absolute
  top: 0
  left: 0
  border-radius: 50%
  background: #f2f2f2
  overflow: hidden
.rest-info-logo-image
  border-radius: inherit
  height: 116px !important
  min-height: 116px !important
  max-height: 116px !important
  width: auto !important
  min-width: 100% !important
  top: 50% !important
  left: 50% !important
  transform: translate3d(-50%, -50%, 0)
.rest-info-wrap
  width: 100%
  height: 100%
  padding-right: 20px
  padding-left: 20px
.rest-info-name
  margin-bottom: 6px
  display: block
.rest-info-address
  margin-bottom: 16px
  display: block
  color: #969696
.rest-info-access-date-wrap
  display: flex
  justify-content: flex-start
.rest-info-access-date
  white-space: nowrap
  padding: 0 11px
  height: 28px
  min-width: 28px
  border-radius: 14px
  display: flex
  justify-content: center
  align-items: center
  &.rest-info-access-date--green
    color: #31BE00
    background-color: rgba(49, 190, 0, 0.1)
  &.rest-info-access-date--red
    color: #FF3118
    background-color: rgba(255, 49, 24, 0.1)
.rest-info-toggle-button
  position: absolute
  top: 0
  right: 0
  height: $toggleBtnSize
  width: $toggleBtnSize
.rest-info-toggle-icon
  width: 24px
  height: 24px
  display: block
  transition: all .2s ease
  .rest-info-toggle-button[data-state='open'] &
    transform: rotate(180deg)


// * stat *

.stats-title
  margin-bottom: 12px

.stats-block
  position: relative
  display: grid
  grid-template-columns: 1fr 1fr
  grid-template-rows: repeat(3, min-content)
  gap: 20px 16px
  padding: 20px 16px
  z-index: 0
  background-color: #fff
  border-radius: 24px
  &[data-two-block=true]
    background-color: transparent
    gap: 20px 32px
    &:after,
    &:before
      content: ''
      position: absolute
      display: block
      top: 0
      bottom: 0
      width: 50%
      border-radius: 24px
      background-color: #fff
      z-index: -1
    &:after
      left: 0
    &:before
      right: 0

  .stats-block-source
    grid-column: 1 / 3
    justify-content: flex-end
    margin-top: -12px
    margin-bottom: -8px


.stat-two-cols
  margin: 12px 0 0
  display: flex
  justify-content: space-between
  & > *
    width: 50%


.stat-source-descr
  margin-top: 14px

// * review-data *

.review-data-wrap-margin
  margin: 12px 0 0


.rest-selection-block-wrapper
  display: flex
  flex-direction: column
  gap: 12px
  width: 394px
  padding: 20px 24px
