@import '@responsive'
@import '@fonts'

.wrapper
  margin-top: 0

.address
  margin-bottom: 8px
  text-wrap: initial
.metro-list
  margin-bottom: 24px
  display: flex
  gap: 8px
  flex-wrap: wrap
  +responsive(mobile)
    margin-bottom: 16px



.metro-item
  font-weight: 400
  font-size: 15px
  line-height: 20px
  display: flex
  align-items: center
.metro-label
  width: 8px
  height: 8px
  border-radius: 50%
  margin-right: 5px
  background: var(--c-gray-95)

.map-container
  position: relative
  height: 56px
  margin-top: 24px
  margin-bottom: 24px
  transition: height 0.5s ease, background-color .3s ease, box-shadow .3s ease
  background-color: #FAFAFA
  background-repeat: no-repeat
  background-position: center center
  background-size: cover
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.25)
  border-radius: 60px
  overflow: hidden
  &:after
    content: ''
    position: absolute
    display: block
    left: 0
    right: 0
    top: 0
    bottom: 0
    opacity: 0
    pointer-events: none
    transition: all .3s ease
    background-color: rgba(0, 0, 0, 0.04)
  &[data-loaded-map=true],
  &[data-loading-map=true]
    border-radius: 32px
    +responsive(mobile)
      border-radius: 24px
    .map-button
      opacity: 0
      pointer-events: none
    .map-wrapper
      opacity: 1
      pointer-events: initial

  &[data-loaded-map=true]
    height: 228px

  +responsive(mobile)
    margin-top: 16px

  &[data-loaded-map=false]:hover
    background-color: #ffffff
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.35)
    &:after
      opacity: 1


.map-wrapper
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 228px
  z-index: 0
  opacity: 0
  pointer-events: none
  transition: all .3s ease 0.4s
  &:after
    position: absolute
    display: block
    content: ''
    left: 0
    right: 0
    top: 0
    bottom: 0
    pointer-events: none
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.25)
.map
  height: inherit
.map-spinner
  opacity: 0
  pointer-events: none
  transition: all .2s ease
.map-button
  position: absolute
  top: 0
  z-index: 1
  height: 100%
  width: 100%
  border: none
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  opacity: 1
  transition: all .2s ease

.map-button-label
  height: 32px
  padding: 0 17.6px 0 13.2px
  background: #FFFFFF
  border: 1px solid var(--c-gray-88)
  border-radius: calc(32px / 2)
  display: flex
  justify-content: center
  align-items: center
  gap: 10px
.map-button-pin
  width: 14px
  height: 18px

.interface
  display: flex
  flex-direction: column
  align-items: center
  position: absolute
  right: 10px
  top: 10px
  z-index: 1
  gap: 6px

.zoom
  display: flex
  flex-direction: column
  align-items: center
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.20)
  border-radius: 28px
  background-color: #EBEBEB
  gap: 1px
  button
    display: flex
    align-items: center
    justify-content: center
    transition: all .2s ease
    background-color: #fff
    padding: 8px
    &:first-child
      border-radius: 50px 50px 0 0
    &:last-child
      border-radius: 0 0 50px 50px
    &:hover
      background-color: #f6f6f6
    svg
      width: 24px
      height: 24px

.user-location
  display: flex
  justify-content: center
  align-items: center
  transition: all .2s ease
  background-color: #fff
  padding: 8px
  border-radius: 50%
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.20)
  &:hover
    background-color: #f6f6f6
  &:disabled
    pointer-events: none
    svg
      opacity: 0.2
  svg
    width: 24px
    height: 24px

.table
  margin-bottom: 24px
  display: grid
  grid-template-columns: repeat(4, 1fr)
  gap: 24px 15px
  +responsive(only-desktop-large)
    grid-template-columns: repeat(5, 1fr)
    gap: 24px 35px
  +responsive(mobile)
    margin-bottom: 28px
    grid-template-columns: repeat(3, 1fr)
  @media screen and (max-width: 600px)
    grid-template-columns: 1fr
    gap: 8px

.table-cell
  display: flex
  gap: 6px
  flex-direction: column
  @media screen and (max-width: 600px)
    flex-direction: row

.cell-title
  display: inline-block
  flex-shrink: 0
  color: var(--c-gray-69)
  +responsive(mobile)
    &:after
      content: ':'

.cell-data
  display: flex
  flex-direction: column
  & > *
    display: inline-block
  @media screen and (max-width: 600px)
    flex-direction: row
    gap: 0 5px
    flex-wrap: wrap

.cell-data-link
  transition: 0.2s ease
  &:hover
    opacity: 0.6


.metro-skeleton
  height: 18px
  width: 257px
  +responsive(mobile)
    width: 122px

.map-skeleton
  position: relative
  height: 56px
  margin-top: 24px
  margin-bottom: 24px
  +responsive(mobile)
    margin-top: 16px

.table-skeleton-mobile
  margin-bottom: 24px

.tag-skeleton
  width: 122px
  height: 40px
  min-width: 122px

