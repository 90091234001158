// @import '@fonts'
@import '@responsive'



// *** rests ***

.rest-list-layout
  position: relative
  margin-top: 36px
  margin-bottom: 60px
  min-height: 400px
  +responsive(mobile)
    margin-bottom: 36px

.rest-list-grid
  display: grid
  justify-content: start
  align-items: start
  justify-items: start
  grid-template-columns: repeat(3, 1fr)

  gap: 36px 16px
  opacity: 1
  transition: all .3s ease
  &[data-is-loading=true]
    opacity: 0.5
    pointer-events: none

  +responsive(only-desktop-large)
    grid-template-columns: repeat(4, 1fr)


  [data-is-special="false"] &
    +responsive(mobile)
      grid-template-columns: repeat(3, 1fr)
      gap: 28px 9px

    @media screen and (max-width: 540px)
      grid-template-columns: repeat(2, 1fr)

    @media screen and (max-width: 360px)
      grid-template-columns: 1fr

  [data-is-special="true"] &
    +responsive(mobile)
      grid-template-columns: repeat(2, 1fr)
      gap: 28px 9px

    @media screen and (max-width: 540px)
      grid-template-columns: repeat(1, 1fr)

  .card
    width: 100%

.pagination
  &[data-has-pagination=true]
    margin-top: 36px
    +responsive(mobile)
      margin-top: 45px

.columnAdvert
  margin-top: 36px
  +responsive(tablet)
    margin-top: 0

.advertListing
  grid-column: 1/5
  width: 100%
  @media screen and (max-width: 1592px)
    grid-column: 1/4
    width: 100%
  @media screen and (max-width: 541px)
    grid-column: 1/3
    width: 100%
  @media screen and (max-width: 360px)
    grid-column: 1/2
    width: 100%
