@import "@responsive"

.button
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  position: relative
  color: #fff
  transition: all .2s ease
  user-select: none
  -moz-user-select: none
  -webkit-user-select: none

  // default
  padding: 18px 28px
  font-size: 15px
  border-radius: 30px
  border: 1px solid #000

  &[data-size="XS"]
    height: 28px
    padding: 0 10px
    font-size: 13px
    border-radius: 18px

  &[data-size="S"]
    height: 32px
    padding: 0 10px
    font-size: 13px
    border-radius: 18px

  &[data-size="M"]
    height: 40px
    padding: 0 16px
    font-size: 13px
    border-radius: 28px

  &[data-size="L"]
    height: 48px
    padding: 0 20px
    font-size: 15px
    border-radius: 28px

  &[data-size="XL"]
    height: 56px
    padding: 0 28px
    font-size: 15px
    border-radius: 30px

  &[data-mode="white"]
    background-color: #fff
    border-color: transparent
    box-shadow: initial
    color: #000
    &:hover,
    &:focus
      background-color: var(--c-gray-95)
    &:disabled
      background-color: #fff

  &[data-mode="white-border"]
    background-color: #fff
    box-shadow: inset 0 0 0 1px var(--c-gray-88)
    color: #000
    border: transparent
    &:hover,
    &:focus
      box-shadow: var(--e-inner-button)
    &:disabled
      box-shadow: inset 0 0 0 1px var(--c-gray-88)

  &[data-mode="yellow-border"]
    background-color: #FFFBD4
    border-color: var(--c-gray-88)
    box-shadow: inset 0 0 0 transparent
    color: #000
    &:hover,
    &:focus
      box-shadow: var(--e-inner-button)
    &:disabled
      border-color: var(--c-gray-88)
      box-shadow: inset 0 0 0 transparent

  &[data-mode="white-shadow"]
    background-color: #fff
    border-color: transparent
    box-shadow: var(--e-inner-button)
    color: #000
    &:hover,
    &:focus
      background-color: var(--c-gray-95)
    &:disabled
      background: #fff

  &[data-mode="black"]
    background-color: #000
    border-color: transparent
    box-shadow: initial
    color: #fff
    &:hover,
    &:focus
      background-color: var(--c-gray-20)
    &:disabled
      background: var(--c-gray-59)

  &[data-mode="red-link"]
    background-color: transparent
    border-color: transparent
    box-shadow: none
    color: var(--c-afisha)
    border-radius: 0
    padding: 0
    height: 22px
    font-size: 15px
    line-height: 22px
    &:hover,
    &:focus
      color: #000000
    &:disabled
      color: var(--c-afisha)
      opacity: 0.38
      pointer-events: none
      user-select: none

  &:disabled
    cursor: initial

  &:disabled &
    &__content
      opacity: 0.4

  &__content
    display: flex
    align-items: center
