@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  .title
    margin-bottom: 20px
    +responsive(mobile)
      margin-bottom: 8px

.items
  display: flex
  flex-direction: column

.items-skeleton
  margin-top: 20px
  display: flex
  flex-direction: column
  gap: 16px
  +responsive(mobile)
    gap: 20px

.item-skeleton
  width: 100%
  height: 56px
  +responsive(mobile)
    height: 48px
