@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  flex-direction: column
  gap: 24px

.header
  display: flex
  gap: 16px
  align-items: center

#delete svg
  width: 19px
  height: 19px

.two-columns
  display: flex
  justify-content: space-between
  gap: 6px
  > *
    flex: 1
    flex-shrink: 0

.photo-description
  margin-top: 10px
  color: var(--c-gray-59)
