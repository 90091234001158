@import '@fonts'
@import '@responsive'

.container
  margin-top: 54px
  padding-bottom: 24px
  +responsive(only-desktop-large)
    margin-top: 100px
  +responsive(mobile)
    margin-top: 37px
    padding-bottom: 12px

.footer
  min-height: 420px
  display: flex
  justify-content: flex-start
  +responsive(only-desktop-large)
    padding-left: 0
    padding-right: 0
    box-sizing: content-box
  +responsive(mobile)
    flex-direction: column
    gap: 12px

.title
  max-width: 400px
  +responsive(mobile)
    max-width: 350px

.aside
  background-color: var(--c-gray-95)
  border-radius: 32px
  padding: 24px
  width: var(--w-footer-aside)
  flex-shrink: 0
  +responsive(only-tablet)
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 50px
    width: 395px
  +responsive(mobile)
    padding: 20px
    width: 100%

  &-top
    display: flex
    align-items: flex-start
    gap: 10px

.main
  background-color: var(--c-gray-95)
  border-radius: 32px
  padding: 20px 24px
  display: flex
  justify-content: space-between
  gap: 129px
  width: 100%
  +responsive(mobile)
    flex-direction: column
    gap: 40px
    padding: 20px

  &-column
    display: flex
    flex-direction: column
    justify-content: space-between
    position: relative
    width: 100%
    &__1
      max-width: 434px
      +responsive(mobile)
        max-width: initial
    &__2
      max-width: 680px
      +responsive(mobile)
        max-width: initial
        gap: 40px

.meta
  display: flex
  flex-direction: column
  gap: 20px
  &-row
    display: flex
    gap: 25px
  &-mistake-button
    height: 40px
    padding: 0
    font-size: 13px
    line-height: 18px
    color: #737373
    background-color: transparent
    border: none
    cursor: pointer
    transition: all .2s ease
    &:hover
      color: black


.social
  display: flex
  gap: 24px
  &-link
    color: transparent
    font-size: 0
    width: 40px
    height: 40px
    display: block
    +responsive(mobile)
      width: 36px
      height: 36px
    &:hover
      svg
        fill: var(--c-afisha)
    svg
      transition: all .2s ease
      fill: black


.qr-code
  background-color: var(--c-white)
  min-width: 187px
  min-height: 187px
  padding: 12px
  border-radius: 15px
  position: relative
  // border: 4px solid red
  display: block
  img
    position: absolute
    top: 50% !important
    left: 50% !important
    transform: translate(-50%, -50%)
    width: calc(187px - (12px * 2)) !important
    height: calc(187px - (12px * 2)) !important
    display: block
    object-fit: cover
    object-position: center
    // border: 4px solid blue
  +responsive(only-tablet)
    min-width: 132px
    min-height: 132px
    padding: 8px
    img
      width: calc(132px - (8px * 2)) !important
      height: calc(132px - (8px * 2)) !important


.email-block
  display: flex
  flex-direction: column
  gap: 16px
  +responsive(mobile)
    gap: 20px

  &-text
    display: flex
    flex-direction: column
    gap: 0
    +responsive(mobile)
      gap: 4px

.apps
  display: flex
  flex-direction: column
  gap: 20px
  +responsive(mobile)
    max-width: 400px
  &-links
    gap: 8px
    display: flex
    flex-wrap: wrap
    +responsive(mobile)
      gap: 12px

  .app-link
    height: 40px
    min-width: 120px
    padding: 4.77px 12px 7.2px 6.5px
    background-color: #fff
    color: transparent
    display: block
    background-repeat: no-repeat
    background-size: 100% 100%
    background-position: center center
    border-radius: 8px
    transition: box-shadow .25s
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0)

    &:hover:not(:disabled),
    &:active:not(:disabled)
      box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.18)


  .app-store
    padding: 7.85px 10.21px 6.5px 9.64px

.subscribe
  display: flex
  +responsive(tablet)
    flex-direction: column
    gap: 8px

  &-input
    padding: 18px 28px
    height: 56px
    border: none
    border-radius: 30px

.navigation
  display: flex
  justify-content: flex-start
  width: 100%
  gap: 20px
  font-size: 13px
  line-height: 18px
  +responsive(mobile)
    flex-direction: column
    gap: 24px
  &-col
    max-width: 160px
    width: 100%
    display: flex
    flex-direction: column
    gap: 15px
    +responsive(mobile)
      max-width: initial
      flex-direction: row
      flex-wrap: wrap
      gap: 12px 16px

  &-link
    color: var(--c-gray-59)
    text-decoration: none
    transition: all .2s ease
    cursor: pointer
    &:hover
      color: black

  &-separator
    height: 1px
    background-color: #E1E1E1

.privacy
  color: #737373
  +responsive(mobile)
    color: #969696
  a
    transition: color .17s ease
    text-decoration: none
    color: inherit
    &:hover
      color: black
