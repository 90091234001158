@import '@responsive'
@import '@mixins'
@import '@fonts'

.wrapper
  display: flex
  flex-direction: column
  gap: 36px

.main-description
  color: var(--c-gray-59)

.button-add
  width: 100%
  &__content
    display: flex
    gap: 10px
    justify-content: center
    align-items: center
    svg
      width: 20px
      height: 20px
