@import '@responsive'
@import '@mixins'
@import '@fonts'

.nav
  max-width: calc(100vw - 24px)
  display: flex
  grid-column: 1 / 3
  grid-row: 2 / 3

.nav-item-wrapper
  display: flex
  gap: 4px
