@import "@responsive"

.container
  margin-top: 40px
  +responsive(mobile)
    margin-top: 8px

.list
  margin-top: 36px
  display: flex
  flex-direction: column
  gap: 60px
  +responsive(mobile)
    gap: 24px

    & > div
      padding-bottom: 24px
      border-bottom: 1px solid rgba(150, 150, 150, 0.2)
      &:last-child
        border-bottom: none

.filters
  margin-top: 24px
  +responsive(mobile)
    margin-top: 12px

.title-link
  color: var(--c-afisha)
  transition: color .2s ease
  &:hover
    color: #dc1f0c

.pagination
  margin-top: 48px
  +responsive(mobile)
    margin-top: initial
