@import '@responsive'
@import '@mixins'
@import '@fonts'

.selections-wrap
  margin-top: 0
  margin-bottom: 30px

.selections-header
  display: flex
  align-items: center
  justify-content: flex-start
  gap: 16px
  margin-bottom: 25px

.grid
  display: grid

  gap: 16px
  width: 100%
  .item
    width: initial

  &[data-mode='6']
    grid-template-columns: repeat(4, 1fr)
  &[data-mode='4']
    grid-template-columns: repeat(3, 1fr)

