@import '@responsive'
@import '@mixins'
@import '@fonts'

@keyframes spin
  0%
    transform: rotate(0deg) scale(1)
  50%
    transform: rotate(180deg) scale(1.5)
  100%
    transform: rotate(360deg) scale(1)

.wrapper
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  left: 0
  right: 0
  top: 0
  opacity: 0
  min-height: 100%
  pointer-events: none
  transition: all .2s ease
  z-index: 1
  &[data-visible=true]
    opacity: 1
    pointer-events: initial

.spinner
  width: 32px
  height: 32px
  animation: spin 1s linear infinite
