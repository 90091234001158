@import '@fonts'
@import '@responsive'

.main-section
  display: flex
  flex-direction: column
  gap: 31px
  margin-bottom: 36px
  +responsive(only-desktop-large)
    margin-bottom: 48px
  +responsive(mobile)
    gap: 26px

section.cover-roll-section
  position: relative
  @media only screen and (min-width: 1850px)
    border-radius: 32px
    overflow: hidden

.container-main-wrapper,
.container-main
  display: flex
  flex-direction: column
  gap: 60px
  +responsive(mobile)
    gap: 44px


