
.disabled
  opacity: 0.4
  &__value
    opacity: 0.5

.search
  position: relative

.icon-search,
.clean
  position: absolute
  width: 24px
  height: 24px
  top: 12px

.icon-search
  pointer-events: none
  left: 16px

.clean
  cursor: pointer
  border: none
  background-color: transparent
  right: 19px
  color: #969696
  transition: all .2s ease
  opacity: 0
  &.visible
    opacity: 1
  &:hover
    color: #000

input.input
  padding-left: 48px
  padding-right: 52px

