@import '@shared/styles/responsive'
@import '@shared/styles/mixins'
@import '@shared/styles/fonts'


.tag-wrapper
  +responsive(mobile)
    position: relative
    left: -12px
    min-width: 100vw
    padding-left: 12px
    padding-right: 12px
    overflow: hidden
    :global(.keen-slider:not([data-keen-slider-disabled]))
      overflow: visible

.tag
  white-space: nowrap
