@import '@responsive'
@import '@mixins'
@import '@fonts'

.filters

  +responsive(mobile)
    width: 100vw
    margin-left: -12px
    padding: 0 12px
