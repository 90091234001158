@import '@responsive'

$paymentItemWidth: 297px
$paymentImageHeight: 198px
$buttonSize: 24px
$calendarArrowsWidth: 32px
$calendarDeselectGap: 8px

$calendarHeightDesktop: 70px
$calendarHeightMobile: 52px

$calendarListGapDesktop: 6px
$calendarListGapMobile: 3px

.wrapper
  margin-bottom: 60px
  +responsive(mobile)
    margin-bottom: 40px
  overflow: hidden

.title
  margin-bottom: 28px
  +responsive(mobile)
    margin-bottom: 12px

  // +responsive(mobile)
  //   display: none

// *** payment ***

.payment-list
  display: flex
  justify-content: flex-start
  gap: 32px 16px
  flex-wrap: wrap
  +responsive(mobile)
    flex-wrap: nowrap
    gap: 32px 0

@keyframes slide
  0%
    left: -550px
  100%
    left: calc(100% + 50px)

.payment-list.payment-list--skeleton
  pointer-events: none
  user-select: none
  flex-wrap: nowrap
  position: relative
  overflow: hidden
  gap: 16px
  +responsive(mobile)
    gap: 8px
  &::after
    z-index: 1
    content: ''
    // background: linear-gradient(90deg, rgba(204, 204, 204, 0) 0%, rgba(232, 232, 232, 0.598958) 54.57%, rgba(196, 196, 196, 0) 100%)
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.598958) 50%, rgba(255, 255, 255, 0) 100%)
    position: absolute
    top: 0
    left: -550px
    bottom: 0
    -webkit-animation: slide 1.5s infinite
    -webkit-animation-delay: 2s
    animation: slide 1.5s infinite
    animation-delay: 1s
    transform: skewX(-15deg)
    width: 450px
  .payment-item-wrap
    background-color: #F2F2F2
  .payment-name,
  .payment-genre,
  .payment-time-descr
    background-color: #F2F2F2
    color: transparent
    border-radius: 11px
  .payment-name
    width: 156px
  .payment-genre
    width: 253px
  .payment-time-descr
    width: 201px

.payment-show-more-wrap
  margin: 36px 0 0
  +responsive(mobile)
   margin: 20px 0 0
.payment-show-more-button
  +responsive(mobile)
    height: 48px
