@import '@responsive'

$rad-content--desk: 12px

.wrapper
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.header-button
  position: absolute
  right: 10px
  top: 10px
  z-index: 1
  .button-close
    color: #969699
    background-color: transparent

.content
  position: absolute
  top: 0
  left: 50%
  width: 100%
  height: 100%
  transform: translate3d(-50%, 0, 0)
  background: #F6F6F6
  padding: 48px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  border-radius: $rad-content--desk 0 0 $rad-content--desk
  box-shadow: none

  +responsive(mobile)
    padding: 0 24px
    border-radius: 16px 16px 0 0

.title[data-size=h3]
  margin-bottom: 24px
  width: 455px
  max-width: 100%
  text-align: center
  color: #000000
  font-size: 28px
  line-height: 32px
  text-transform: uppercase
  +responsive(mobile)
    color: #FF3118
    font-size: 22px
    line-height: 24px
    margin-bottom: 32px

.buttons
  display: flex
  justify-content: center
  gap: 16px
  max-width: 310px
  width: 100%
  flex-direction: column
  margin: 0 auto
  +responsive(mobile)
    max-width: 279px

