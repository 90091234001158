@import '@responsive'
@import '@mixins'
@import '@fonts'

.author
  display: flex
  align-items: center
  gap: 20px
  &-text
    display: flex
    flex-direction: column
    gap: 4px
  .author-avatar
    +responsive(mobile)
      width: 60px
      height: 60px

.text-skeleton
  overflow: hidden
  border-radius: 16px
  width: 636px
  height: 31px
  +responsive(mobile)
    width: 207px
    height: 18px
