@import '@responsive'
@import '@mixins'


div.wrapper
  +responsive(only-desktop-large)
    justify-content: flex-start
  @media (width <= 970px)
    overflow: hidden

.promo
  position: relative
  overflow: hidden

  :global(.keen-slider)
    overflow: visible

  @media (width <= 970px)
    width: 100%
    padding-left: 0
    padding-right: 0
    left: 0
    margin-right: 0
    overflow: visible
