@import '@fonts'
@import '@responsive'

@import '@ownerGlobal'

.owner-news-item
  // empty

.owner-news-item-wrap-with-image
  margin: 12px 0 0
  display: flex
  justify-content: space-between
  & > *:first-child
    width: 198px
    height: 198px
  & > *:last-child
    width: calc(100% - 198px)
    height: 198px

.owner-news-item-image-block
  background: #FFFFFF
  border-radius: 24px
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden
  position: relative
  img
    max-height: 100% !important
    max-width: auto !important
    min-height: 198px !important
    min-width: 198px !important
    width: auto !important
    height: auto !important
    position: absolute !important
    top: 50% !important
    left: 50% !important
    transform: translate3d(-50%, -50%, 0)

.owner-news-item-white-block
  @include page-content-white-block

.owner-news-item-wrap-content
  position: relative
  height: 100%
  max-height: 100%
  overflow: hidden
  &.owner-news-item-wrap-content--with-w-shadow
    &::before
      content: ''
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 86.42%)
      display: block
      position: absolute
      z-index: 1
      bottom: 0
      height: 50px
      left: 0
      right: 0

.owner-news-item-type
  color: #969696
  margin-bottom: 4px

.owner-news-item-title-name
  margin-bottom: 12px
  padding-right: calc(48px + 58px)

.owner-news-item-data-text
  font-size: 15px !important
  line-height: 22px !important

.owner-news-item-edit-button
  position: absolute
  top: 0
  right: 0
  width: 48px
  height: 48px

.owner-news-item-edit-icon
  width: 20px
  height: 20px


.menu
  position: relative
  margin-top: 12px
  background-color: #fff
  display: flex
  flex-direction: column
  gap: 32px
  padding: 24px
  border-radius: 24px
  .owner-news-item-edit-button
    top: 16px
    right: 19px

.dishes
  display: flex
  flex-wrap: wrap
  gap: 24px 20px
  justify-content: space-between
  @media only screen and (max-width: 970px)
    gap: 20px
    flex-direction: column
    flex-wrap: nowrap

.dish
  width: calc(50% - 10px)
  @media only screen and (max-width: 970px)
    width: 100%
    max-width: 500px
