@import '@responsive'
@import '@mixins'
@import '@fonts'

.header
  margin-bottom: 24px

.description
  max-width: 687px
  color: var(--c-gray-59)

.section-title
  font-size: 22px
  line-height: 118%
  margin: 0 0 20px
  font-weight: 500
  &.section-top-margin
    margin-top: 25px

.row
  display: flex
  gap: 20px 16px
  width: 100%
  margin-bottom: 36px
  & > *
    flex: 1
  +responsive(mobile)
    flex-direction: column

.column
  display: flex
  width: 100%
  gap: 16px
  margin-bottom: 36px
  & > *
    flex: 1

.footer
  width: 100%
  margin-top: 24px

  .button
    width: 100%
