@import "@responsive"

.menu
  display: flex
  flex-direction: column
  justify-content: center
  background: var(--c-gray-95)
  border-radius: 16px
  overflow: hidden
  position: relative
  text-decoration: none
  padding: 5px 20px

  &[data-size='298x92']
    width: 297px
    min-height: 92px
    padding: 5px 20px
    .image-wrapper
      right: 13px
      bottom: -7px
  &[data-size='261x86']
    width: 261px
    min-height: 86px
    padding: 5px 20px
    .image-wrapper
      bottom: -7px
      right: 0
    .title
      max-width: calc(100% - 80px)
  &[data-size='245x76']
    width: 245px
    min-height: 76px
    padding: 5px 20px
    .image-wrapper
      bottom: -7px
      right: 4px
    .title
      max-width: calc(100% - 70px)

.title,
.ext,
.image
  pointer-events: none

.title
  max-width: calc(100% - 90px)
  display: block
  margin-bottom: 2px
  color: var(--c-black)

.ext
  display: block
  color: var(--c-gray-59)

.image-wrapper
  position: absolute
  min-height: inherit
  display: block

.image-elem
  object-fit: cover
