@import '@fonts'
@import '@responsive'

.number
  margin: 0
  text-align: left
  text-decoration: none
  font-weight: 500
  @include font-a-headline

  &[data-size='N1']
    font-size: 100px
    line-height: 80px
    letter-spacing: -2px

  &[data-size='N2']
    font-size: 60px
    line-height: 60px
    letter-spacing: -1.2px

  &[data-size='N3']
    font-size: 60px
    line-height: 60px
    letter-spacing: -1.2px

  &[data-size='N4']
    font-size: 52px
    line-height: 52px
    letter-spacing: -1.04px
