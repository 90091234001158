@import '@fonts'
@import '@responsive'

.content
  &[data-promo=true]
    padding: 8px 12px
    border-radius: 16px
    background: #E0EEFF

    :global(.promo-badge-label)
      background-color: #fff

.favorite
  transition: all .2s ease
  &[data-active=true] svg
    fill: var(--c-afisha)
    stroke: var(--c-afisha)

.afisha-selected
  position: absolute
  z-index: 1
  top: -4px
  right: 16px
  svg
    width: 28px
    height: 28px

.img-content
  display: flex
  align-items: flex-start
  flex-direction: column
  justify-content: space-between

.img-bottom-wrapper
  display: flex
  position: relative
  width: 100%
  justify-content: flex-end
  gap: 4px

.dots
  color: var(--c-gray-59)

.promo-label[data-size='S'][data-promo=true]
  height: 24px
  padding: 3px 8px 2.5px 8px

.title
  cursor: pointer
  &[data-trim=true]
    display: -webkit-box
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    overflow: hidden

.card[data-size="S"]
  .tasty-club
    margin: 0 0 1px 0
  .content[data-promo=true]
    padding: 8px 6px
  .img-bottom-wrapper
    gap: 2px


.skeleton-item
  position: absolute
  &.skeleton-item--1
    &[data-size="L"]
      top: 10px
      width: 152px
      height: 24px
      translate: 0 206px

    &[data-size="ML"]
      width: 144px
      height: 22px
      translate: 0 182px

    &[data-size="M"]
      width: 144px
      height: 22px
      translate: 0 182px

    &[data-size="S"]
      width: 140px
      height: 20px
      translate: 0 179px

  &.skeleton-item--2,
  &.skeleton-item--3
    height: 18px

  &.skeleton-item--2
    &[data-size="L"]
      width: 250px
      translate: 0 234px

    &[data-size="ML"]
      width: 217px
      translate: 0 210px

    &[data-size="M"]
      width: 217px
      translate: 0 210px

    &[data-size="S"]
      width: 162px
      translate: 0 203px

  &.skeleton-item--3
    &[data-size="L"]
      width: 196px
      translate: 0 256px

    &[data-size="ML"]
      width: 172px
      translate: 0 234px

    &[data-size="M"]
      width: 172px
      translate: 0 234px

    &[data-size="S"]
      width: 105px
      translate: 0 225px

