@import '@fonts'

.input
  width: 100%
  height: 48px
  padding: 14px 16px
  box-sizing: border-box

  background: #FFFFFF
  border: 1px solid #E1E1E1
  border-radius: 24px
  color: black
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0)
  transition: all .2s ease
  font-size: 15px
  line-height: 20px
  @include font-regular

  &::placeholder
    color: #969696
  &:focus:not(:disabled),
  &:hover:not(:disabled)
    outline: none
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.15)

.error
  border-color: #FF3118
  &:hover:not(:disabled)
    box-shadow: inset 0 0 8px rgba(255, 49, 24, 0.4)
  &:focus:not(:disabled)
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0)
