@import '@responsive'

.layout
  max-width: 845px

.title
  width: 100%
  margin: 64px 0 16px 0
  +responsive(mobile)
    margin: 24px 0 16px 0

.start-text
  margin-bottom: 16px

.section-wrap
  margin-bottom: 24px
  +responsive(mobile)
    margin-bottom: 16px

.section-title
  width: 100%
  margin: 0
  font-weight: 400


.usage-doc
  max-width: 845px
  font-size: 18px
  line-height: 27px
  +responsive(mobile)
    font-size: 15px
    line-height: 20px

.sub-text
  margin: 0
  padding-left: 60px
  position: relative
  span
    position: absolute
    left: 20px
    top: 0
  +responsive(mobile)
    padding-left: 50px
    span
      left: 15px

.text-link
  cursor: pointer
  text-decoration: underline
  text-decoration-color: #ff3118
  text-decoration-thickness: 2px
  text-underline-offset: 4px
  transition: all 0.15s ease
  &:hover
    color: #ff3118

.text-date
  margin: 16px 0
  width: 100%
